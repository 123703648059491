import {makeStyles} from "@mui/styles";


export const appStyles = makeStyles( () => (
    {/*
        flexGrow: {
            flex: '1',
        },
        //Botones de aceptar
        SuccessButton: {
            backgroundColor: '#3424ab',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#ffffff',
                color: '#3424ab',
                fontWeight: 'bold',
            },
            /*"&:disabled": {
                background: "rgba(0, 0, 0, 0.12)",
                color: '#fff',
                //boxShadow: "none"
            }*/
        /*},
        //Botones de cancelar
        NegativeButton: {
            backgroundColor: '#fff',
            color: '#af4343',
            '&:hover': {
                backgroundColor: '#af4343',
                color: '#fff',
                fontWeight: 'bold'
            },
        },
        DefaultButton: {
            backgroundColor: '#b96d20',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#b96d20',
                fontWeight: 'bold'
            },
        },
        //Fondo de la aplicación
        AppBackground: {
            backgroundColor: '#e7ddd3',
        },
        //Barra superior principal
        MainAppBar: {
            backgroundColor: "#4b545d",
        },*/
        containerOverflow: {
            marginTop: 3,
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 5,
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: 5,
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
            },
        },
}));