import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import {appStyles} from "../../../../../../styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {success} from "../../../../../../Utils/Notification/notifications";
import Typography from "@mui/material/Typography";
import {Checkbox} from "@mui/joy";

export default function AccountMobileMenu(props) {
    const {accountid} = props;
    const [menu, setMenu] = React.useState(null);
    const [change, setChange] = React.useState(false);
    const [permissions, setPermissions] = React.useState(['worker_crud', 'customers']);
    const classes = appStyles();


    useEffect(() => {
        refresh()
    }, [])

    const refresh = (callback, cache) => {
        doPost("ui/getMenuMobileEntriesAndUserPermissions",accountid, result => {
            processResult(result, callback);
        })
    }

    const processResult = (r) => {
        let menuEntriesUI = r.menuEntriesUI;
        setMenu(menuEntriesUI);
        setPermissions(r.permissions)
    }

    const savePermission = () => {
        const toSend = {permissions: [...permissions], accountid: accountid}
        doPost('ui/saveMobileUserPermissions', toSend, r => {
            success('Guardado correctamente');
            setChange(false);
        })
    }

    const handleCheckClick = (r) => {
        setChange(true);
        let target = r.target;
        if (target.value && !permissions.includes(target.name)) {
            setPermissions([...permissions, target.name]);
        } else {
            let index = permissions.indexOf(target.name);
            if (index !== -1) {
                  permissions.splice(index, 1);
            }
        }
    }

    return (
        <Grid container>
            <Grid item md={5}/>
            {menu && <Grid sx={{mt: 5}} item md={6} alignContent={'center'}>
                <Box  sx={{height: '83vh', px: 1, alignContent: 'center'}} overflow={'auto'}>
                    <Paper className={classes.containerOverflow} sx={{pt:1, height: '80vh', bgcolor: 'white', maxWidth: 400}}>
                        {menu && menu.map(e =>
                            <Paper sx={{m: 1, my: 2}}>
                                <Grid container>
                                    <Grid item md={10}>
                                        <Typography sx={{m: 3}}>
                                            {e.key}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Box>
                                            <Checkbox sx={{ml: 1, flex: 1, alignItems: 'right', m: 3}}
                                                      onChange={(r) => handleCheckClick({target : {name: e.value, value:r.target.checked}})} defaultChecked={e.value ? permissions.includes(e.value) : false}/>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Paper>
                        )}
                    </Paper>
                </Box>
            </Grid>
            }
            <Grid item md={6}/>
            <Grid item md={12}>
                <Grid container>
                    <Grid item md={12}>
                        <Box component="form" noValidate sx={{pt: 2}}>
                            <SuccessButton text={'GUARDAR'} fullWidth onClick={(e) => {e.preventDefault(); savePermission()}} disabled={!change}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}