import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (campaignid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Campaña", component: <EditObjectDialog objectid={campaignid} entityDefinition={campaignUI}/> },
            ]}
    })
}

export const campaignUI = {
    entity : {
        name: 'campaign',
        keyField: 'campaignid',
        label: 'Campaña',
        info: {typeName: 'es.rbm.model.jpa.Campaign'},
    },
    uiTable: {
        fields:{
            campaignid : {name: 'campaignid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, sort: 'asc' },
            campaign : {name: 'campaign', label:'Descripción' , typeName: 'String'},
            since : {name: 'since', label:'Desde' , typeName: 'Date'},
            until : {name: 'until', label:'Hasta' , typeName: 'Date'},
        },
        keyComponent: 'campaignCrudManagement',
    },
    uiForm : {
        fields:{
            campaignid : {name: 'campaignid', label:'Codigo' , typeName: 'String', editable: false, optional: true, flex: 0.3, sort: 'asc' },
            campaign : {name: 'campaign', label:'Descripción' , typeName: 'String'},
            since : {name: 'since', label:'Desde' , typeName: 'Date', editable: true},
            until : {name: 'until', label:'Hasta' , typeName: 'Date', editable: true},
        }
    },
    uiTab: (campaignid) => getUiTab(campaignid)
}