import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import Typography from "@mui/material/Typography";
import {groupBy} from "../../../../../../Utils/UtilsCommon";

const treatmentUI = {
    fields: {
        treatmentid : {name: 'treatmentid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3},
        treatment : {name: 'treatment', label: 'Tratamiento' ,typeName: 'String', editable: false},
        since : {name: 'since', label: 'Fecha' ,typeName: 'Date', editable: false},
        workerid : {name: 'workerid', label: 'Responsable' ,typeName: 'es.rbm.model.jpa.Worker', optional: true, editable: false, urlRecords: 'worker/getResponsibles'},
    }
}

const treatmentproductUI = {
    fields: {
        cultivation : {name: 'cultivation', label: 'Cultivo' ,typeName: 'es.rbm.model.jpa.Cultivation', optional: true, editable: false},
        area : {name: 'area', label: 'Área(Ha)' ,typeName: 'Number', optional: true, editable: false},
        quantity : {name: 'quantity', label: 'Cantidad' ,typeName: 'Number', optional: true, editable: false},
    }
}

export default function ProductTreatmentsView(props) {
    const {productid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [props])



    const refresh = () => {
        const filters = {
            entity : {name: 'treatmentcultivation'},
            fields: {productid : {name: 'productid', operator: 'OPERATOR_EQUAL', value: productid}},
        }
        searchByCriteria(filters, result =>{
            const treatmentGroup = groupBy(result, "treatmentid.treatmentid");
            setGroups(treatmentGroup);
            const newRecords = Object.keys(treatmentGroup).map(key => treatmentGroup[key][0].treatmentid);
            setRecords(newRecords)
            setDetails([]);
        });
    }

    const onChange = (e) => {

    }
    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.treatmentid]);
        }
    }

    return(
        <Box maxWidth sx={{mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography sx={{mb:2}} component="p" variant="h4">{'(' + productid.productid + ') ' + productid.product}</Typography>
            <Box maxWidth sx={{minWidth: '100vh', maxWidth: '100vh' }} overflow={'auto'}>
                <GridTable ui={treatmentUI}
                           sx={{height: '40vh'}}
                           records={records} rowId={'treatmentid'} onRowClick={onRowClick}
                           onChange={onChange} toolBar={'S'} hideFooter/>
            </Box>
            <Box maxWidth sx={{minWidth: '100vh', maxWidth: '100vh' }} overflow={'auto'}>
                <GridTable ui={treatmentproductUI}
                           sx={{height: '30vh', mt:2}}
                           records={details} rowId={'treatmentcultivationid'}
                           onChange={onChange}  hideFooter/>
            </Box>
        </Box>
    )
}