import {doDelete, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../../../Utils/Notification/notifications";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

export const updateInventoryField = (record, callback) =>{
    doPut('rest/inventory/update',record, response =>{
        if(response){
            callback && callback()
        }
    })
}

export const updateInventory = (inventoryid, callback) =>{
    doPut('rest/inventory/update',inventoryid, response =>{
        if(response){
            success('Guardado correctamente', callback && callback(response))
            callback && callback(response.data)
        }
    })
}

export const updateInventorydetail = (inventorydetailid, callback) =>{
    doPut('rest/inventorydetail/update',inventorydetailid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deleteInventory = (inventoryid, callback) =>{
    doDelete('rest/inventory/delete/' + inventoryid.inventoryid,null, response =>{
        callback && callback();
    })
}
export const deleteInventorydetail = (inventorydetailid, callback) =>{
    doDelete('rest/inventorydetail/delete/' + inventorydetailid.inventorydetailid,null, response =>{
        callback && callback();
    })
}

export const getDetails = (inventoryid, callback) =>{
    const filters = {
        entity : { name: 'inventorydetail'},
        fields: {inventoryid : {name: 'inventoryid', operator: 'OPERATOR_EQUAL', value: inventoryid},},
    }
    searchByCriteria(filters, result =>{
        callback && callback(result)
    });
}

export const getTotalAmount = (inventorydetailid) =>{
    const totalinventorydetailprice = inventorydetailid.unitprice * inventorydetailid.quantity;
    if(inventorydetailid.discount){
        return Math.floor((((totalinventorydetailprice/100)*(100-inventorydetailid.discount)) *100))/100;
    }
    return (totalinventorydetailprice * 100) /100;
}