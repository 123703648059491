import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (vehicleoperationtypeid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tipo de operación", component: <EditObjectDialog objectid={vehicleoperationtypeid} entityDefinition={vehicleoperationtypeUI}/> },
            ]}
    })
}

export const vehicleoperationtypeUI = {
    entity : {
        name: 'vehicleoperationtype',
        keyField: 'vehicleoperationtypeid',
        label: 'Tipo de operación'
    },
    uiTable: {
        fields: {
            vehicleoperationtypeid : {name: 'vehicleoperationtypeid', label:'#' , typeName: 'Number', editable: false, flex: 0.3},
            vehicleoperationtype : {name: 'vehicleoperationtype', label:'Marca' , typeName: 'String', editable: true, align: 'center'},
        },
        keyComponent: 'vehicleoperationtypeCrudManagement'
    },
    uiForm : {
        fields:{
            vehicleoperationtypeid : {name: 'vehicleoperationtypeid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            vehicleoperationtype : {name: 'vehicleoperationtype', label:'Tipo' , typeName: 'String', editable: true },
        }
    },
    uiTab: (vehicleoperationtype) => getUiTab(vehicleoperationtype)
}

