export const defaultReport = {

     defaultWorkerReport : {
        ui : {
            fields :{
                workerid : {name: 'workerid', label: 'Código' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                name : {name: 'name', label: 'Nombre' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                surname : {name: 'surname', label: 'Apellidos' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                idcardnumber : {name: 'idcardnumber', label: 'DNI' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                phone : {name: 'phone', label: 'Teléfono' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                birthday : {name: 'birthday', label: 'Fecha de nacimiento' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                townid : {name: 'townid', label: 'Población' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                stateid : {name: 'stateid', label: 'Provincia' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                postalcode : {name: 'postalcode', label: 'CP' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                countryid : {name: 'countryid', label: 'Pais' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
            }
        },
        title: 'Informe de trabajadores',
        description: 'Listado de todos los trabajadores',
        url: 'rest/worker/report',
        documentName: 'Informe_trabajadores' + new Date(),
        keyForm: 'defaultWorkerReport',
    },

     defaultContractReport : {
         ui : {
             fields :{
                 contractid : {name: 'contractid', label: 'Código' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 since : {name: 'since', label: 'Desde' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 until : {name: 'until', label: 'Hasta' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
             }
         },
         title: 'Informe de trabajadores',
         description: 'Listado de todos los contratos',
         url: 'rest/contract/report',
         documentName: 'Informe_contractos' + new Date(),
         keyForm: 'defaultContractReport',
    },

    defaultProducttypeReport : {
         ui : {
             fields :{
                 producttypeid : {name: 'producttypeid', label: 'Código' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 producttype : {name: 'producttype', label: 'Tipo de producto' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 externalcode : {name: 'externalcode', label: 'Código externo' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
             }
         },
         title: 'Informe de tipo de productos',
         description: 'Listado de todos los tipos de productos',
         url: 'rest/producttype/report',
         documentName: 'Informe_tipos_producto' + new Date(),
        keyForm: 'defaultProducttypeReport',
    },

    defaultProductReport : {
         ui : {
             fields :{
                 productid : {name: 'productid', label: 'Código' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 product : {name: 'product', label: 'Producto' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 producttypeid : {name: 'producttypeid', label: 'Tipo de producto' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 unitprice : {name: 'unitprice', label: 'Precio unitario' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 active : {name: 'active', label: 'Activo' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 externalcode : {name: 'externalcode', label: 'Codigo externo' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 measureunitid : {name: 'measureunitid', label: 'Unidad de medida' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
             }
         },
         title: 'Informe de productos',
         description: 'Listado de todos los productos',
         url: 'rest/product/report',
         documentName: 'Informe_producto' + new Date(),
        keyForm: 'defaultProductReport',
    },

    defaultTaskReport : {
         ui : {
             fields :{
                 taskid : {name: 'taskid', label: 'Código' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 task : {name: 'task', label: 'Tarea' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 tasktypeid : {name: 'tasktypeid', label: 'Tipo de tarea' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
             }
         },
         title: 'Informe de tareas',
         description: 'Listado de todas las tareas',
         url: 'rest/task/report',
         documentName: 'Informe_tareas' + new Date(),
        keyForm: 'defaultTaskReport',
    },

    defaultTasktypeReport : {
         ui : {
             fields :{
                 tasktypeid : {name: 'tasktypeid', label: 'Código' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
                 tasktype : {name: 'task', label: 'Tipo de tarea' ,typeName: 'Boolean', size: 6, optional: true, align: 'left'},
             }
         },
         title: 'Informe de tipo de tareas',
         description: 'Listado de todos los tipos de tarea',
         url: 'rest/tasktype/report',
         documentName: 'Informe_tipos_tarea' + new Date(),
        keyForm: 'defaultTasktypeReport',
    },

    defaultAbsenceReport : {
         ui : {
             fields :{

             }
         },
         title: 'Informe de ausencias',
         description: 'Listado de todas las ausencias',
         url: 'rest/absence/report',
         documentName: 'Informe_ausencias' + new Date(),
        keyForm: 'defaultAbsenceReport',
    },
}


