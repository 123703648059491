import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import ProductTreatmentsView from "./ProductTreatmentsView";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

const getUiTab = (treatmentproductid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Producto", component: <EditObjectDialog objectid={treatmentproductid} entityDefinition={treatmentproductUI}/> },
                {label : "Tratamientos", component: <ProductTreatmentsView productid={treatmentproductid}/>},
            ]}
    })
}

export const treatmentproductUI = {
    entity : {
        name: 'agroproduct',
        keyField: 'productid',
        label: 'Producto de tratamiento',
        urlRefresh: 'agro/getAllsTreatmentproduct',
        info: {typeName: 'es.rbm.model.jpa.AgroProduct'},
        hideNewButton: true,
        colorRow: 'active',
        urlExcelImport: 'agro/importTreatmentProductFromExcel',
    },
    uiTable: {
        fields:{
            productid : {name: 'productid', label: 'Cod' ,typeName: 'Number', optional: true, editable: false, flex: 0.5},
            product : {name: 'product', label: 'Producto' ,typeName: 'String'},
            description : {name: 'description', label: 'Descripción' ,typeName: 'String'},
            measureunitid : {name: 'measureunitid', label: 'Unidad de medida' ,typeName: 'es.rbm.model.jpa.Movementunit', flex: 0.5},
            activeingredient : {name: 'activeingredient', label: 'Ingrediente activo' ,typeName: 'String', optional: true},
            reasonuseid : {name: 'reasonuseid', label: 'Razón de uso' ,typeName: 'es.rbm.model.jpa.Reasonuse', optional: true},
            registrynumber : {name: 'registrynumber', label: 'Nº Registro' ,typeName: 'String', optional: true},
            dose : {name: 'dose', label: 'Dosis autorizada' ,typeName: 'Number', optional: true, flex: 0.6},
            watervolume : {name: 'watervolume', label: 'Volumen de agua' ,typeName: 'Number', optional: true},
            securityperiod : {name: 'securityperiod', label: 'Plazo seguridad' ,typeName: 'String', optional: true, flex: 0.5},
            externalcode : {name: 'externalcode', label: 'Codigo externo' ,typeName: 'String', optional: true, flex: 0.4},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
        },
        keyComponent: 'treatmentproductCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            productid : {name: 'productid', label: 'Cod.' ,typeName: 'Number', optional: true, editable: false},
            product : {name: 'product', label: 'Producto' ,typeName: 'String'},
            description : {name: 'description', label: 'Descripción' ,typeName: 'String'},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
            measureunitid : {name: 'measureunitid', label: 'Unidad de medida' ,typeName: 'es.rbm.model.jpa.Movementunit'},
            activeingredient : {name: 'activeingredient', label: 'Ingrediente activo' ,typeName: 'String', optional: true},
            reasonuseid : {name: 'reasonuseid', label: 'Razón de uso' ,typeName: 'es.rbm.model.jpa.Reasonuse', optional: true},
            dose : {name: 'dose', label: 'Dosis autorizada' ,typeName: 'Number', optional: true},
            watervolume : {name: 'watervolume', label: 'Voluçmen de agua' ,typeName: 'Number', optional: true},
            securityperiod : {name: 'securityperiod', label: 'Plazo seguridad' ,typeName: 'Number', optional: true},
            externalcode : {name: 'externalcode', label: 'Codigo externo' ,typeName: 'String', optional: true},
            extrainformation : {name: 'extrainformation', label: 'Información extra' ,typeName: 'String', optional: true, size: 12, multiline: true, rows: 3},
        }
    },
    filters : {
        fields: {
            product : {name: 'product', label: 'Producto' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            reasonuseid : {name: 'reasonuseid', label: 'Razón de uso' ,typeName: 'es.rbm.model.jpa.Reasonuse', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            activeingredient : {name: 'activeingredient', label: 'Ingrediente activo' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },

    uiTab: (treatmentproduct) => getUiTab(treatmentproduct)
}

