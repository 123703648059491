import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {defaultSendMail} from "../../../../../../View/Form/utils";

const getUiTab = (applicatorid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Aplicador", component: <EditObjectDialog objectid={applicatorid} entityDefinition={applicatorUI}/> },
            ]}
    })
}

export const applicatorUI = {
    entity : {
        name: 'worker',
        keyField: 'workerid',
        label: 'Aplicador',
        info: {typeName: 'es.rbm.model.jpa.Worker'},
        urlRefresh: 'agro/getAllsTreatmentAplicators',
    },
    uiTable: {
        fields:{
            workerid : {name: 'workerid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, sort: 'asc' },
            name : {name: 'name', label:'Nombre' , typeName: 'String', editable: false},
            surname : {name: 'surname', label:'Apellidos' , typeName: 'String', editable: false},
            idcardnumber : {name: 'idcardnumber', label:'DNI' , typeName: 'String', editable: false},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: false},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: false},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: false},
            applicatorcode : {name: 'applicatorcode', label:'Número de aplicador' , typeName: 'String', editable: true},
        },
        keyComponent: 'applicatorCrudManagement',
        actions:{
            sendEmail:{
                fun: (entity ,setOpenMail, setMailRecords) => defaultSendMail(entity,'email',setOpenMail, setMailRecords)
            },
        },
    },
    uiForm : {
        fields:{
            workerid : {name: 'workerid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, sort: 'asc' },
            name : {name: 'name', label:'Nombre' , typeName: 'String', editable: false},
            surname : {name: 'surname', label:'Apellidos' , typeName: 'String', editable: false},
            idcardnumber : {name: 'idcardnumber', label:'DNI' , typeName: 'String', editable: false},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: false, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: false},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: false},
            applicatorcode : {name: 'applicatorcode', label:'Número de aplicador' , typeName: 'String', editable: true},
        }
    },
    uiTab: (applicatorid) => getUiTab(applicatorid)
}