import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateCustomerproduct} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const ui = {
    fields:{
        customerproductid : {name: 'customerproductid', label:'Codigo' , typeName: 'String', editable: false, optional: true},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false },
        since : {name: 'since', label: 'Desde',typeName:'Date'},
        until : {name: 'until', label: 'Hasta',typeName:'Date'},
        fromquantity : {name: 'fromquantity', label:'Desde cantidad' , typeName: 'Number'},
        toquantity : {name: 'toquantity', label:'Hasta cantidad' , typeName: 'Number'},
        price : {name: 'price', label:'Precio' , typeName: 'Number'},
    }
}

export default function EditCustomerproductDialog(props) {
    const {setOpen, selected} = props;
    const [customerproduct, setCustomerproduct] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nueva tarifa');

    const handledChange = (record, complete) => {
        setCustomerproduct({...record});
        setComplete(complete);
    }

    useEffect(() =>{
        if(customerproduct && customerproduct.customerproductid){
            setTitle('Editar tarifa')
        }
    }, [props])

    const onSuccesClick = (e) => {
        e.preventDefault()
        updateCustomerproduct(customerproduct, () => setOpen())
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen()
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={customerproduct} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccesClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
