export const fileToBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback && callback(reader.result)
    };

    reader.onerror = function (error) {
        // console.log('Error: ', error);
    };
}

export const fileToArrayBytes = (file, callback) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
        callback && callback(reader.result)
    };

    reader.onerror = function (error) {
        // console.log('Error: ', error);
    };
}