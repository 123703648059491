import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {recypeTypes} from "../../../../../../Utils/Constant";
import RecipedetailsDialog from "./RecipedetailsDialog";

const getUiTab = (recipeid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Receta", component: <EditObjectDialog objectid={recipeid} entityDefinition={recipeUI}/> },
                {label : "Detalles", component: <RecipedetailsDialog selected={recipeid}/> },
            ]}
    })
}

export const recipeUI = {
    entity : {
        name: 'recipe',
        keyField: 'recipeid',
        label: 'Receta',
        info: {typeName: 'es.rbm.model.jpa.Recipe'},
    },
    uiTable: {
        fields:{
            recipeid : {name: 'recipeid', label:'#' , typeName: 'Number', editable: false,flex:0.3, align: 'center', optional: true},
            recipe : {name: 'recipe', label: 'Receta',typeName:'String', optional: true},
            recipetype : {name: 'recipetype', label: 'Tipo',typeName:'Select', valueOptions: recypeTypes, align: 'center'},
        },
        keyComponent: 'recipeCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            recipeid : {name: 'recipeid', label: 'Código' ,typeName: 'String', editable: false, optional: true},
            recipe : {name: 'recipe', label: 'Receta' ,typeName: 'String'},
            recipetype : {name: 'recipetype', label: 'Tipo' ,typeName: 'Enum'},
        }
    },

    uiTab: (recipe) => getUiTab(recipe)
}

