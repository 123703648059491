import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {success} from "../../../../../../Utils/Notification/notifications";
import {updateWorkerattributevalue} from "./actions";

export default function AttributeWorkerDialog(props) {
    const [records, setRecords] = React.useState({});
    const [workerid, setWorkerid] = React.useState({});
    const [ui, setUI] = React.useState({});

    const handledChange = (record, complete) => {
        setRecords({...record});
    }

    useEffect(() =>{
        if(props.workerid !== workerid){
            setWorkerid(props.workerid)
            refresh();
        }
    }, [props])


    const refresh = () => {
      doPost("worker/getAttributesFromWorker",props.workerid, result => {
          generateUIAndRecords(result);
      })
    }

    const generateUIAndRecords = (records, callback) => {
        const fields = records.reduce((l, c)=>{
            const key = c.attribute;
            return {...l, [key] : {name: c.attribute, label: c.attribute ,typeName: c.attributeclassname, size: c.size}}
        }, {});

        const convertedRecords = records.reduce((l, c)=>{
                const key = c.attribute;
                return {...l, [key] : c.value}
        }, {});

        setRecords(convertedRecords)
        setUI({fields:{...fields}});
        callback && callback()
    }

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography sx={{mb: 3}} component="h1" variant="h5">
               Atributos del trabajador
            </Typography>
            <div style={{ height: 300, width: 840 }}>
                <SingleRecordForm ui={ui} records={records} onChange={handledChange} disableAutoFocus />
                <SuccessButton
                    text={'GUARDAR'}
                    fullWidth
                    sx={{mt: 3}}
                    onClick={(e) => {
                        e.preventDefault()
                        updateWorkerattributevalue({workerid: workerid, attributes: records}, () => success('Guardado correctamente') && refresh())
                    }}
                />
            </div>
        </Box>
    )
}