import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import {useEffect} from "react";

export default function Orders({orders}) {
    const [lastorders, setLastOrders] = React.useState(null);
    const options = {year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() =>{
        if(orders){
            setLastOrders(orders)
        }
    }, [orders])

    return (
        <React.Fragment>
            <Title>Últimos presupuestos</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center"><strong>Nº</strong></TableCell>
                        <TableCell align="center"><strong>Fecha</strong></TableCell>
                        <TableCell align="center"><strong>Cliente</strong></TableCell>
                        <TableCell align="center"><strong>Estado</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lastorders && lastorders.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell align="center">{row.saleorderid}</TableCell>
                            <TableCell align="center">{new Date(row.date).toLocaleDateString('es-ES', options)}</TableCell>
                            <TableCell align="center">{row?.customerid?.customer}</TableCell>
                            <TableCell align="center">{row.saleorderstatusid?.saleorderstatus}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}