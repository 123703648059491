import * as React from 'react';
import Box from '@mui/material/Box';
import {useEffect} from "react";
import {doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {error, success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import Typography from "@mui/material/Typography";

const uiVehiclecardcode = {
    fields:{
        vehiclecardcodeid : {name: 'vehiclecardcodeid', label: '#' ,typeName: 'Number', flex: 0.3, editable: false},
        vehiclecardcode : {name: 'vehiclecardcode', label: 'Descripción' ,typeName: 'String', align: 'center'},
        registerdate : {name: 'registerdate', label: 'Fecha de registro' ,typeName: 'Date'},
    }
}

export default function VehicleCardcodeView(props) {
    const {vehicleid} = props;
    const [cardsRecords, setCardsRecords] = React.useState([]);
    const [refreshObjects, setRefreshObjects] = React.useState(false);
    

    const onDelete = (vehiclecardcode) =>{
        deleteObject(vehiclecardcode,{name:"vehiclecardcode", keyField:'vehiclecardcodeid'},() => setRefreshObjects(!refreshObjects))
    }
    const onChange = (e) => {
        const record = cardsRecords.filter(r => r.vehiclecardcodeid === e.id);
        if(record && record.length>0){
            const toSend = record[0];
            toSend[e.field] = e.value
            doPut('rest/vehiclecardcode/update',toSend, response =>{
                if(response){
                    success('Se ha guardado correctamente')
                }
            })
        } else {
            error('Error al modificar identificador');
        }
    }

    const searchVehiclecode = () =>{
        const filters = {
            fields: {vehicleid : {name: 'vehicleid', operator: 'OPERATOR_EQUAL', value: vehicleid},},
            entity : { name: 'vehiclecardcode'}
        }
        searchByCriteria(filters, result =>{
            setCardsRecords(result);
        });
    }


    useEffect(() =>{
        searchVehiclecode();
    }, [refreshObjects, props])

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}} overflow={"auto"} >
                <Typography sx={{m: 2}} component="h1" variant="h5" align={'center'}>
                    Identificadores asociados
                </Typography>
                <GridTable ui={uiVehiclecardcode} onDelete={onDelete}
                           onChange={onChange}
                           records={cardsRecords} rowId={'vehiclecardcodeid'}
                           actionFlex={0.15}
                />
            </Box>
        </Box>
    );
}
