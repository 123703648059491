import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {
    deleteDataperioddetail,
    getDetails,
    updateDataperioddetail
} from "./actions";
import Dialog from "@mui/material/Dialog";
import EditDataperioddetailDialog from "./EditDataperioddetailDialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";

const ui = {
    fields: {
        workerid : {name: 'workerid', label: 'Trabajador',typeName:'es.rbm.model.jpa.Worker' },
        taskid : {name: 'taskid', label: 'Tarea', typeName:'es.rbm.model.jpa.Task'},
        workspaceid : {name: 'workspaceid', label: 'Finca',typeName:'es.rbm.model.jpa.Workspace'},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product'},
        transport : {name: 'transport', label: 'Transporte (€)',typeName:'Number'},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number'},
        hours : {name: 'hours', label: 'Horas',typeName:'Number'},
    }
}

export default function DataperioddetailsDialog(props) {
    const {selected} = props;
    const [openEditWorker, setOpenEditWorker] = React.useState(false);
    const [dataperioddetails, setDataperioddetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);

    useEffect(() =>{
        if(selected && selected.dataperiodid){
            refresh()
        }
    }, [openEditWorker, selected])

    const refresh = () => {
        getDetails(selected, result =>{
            setDataperioddetails([...result]);
        });
    }

    const onDelete = (dataperioddetail) => {
        deleteDataperioddetail(dataperioddetail, () => refresh())
    }

    const onEdit = (dataperioddetail) => {
        setSelectedDetail(dataperioddetail)
        setOpenEditWorker(!openEditWorker);
    }

    const onChange = (e) => {
        const record = dataperioddetails.filter(r => r.dataperioddetailid === e.id);
        if(record && record.length>0) {
            record[0][e.field] = e.value;
            updateDataperioddetail(record[0])
        } else {
           updateDataperioddetail(e, () => refresh());
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} align={'center'}>
            <Dialog  fullWidth maxWidth="md" open={openEditWorker}>
                <EditDataperioddetailDialog setOpen={setOpenEditWorker} open={openEditWorker} selected={selectedDetail}/>
            </Dialog>
            <Typography sx={{mb: 3}} component="h1" variant="h5">Trabajadores en el parte</Typography>
            <Button size={'small'} variant="contained" onClick={() => onEdit({dataperiodid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.dataperiodid}>
               Añadir trabajador
            </Button>
            <Paper sx={{backgroundColor: 'white', p: 5}}>
                <GridTable ui={ui}  onDelete={onDelete} onEdit={onEdit} toolBar
                           records={dataperioddetails} rowId={'dataperioddetailid'}
                           onChange={onChange} />
            </Paper>
        </Box>
    )
}
