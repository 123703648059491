import * as React from 'react';
import Box from "@mui/material/Box";

import {useEffect} from "react";
import {doGet} from "../../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../../View/Form/GridTable";

export default function SelectCultivationForm(props) {
    const {entityDefinition, state, setState, setComplete} = props;
    const [records, setRecords] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const onSelection = (e) => {
        const record = records.filter(r => e.includes(r[entityDefinition.entity.keyField]));
        const newState = {...state, selectedCultivation: record}
        setState(newState)
        record.length >0 ? setComplete(true) :  setComplete(false);
    }

    const setSelections = () => {
      const selected = state.selectedCultivation? state.selectedCultivation.map(r => r[entityDefinition.entity.keyField]) : [];
      setSelectionModel(selected);
      selected.length >0 ? setComplete(true) :  setComplete(false);
    }

    const refresh = (callback) => {
      doGet("rest/cultivation/getAll", records =>{
          setRecords(records);
          callback && callback(records);
      })
    }

    useEffect(() =>{
        refresh( () =>{
            setSelections()
        })
    }, [props]);

    return (
        <Box sx={{ width: '100%', height: '50vh'}}>
            <GridTable ui={entityDefinition.uiTable}
                       records={records} rowId={'cultivationid'}
                       toolBar onSelection={onSelection}
                       selectionModel={selectionModel}/>
        </Box>


    );

}