import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {groupBy} from "../../../../../../Utils/UtilsCommon";
import {purchasenoteUI} from "../Purchasenote/purchasenoteCrud";


const purchasenotedetailUI = {
    fields: {
        purchasenotedetailid : {name: 'purchasenotedetailid', label:'#' , typeName: 'Number', editable: false, optional: true},
        purchasenotedetail : {name: 'purchasenotedetail', label:'Descripción' , typeName: 'String', editable: false, align: 'center' },
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: false},
        warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: false, urlRecords: 'rest/warehouse/getActives'},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: false},
    }
}

export default function PurchasenoteProviderView(props) {
    const {providerid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [providerid])

    const refresh = () => {
        setDetails([])
        setRecords([])
        const filters = {
            entity : {name: 'purchasenote'},
            fields: {providerid : {name: 'providerid', operator: 'OPERATOR_EQUAL', value: providerid}},
        }
        searchByCriteria(filters, result =>{
            if(result && result.length > 0){
                const filters = {
                    entity : {name: 'purchasenotedetail'},
                    fields: {purchasenoteid : {name: 'purchasenoteid', operator: 'OPERATOR_IN', value: result}},
                }
                searchByCriteria(filters, result1 =>{
                    const purchasenoteGroup = groupBy(result1, "purchasenoteid.purchasenoteid");
                    setGroups(purchasenoteGroup);
                    const newRecords = Object.keys(purchasenoteGroup).map(key => purchasenoteGroup[key][0].purchasenoteid);
                    setRecords(newRecords)
                    setDetails([]);
                });
            }
        });
    }

    const onChange = (e) => {

    }
    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.purchasenoteid]);
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Box overflow={'auto'}>
                <GridTable ui={purchasenoteUI.uiTable}
                           sx={{height: '40vh'}}
                           records={records} rowId={'purchasenoteid'} onRowClick={onRowClick}
                           onChange={onChange} toolBar={'S'} hideFooter/>
            </Box>
            <Box overflow={'auto'}>
                <GridTable ui={purchasenotedetailUI}
                           sx={{height: '30vh', mt:2}}
                           density={'compact'}
                           records={details} rowId={'purchasenotedetailid'}
                           onChange={onChange}  hideFooter/>
            </Box>
        </Box>
    )
}