import NewFertigation from "./NewFertigation/NewFertigation";
import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import DetailsFertigationDialog from "./DetailsFertigationDialog";

const getUiTab = (fertigation) =>{
    return ({
        uiTabs : {fields : [
                {label : "Fertirrigación", component: <EditObjectDialog objectid={fertigation} entityDefinition={fertigationUI}/> },
                {label : "Detalles", component: <DetailsFertigationDialog selected={fertigation}/>},
            ]}
    })
}


const getNewComponent = (setOpenNewObject, openNewObject) =>{
    return <NewFertigation setOpenNewObject={setOpenNewObject} openNewObject={openNewObject}/>
}

export const fertigationUI = {
    entity : {
        name: 'fertigation',
        keyField: 'fertigationid',
        label: 'Fertirrigación',
        info: {typeName: 'es.rbm.model.jpa.Fertigation'},
        colorRow: 'executed',
    },
    uiTable: {
        fields: {
            fertigationid : {name: 'fertigationid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3},
            fertigation : {name: 'fertigation', label: 'Fertirrigación' ,typeName: 'String'},
            since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date'},
            workerid : {name: 'workerid', label: 'Responsable' ,typeName: 'es.rbm.model.jpa.Worker', optional: true, urlRecords: 'worker/getResponsibles'},
            tractorid : {name: 'tractorid', label: 'Tractor' ,typeName: 'es.rbm.model.jpa.Tractor',  optional: false},
            tankid : {name: 'tankid', label: 'Cuba' ,typeName: 'es.rbm.model.jpa.Tank',  optional: false},
            remarks : {name: 'remarks', label: 'Observaciones' ,typeName: 'String', optional: true, size: 3},
            executed : {name: 'executed', label: 'Completado' ,typeName: 'Boolean', optional: true},

        },
        keyComponent: 'fertigationCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            fertigationid : {name: 'fertigationid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3, optional: true},
            fertigation : {name: 'fertigation', label: 'Fertirrigación' ,typeName: 'String'},
            since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date'},
            workerid : {name: 'workerid', label: 'Responsable' ,typeName: 'es.rbm.model.jpa.Worker', urlRecords: 'worker/getResponsibles'},
            tractorid : {name: 'tractorid', label: 'Tractor' ,typeName: 'es.rbm.model.jpa.Tractor',  optional: false},
            tankid : {name: 'tankid', label: 'Cuba' ,typeName: 'es.rbm.model.jpa.Tank',  optional: false},
            executed : {name: 'executed', label: 'Completado' ,typeName: 'Boolean', optional: true},
            remarks : {name: 'remarks', label: 'Observaciones' ,typeName: 'String', optional: true, size: 12, multiline: true, rows: 3},
        }
    },

    onClickNewObject : {component : (setOpenNewObject, openNewObject) => getNewComponent(setOpenNewObject, openNewObject)},

    uiTab: (fertigation) => getUiTab(fertigation)
}

