import ReportForm from "../../../../View/Form/ReportForm";
import {defaultReport} from "./defaultReports";
import {fertigationReport, treatmentReport} from "../Agro/Reports/Reports";
import {defaultTimetickReport} from "./presenceReports";

export const workerReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
            hasta : {name: 'hasta', label: 'Hasta' ,typeName: 'Date',},
            onlyactive : {name: 'onlyactive', label: 'Solo activos' ,typeName: 'Boolean', optional: true},
        }
    },
    title: 'Informe de trabajadores',
    description: 'Listado de trabajadores',
    url: null,
    documentName: 'Informe trabajadores' + new Date(),
    parameters : {
        workerid: "workerid",
        worker: "name",
        surname: "surname",
        idcardnumber: "idcardnumber",
        address: "address",
        phone: "phone",
        birthday: "birthday",
        townid: "townid",
        state: "state",
        postalcode: "postalcode",
        countryid: "countryid",
    }
}

export const contractReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
            hasta : {name: 'hasta', label: 'Hasta' ,typeName: 'Date'},
            workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'es.rbm.model.jpa.Worker', size: 12, optional: true, isMulti: true},
        }
    },
    title: 'Contratos activos',
    description: 'Listado con los contratos activos de una fecha a otra fecha',
    url: null
}

export const ticksReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
            hasta : {name: 'hasta', label: 'Hasta' ,typeName: 'Date'},
            workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'es.rbm.model.jpa.Worker', size: 12, optional: true},
        }
    },
    title: 'Informe de marcajes',
    description: 'Listado con todos lo marcajes en el rango de fechas seleccionado',
    url: null
}

export const absenceReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date' },
            hasta : {name: 'hasta', label: 'Hasta' ,typeName: 'Date'},
            workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'es.rbm.model.jpa.Worker', size: 12, optional: true},
        }
    },
    title: 'Informe de ausencias',
    description: 'Listado con las ausencias registradas en un rango de fechas',
    url: null
}

export const productReport = {
    ui : {
        fields :{
            active : {name: 'active', label: 'Activo' ,typeName: 'Boolean', size: 12, optional: true},
        }
    },
    title: 'Informe de productos',
    description: 'Listado de productos',
    url: null,
    documentName: 'Informe productos' + new Date(),
    parameters : {
        productid: "productid",
        product: "product",
        varietyid: "varietyid",
    }
}

export const taskReport = {
    ui : {
        fields :{
            active : {name: 'active', label: 'Activo' ,typeName: 'Boolean', size: 6, optional: true},
            tasktype : {name: 'tasktype', label: 'Tipo' ,typeName: 'es.rbm.model.jpa.Tasktype', size: 6, optional: true},
        }
    },
    title: 'Informe de tareas',
    description: 'Listado de tareas segun los filtros seleccionados',
    url: null
}

export const saleReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date', size: 6},
            hasta : {name: 'hasta', label: 'Hasta' ,typeName: 'Date', size: 6},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', size: 12, optional: true},
        }
    },
    title: 'Informe de ventas',
    description: 'Listado de ventas en un rango de fechas y según los filtros seleccionados',
    url: null
}

export const purchaseReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date', size: 6},
            hasta : {name: 'hasta', label: 'Hasta' ,typeName: 'Date', size: 6},
            provider : {name: 'providerid', label: 'Proveedor' ,typeName: 'es.rbm.model.jpa.Provider', size: 12, optional: true},
        }
    },
    title: 'Informe de compras',
    description: 'Obtener las compras realizadas entre las fechas',
    url: null
}

export const sideBarReports = {
    generalReport: {
        fields : [
            {label : "Trabajadores", component: <ReportForm form={defaultReport.defaultWorkerReport}/>},
            {label : "Productos", component: <ReportForm form={defaultReport.defaultProductReport}/>},
            {label : "Tipo producto", component: <ReportForm form={defaultReport.defaultProducttypeReport}/>},
            {label : "Tareas", component: <ReportForm form={defaultReport.defaultTaskReport}/>},
            {label : "Tipo tarea", component: <ReportForm form={defaultReport.defaultTasktypeReport}/>},
            {label : "Contratos", component: <ReportForm form={defaultReport.defaultContractReport}/>},
            {label : "Ausencias", component: <ReportForm form={defaultReport.defaultAbsenceReport}/>},
        ]
    },
    workerReport: {
        fields : [
            {label : "Personal", component: <ReportForm form={workerReport}/>},
            {label : "Contrato", component: <ReportForm form={contractReport}/>},
            {label : "Control horario", component: <ReportForm form={defaultTimetickReport}/>},
            {label : "Ausencias", component: <ReportForm form={absenceReport}/>},
        ]
    },
    productReport: {
        fields : [
            {label : "Productos", component: <ReportForm form={productReport}/>}
        ]
    },
    taskReport: {
        fields : [
            {label : "Tareas", component: <ReportForm form={taskReport}/>}
        ]
    },
    saleReport: {
        fields : [
            {label : "Ventas", component: <ReportForm form={saleReport}/>}
        ]
    },
    purchaseReport: {
        fields : [
            {label : "Compras", component: <ReportForm form={purchaseReport}/>}
        ]
    },
    agroReport : {
        fields : [
            {label : "Tratamientos", component: <ReportForm form={treatmentReport}/>},
            {label : "Fertirrigaciones", component: <ReportForm form={fertigationReport}/>}
        ]
    }
}

