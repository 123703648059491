import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {groupBy} from "../../../../../../Utils/UtilsCommon";
import {purchaseorderUI} from "../Purchaseorder/purchaseorderCrud";


const purchaseorderdetailUI = {
    fields: {
        purchaseorderdetailid : {name: 'purchaseorderdetailid', label:'Codigo' , typeName: 'Number', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false },
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: false},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number', editable: false},
        price : {name: 'price', label:'Precio unitario' , typeName: 'Number', editable: false},
    }
}

export default function PurchaseorderProviderView(props) {
    const {providerid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [providerid])

    const refresh = () => {
        setDetails([])
        setRecords([])
        const filters = {
            entity : {name: 'purchaseorder'},
            fields: {providerid : {name: 'providerid', operator: 'OPERATOR_EQUAL', value: providerid}},
        }
        searchByCriteria(filters, result =>{
            if(result && result.length > 0){
                const filters = {
                    entity : {name: 'purchaseorderdetail'},
                    fields: {purchaseorderid : {name: 'purchaseorderid', operator: 'OPERATOR_IN', value: result}},
                }
                searchByCriteria(filters, result1 =>{
                    const purchaseorderGroup = groupBy(result1, "purchaseorderid.purchaseorderid");
                    setGroups(purchaseorderGroup);
                    const newRecords = Object.keys(purchaseorderGroup).map(key => purchaseorderGroup[key][0].purchaseorderid);
                    setRecords(newRecords)
                    setDetails([]);
                });
            }
        });
    }

    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.purchaseorderid]);
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Box overflow={'auto'}>
                <GridTable ui={purchaseorderUI.uiTable}
                           sx={{height: '40vh'}}
                           records={records} rowId={'purchaseorderid'} onRowClick={onRowClick}
                           toolBar={'S'} hideFooter/>
            </Box>
            <Box overflow={'auto'}>
                <GridTable ui={purchaseorderdetailUI}
                           density={'compact'}
                           sx={{height: '30vh', mt:2}}
                           records={details} rowId={'purchaseorderdetailid'} hideFooter/>
            </Box>
        </Box>
    )
}