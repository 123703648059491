import * as React from 'react';
import Box from '@mui/material/Box';
import {useEffect} from "react";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

const ui = {
    fields: {
        cultivationplotid : {name: 'cultivationplotid', label:'Codigo' , typeName: 'String', editable: true, flex: 0.5, sort: 'asc' },
        plotid : {name: 'plotid', label:'Parcela' , typeName: 'es.rbm.model.jpa.Plot', editable: true},
    }
}

export default function CultivationPlotView(props) {
    const {cultivationid} = props;
    const [records, setRecords] = React.useState([]);

    useEffect(() =>{
        const filters = {
            fields: {cultivationid : {name: 'cultivationid', operator: 'OPERATOR_EQUAL', value: cultivationid},},
            entity : { name: 'cultivationplot'}
        }
        searchByCriteria(filters, result =>{
            setRecords(result);
        });
    }, [props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <GridTable ui={ui} sx={{mt: 2}} records={records} rowId={'cultivationplotid'} toolBar />
            </Box>
        </Box>
    );
}
