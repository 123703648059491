import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doLogin} from "./actions";
import {actionTypes} from "../../../../Utils/Redux/reducer";
import {error} from "../../../../Utils/Notification/notifications";
import {getToken} from "../../../../Utils/Constant";
import store from "../../../../Utils/Redux/store";
import {useStateValue} from "../../../../Utils/Redux/StateProvider";


export default function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState(false);
    const navigate = useNavigate();
    const [{token}, dispatch] = useStateValue();


    useEffect(() => {},[login])

    const doSignIn = (e) =>{
        if(e){
            e.preventDefault();
            doLogin(email, password, (result) =>{
                if(result){
                    setLogin(true)
                    navigate('/main');
                    localStorage['token-session-erp'] = result.token;
                    dispatch({type: actionTypes.SET_USER, token: result.token});
                    store.dispatch({type: actionTypes.UNAUTHORIZED, authorized: true})
                    store.dispatch({type: actionTypes.SET_USER, token: result.token})
                } else{
                    error("Fallo al acceder")
                }
            })
        } else{
            if(getToken()){
                navigate('/main');
            }
        }
    }

    return (
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        p:5,
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#053a70' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                       Acceso
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <TextField
                            sx={{bgcolor: 'white'}}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label="Usuario"
                            name="user"
                            autoComplete="user"
                            autoFocus
                        />
                        <TextField
                            value={password}
                            sx={{bgcolor: 'white'}}
                            onChange={e => setPassword(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            color={'primary'}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={doSignIn}
                        >
                            ENTRAR
                        </Button>
                    </Box>
                </Box>
            </Container>
    );
}
