import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import WorkspaceMapView from "./MapView/WorkspaceMapView";

export const WorkspaceMapDialog = (props) => {
    const [workspace, setWorkspace] = React.useState(null);


    useEffect(() => {
        const {workspaceid} = props;
        refresh(workspaceid)
    }, [props]);

    const refresh = (workspaceid) => {
        setWorkspace(workspaceid)
    }

    return (
        <Box>
            <WorkspaceMapView markButton={true} workspaceid={workspace} coordinates={{latitude: 38.06820389131554, longitude: -0.872540639659949}}/>
        </Box>
    );
};

export default WorkspaceMapView;
