import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (categoryid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tipo", component: <EditObjectDialog objectid={categoryid} entityDefinition={categoryUI}/> },
            ]}
    })
}

export const categoryUI = {
    entity : {
        name: 'category',
        keyField: 'categoryid',
        label: 'Categoria'
    },
    uiTable: {
        fields:{
            categoryid : {name: 'categoryid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.5 },
            category : {name: 'category', label:'Tipo' , typeName: 'String', editable: true, align: 'center' },
        },
        keyComponent: 'categoryCrudManagement'
    },
    uiForm : {
        fields:{
            categoryid : {name: 'categoryid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.5,  optional: true },
            category : {name: 'category', label:'Tipo' , typeName: 'String', editable: true },
        }
    },
    uiTab: (category) => getUiTab(category)
}

