import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {notEditableFields} from "../../../../../../Utils/UtilsCommon";
import {saleinvoiceUI} from "../Saleinvoice/saleinvoiceCrud";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {doGetDownloadFile, doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import {downloadInvoice} from "../Saleinvoice/actions";


const saleinvoicedetailUI = {
    fields: {
        salenote : {name: 'salenote', label:'Albarán de venta' , typeName: 'es.rbm.model.jpa.Salenote', editable: false},
        total : {name: 'total', label:'Total (€)' , typeName: 'Number', editable: false, optional: true},
        lines : {name: 'lines', label:'Nº lineas' , typeName: 'Number', editable: false, optional: true},
    }
}

export default function SaleorderSaleinvoiceView(props) {
    const {saleorderid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState(null);
    const [ui, setUi] = React.useState(saleinvoiceUI.uiTable);

    useEffect(() => {
        setUi(notEditableFields(ui));
        refresh()
    }, [saleorderid])

    const refresh = () => {
        setDetails(null)
        setRecords([])
        const filters = {
            entity : {name: 'salenote'},
            fields: {
                'saleorderid.saleorderid' : {name: 'saleorderid.saleorderid', operator: 'OPERATOR_EQUAL', value: saleorderid.saleorderid},
                saleinvoiceid : {name: 'saleinvoiceid', operator: OPERATORS.OPERATOR_ISNOTNULL}
            },
        }
        searchByCriteria(filters, result =>{
            if(result && result.length > 0){
                const saleinvoices = result.map(so => so.saleinvoiceid);
                setRecords(saleinvoices);
            }
        });
    }

    const onRowClick = (e, v) => {
        setDetails(null)
        if(e){
            doPost('saleinvoice/getSaleinvoiceInfo', e.row, result => {
                if(result) {
                    const details = result.salenotes.map(sn => {
                        return {salenote: sn.salenote, total: sn.total, lines: sn.salenotedetailList.length}
                    });
                    setDetails(details)
                }
            } )
        }
    }

    const downloadnotePDF = (salenoteid) =>{
        doGetDownloadFile('salenote/downloadSalenote/' + salenoteid.salenote.salenoteid, 'Albaran_venta_n' + salenoteid.salenote.salenoteid + '.pdf')
    }

    const downloadinvoicePDF = (invoiceid) =>{
        downloadInvoice(invoiceid)
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} align={'center'}>
            <Box overflow={'auto'}>
                <GridTable ui={ui}
                           sx={{height: '40vh'}} downloadPDF={downloadinvoicePDF}
                           records={records} rowId={'saleinvoiceid'} onRowClick={onRowClick}
                           toolBar={'D'} hideFooter/>
            </Box>
            {details &&
            <Box overflow={'auto'}>
                <GridTable ui={saleinvoicedetailUI}
                           sx={{height: '30vh', mt:2}}
                           records={details} downloadPDF={downloadnotePDF}
                           hideFooter/>
            </Box>}
        </Box>
    )
}