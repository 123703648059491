import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {warehouseUI} from "../Warehouse/warehouseCrud";
import {townUI} from "../Other/townCrud";
import {stateUI} from "../Other/stateCrud";
import {countryUI} from "../Other/countryCrud";

const getUiTab = (contractorid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Empresa", component: <EditObjectDialog objectid={contractorid} entityDefinition={contractorUI}/> },
                {label : "Documentos", component: <EditObjectDialog objectid={contractorid} entityDefinition={contractorDocument}/> }
            ]}
    })
}

export const contractorUI = {
    entity : {
        name: 'contractor',
        keyField: 'contractorid',
        label: 'Empresa',
        info: {typeName: 'es.rbm.model.jpa.Contractor'}
    },
    uiTable: {
        fields:{
            contractorid : {name: 'contractorid', label:'#' , typeName: 'Number', editable: false,flex:0.3},
            contractor : {name: 'contractor', label:'Empresa' , typeName: 'String', flex: 1.5, align: 'center'},
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true, align: 'center', flex: 0.75},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true, align: 'center'},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true, align: 'center'},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', editable: true, align: 'center'},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, align: 'center'},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'Number', editable: true, flex: 0.5},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, align: 'center'},
        },
        keyComponent: 'contractorCrudManagement'
    },
    uiForm : {
        fields: {
            contractor : {name: 'contractor', label: 'Empresa', typeName: 'String'},
            idcardnumber : {name: 'idcardnumber', label: 'CIF', typeName: 'String', editable: true},
            address : {name: 'address', label: 'Dirección', typeName: 'String', editable: true},
            email : {name: 'email', label: 'Correo', typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label: 'Teléfono', typeName: 'Number', editable: true},
            townid : {name: 'townid', label: 'Población', typeName: 'es.rbm.model.jpa.Town', editable: true, addObject: townUI},
            stateid : {name: 'stateid', label: 'Provincia', typeName: 'es.rbm.model.jpa.State', editable: true, addObject: stateUI},
            postalcode : {name: 'postalcode', label: 'CP', typeName: 'Number', editable: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, addObject: countryUI},
        }
    },
    uiTab: (contractor) => getUiTab(contractor)
}


const contractorDocument = {
    entity : {
        name: 'contractor',
        keyField: 'contractorid',
        label: 'Empresa',
        info: {typeName: 'es.rbm.model.jpa.Contractor'}
    },
    uiForm : {
        fields: {
            seriepurchaseorder : {name: 'seriepurchaseorder', label: 'Serie pedido compras', typeName: 'String', size: 2, optional: true},
            seriepurchasenote : {name: 'seriepurchasenote', label: 'Serie albarán compras', typeName: 'String', size: 2, optional: true},
            seriepurchaseinvoice : {name: 'seriepurchaseinvoice', label: 'Serie factura compras', typeName: 'String', size: 2, optional: true},
            seriepurchaseinvoicerev : {name: 'seriepurchaseinvoicerev', label: 'Serie factura compras devuelta', typeName: 'String', size: 2, optional: true},
            seriesaleorder : {name: 'seriesaleorder', label: 'Serie pedido ventas', typeName: 'String', size: 2, optional: true},
            seriesalenote : {name: 'seriesalenote', label: 'Serie albarán ventas', typeName: 'String', size: 2, optional: true},
            seriesaleinvoice : {name: 'seriesaleinvoice', label: 'Serie factura ventas', typeName: 'String', size: 2, optional: true},
            seriesaleinvoicerev : {name: 'seriesaleinvoicerev', label: 'Serie factura ventas devuelta', typeName: 'String', size: 2, optional: true},
            resetdocumentnumber : {name: 'resetdocumentnumber', label: 'Fecha de reinicio de números', typeName: 'Date', size: 2, optional: true, empty: true},
        }
    },
}