import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {groupBy, notEditableFields} from "../../../../../../Utils/UtilsCommon";
import {salenoteUI} from "../Salenote/salenoteCrud";


const salenotedetailUI = {
    fields: {
        salenotedetailid : {name: 'salenotedetailid', label:'#' , typeName: 'Number', editable: false, optional: true},
        salenotedetail : {name: 'salenotedetail', label:'Descripción' , typeName: 'String', editable: false },
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: false},
        warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: false, urlRecords: 'rest/warehouse/getActives'},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: false},
    }
}

export default function SaleorderSalenoteView(props) {
    const {saleorderid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState(null);
    const [groups, setGroups] = React.useState([]);
    const [ui, setUi] = React.useState(salenoteUI.uiTable);

    useEffect(() => {
        setUi(notEditableFields(ui));
        refresh()
    }, [saleorderid])

    const refresh = () => {
        setDetails(null)
        setRecords([])
        const filters = {
            entity : {name: 'salenote'},
            fields: {'saleorderid.saleorderid' : {name: 'saleorderid.saleorderid', operator: 'OPERATOR_EQUAL', value: saleorderid.saleorderid}},
        }
        searchByCriteria(filters, result =>{
            if(result && result.length > 0){
                const ids = result.map(sn => {
                    return {salenoteid: sn.salenoteid}
                });
                const filters = {
                    entity : {name: 'salenotedetail'},
                    fields: {salenoteid : {name: 'salenoteid', operator: 'OPERATOR_IN', value: ids}},
                }
                searchByCriteria(filters, result1 =>{
                    const salenoteGroup = groupBy(result1, "salenoteid.salenoteid");
                    setGroups(salenoteGroup);
                    const newRecords = Object.keys(salenoteGroup).map(key => salenoteGroup[key][0].salenoteid);
                    setRecords(newRecords)
                    setDetails(null);
                });
            }
        });
    }

    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.salenoteid]);
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} align={'center'}>
            <Box overflow={'auto'}>
                <GridTable ui={ui}
                           sx={{height: '40vh'}}
                           records={records} rowId={'salenoteid'} onRowClick={onRowClick}
                           hideFooter/>
            </Box>
            { details && <Box overflow={'auto'}>
                <GridTable ui={salenotedetailUI}
                           sx={{height: '30vh', mt:2}}
                           records={details} rowId={'salenotedetailid'}
                           hideFooter/>
            </Box> }
        </Box>
    )
}