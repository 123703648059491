import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";
import {stateUI} from "./stateCrud";

const getUiTab = (townid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Población", component: <EditObjectDialog objectid={townid} entityDefinition={townUI}/> },
            ]}
    })
}

export const townUI = {
    entity : {
        name: 'town',
        keyField: 'townid',
        label: 'Población',
        info: {typeName: 'es.rbm.model.jpa.Town'}
    },
    uiTable: {
        fields:{
            townid : {name: 'townid', label:'#' , typeName: 'Number', editable: false, flex:0.3, optional: true},
            town : {name: 'town', label:'Población' , typeName:'String', align: 'center'},
            stateid : {name: 'stateid', label:'Provincia' , typeName:'es.rbm.model.jpa.State', align: 'center'},
        },
        keyComponent: 'townCrudManagement'
    },
    uiForm : {
        fields:{
            townid : {name: 'townid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            town : {name: 'town', label:'Población' , typeName:'String', align: 'center'},
            stateid : {name: 'stateid', label:'Provincia' , typeName:'es.rbm.model.jpa.State', align: 'center', addObject: stateUI},
        }
    },
    uiTab: (town) => getUiTab(town)
}

