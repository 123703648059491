import React, {useState, useEffect, useRef} from "react";
import {MapContainer, Polygon, Popup, TileLayer} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import {doGet} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import TimetickMarkers from "./TimetickMarkers";
import {getRelativeMaxViews, maxHeightViews} from "../../../../../../Utils/Constant";

const uiFilters = {
  fields: {
    date : {name: 'date', label: 'Fecha' ,typeName: 'Date', size: 12, height: 'small'}
  }
}

const TimetickMapView = (props) => {
  const [state, setState] = useState({
    zoom: 15,
    data: [],
  });
  const [filters, setFilters] = React.useState({date: new Date().getTime()});
  const mapRef = useRef(null);
  const {height, filterDate} = props;

  useEffect(() => {
      refresh()
  }, [props, filters]);

  const refresh = (day) => {
    let date = filters?.date? filters.date : new Date().getTime();
    let isValidDate = Date.parse(date)
    let dateTicks;
    if(isValidDate && Object.keys(date).length>0){
      dateTicks = date;
    } else if(day){
      dateTicks = day;
    } else{
      dateTicks = Date.parse(new Date());
    }

    doGet("presence/getTimetickBySubjectGroup/" + dateTicks, timeticks =>{
      const ticksList = timeticks.reduce((l, c) => {
        return [...l, ...c.ticks]
      }, []);
      const tickWithLocation = ticksList.filter(tt => tt.latitude);
      const currentLocation = {
        lat: tickWithLocation.length > 0 ?  tickWithLocation[0].latitude : 38.08635047,
        lng: tickWithLocation.length > 0 ?  tickWithLocation[0].longitude : -0.65302153,
      };
      panToNewLocation(currentLocation);
      setState({
        ...state,
        data: ticksList,
        currentLocation,
      });
    })
  }
  
  const panToNewLocation = (currentLocation) => {
    const newLocation = [currentLocation.lat, currentLocation.lng];
    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
    }
  };

  const handledChange = (filter) =>{
    setFilters({...filter});
  }

  return (
      <Box>
        {filterDate !== false && <SingleRecordForm ui={uiFilters} records={filters} sx={{backgroundColor: 'white', pb:1, pr: 1, mt: 1}} onChange={handledChange}/>}
        {state.currentLocation &&
            <MapContainer center={state.currentLocation} zoom={state.zoom} style={{ marginTop: 7, height: height || getRelativeMaxViews(-3), width: '100%' }} ref={mapRef}>
              <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <TimetickMarkers timeticks={state.data}/>
            </MapContainer>}
      </Box>
  );
};

export default TimetickMapView;
