import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import ProductFertigationsView from "./ProductFertigationsView";

const getUiTab = (fertigationproductid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Producto", component: <EditObjectDialog objectid={fertigationproductid} entityDefinition={fertigationproductUI}/> },
                {label : "Riegos", component: <ProductFertigationsView productid={fertigationproductid}/>},
            ]}
    })
}

export const fertigationproductUI = {
    entity : {
        name: 'product',
        keyField: 'productid',
        label: 'Producto de riego',
        urlRefresh: 'agro/getAllsFertigationproduct',
        info: {typeName: 'es.rbm.model.jpa.Product'},
        hideNewButton: true,
        colorRow: 'active',
        urlExcelImport: "agro/importFertigationProductFromExcel"
    },
    uiTable: {
        fields:{
            productid : {name: 'productid', label: '#' ,typeName: 'Number', optional: true, editable: false, flex: 0.3},
            product : {name: 'product', label: 'Producto' ,typeName: 'String'},
            measureunitid : {name: 'measureunitid', label: 'Unidad de medida' ,typeName: 'es.rbm.model.jpa.Movementunit'},
            externalcode : {name: 'externalcode', label: 'Codigo externo' ,typeName: 'String', optional: true},
            extrainformation : {name: 'extrainformation', label: 'Información extra' ,typeName: 'String', optional: true, size: 3},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
            nitrogen : {name: 'nitrogen', label: '% Nitrogeno (N)' ,typeName: 'String', optional: true},
            phosphorus : {name: 'phosphorus', label: '% Fósforo (P)' ,typeName: 'String', optional: true},
            potassium : {name: 'potassium', label: '% Potasio (K)' ,typeName: 'String', optional: true},
            calcium : {name: 'calcium', label: '% Calcio (Ca)' ,typeName: 'String', optional: true, visible: false},
            magnesium : {name: 'magnesium', label: '% Magnesio (Mg)' ,typeName: 'String', optional: true, visible: false},
            sulfur : {name: 'sulfur', label: '% Azufre (S)' ,typeName: 'String', optional: true, visible: false},
            iron : {name: 'iron', label: '% Hierro (Fe)' ,typeName: 'String', optional: true, visible: false},
            boron : {name: 'boron', label: '% Boro (Fe)' ,typeName: 'String', optional: true, visible: false},
            molybdenum : {name: 'molybdenum', label: '% Molibdeno  (Mo)' ,typeName: 'String', optional: true, visible: false},
            zinc : {name: 'zinc', label: '% Zinc (Zn)' ,typeName: 'String', optional: true, visible: false},
            cobre : {name: 'cobre', label: '% Cobre (Cu)' ,typeName: 'String', optional: true, visible: false},
            manganeso : {name: 'manganeso', label: '% Manganeso (Mn)' ,typeName: 'String', optional: true, visible: false},

        },
        keyComponent: 'fertigationproductCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            productid : {name: 'productid', label: 'Cod.' ,typeName: 'Number', optional: true, editable: false},
            product : {name: 'product', label: 'Producto' ,typeName: 'String'},
            description : {name: 'description', label: 'Descripción' ,typeName: 'String'},
            measureunitid : {name: 'measureunitid', label: 'Unidad de medida' ,typeName: 'es.rbm.model.jpa.Movementunit'},
            externalcode : {name: 'externalcode', label: 'Codigo externo' ,typeName: 'String', optional: true},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
            extrainformation : {name: 'extrainformation', label: 'Información extra' ,typeName: 'String', optional: true, size: 12, multiline: true, rows: 3},
            nitrogen : {name: 'nitrogen', label: '% Nitrogeno (N)' ,typeName: 'String', optional: true,size: 3},
            phosphorus : {name: 'phosphorus', label: '% Fósforo (P)' ,typeName: 'String', optional: true,size: 3},
            potassium : {name: 'potassium', label: '% Potasio (K)' ,typeName: 'String', optional: true,size: 3},
            calcium : {name: 'calcium', label: '% Calcio (Ca)' ,typeName: 'String', optional: true,size: 3},
            magnesium : {name: 'magnesium', label: '% Magnesio (Mg)' ,typeName: 'String', optional: true,size: 3},
            sulfur : {name: 'sulfur', label: '% Azufre (S)' ,typeName: 'String', optional: true,size: 3},
            iron : {name: 'iron', label: '% Hierro (Fe)' ,typeName: 'String', optional: true,size: 3},
            boron : {name: 'boron', label: '% Boro (Fe)' ,typeName: 'String', optional: true,size: 3},
            molybdenum : {name: 'molybdenum', label: '% Molibdeno  (Mo)' ,typeName: 'String', optional: true,size: 3},
            zinc : {name: 'zinc', label: '% Zinc (Zn)' ,typeName: 'String', optional: true,size: 3},
            cobre : {name: 'cobre', label: '% Cobre (Cu)' ,typeName: 'String', optional: true,size: 3},
            manganeso : {name: 'manganeso', label: '% Manganeso (Mn)' ,typeName: 'String', optional: true,size: 3},
        }
    },

    uiTab: (fertigationproduct) => getUiTab(fertigationproduct)
}

