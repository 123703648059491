import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {categoryUI} from "./categoryCrud";


const ui = {
    fields:{
        since : {name: 'since', label: 'Desde' ,typeName: 'Date', size: 3},
        until : {name: 'until', label: 'Hasta' ,typeName: 'Date', optional: true, empty: true, size: 3},
        hourlycost : {name: 'hourlycost', label: '€ / hora' ,typeName: 'Number', size: 2},
        categoryid : {name: 'categoryid', label: 'Categoria' ,typeName: 'es.rbm.model.jpa.Category', addObject: categoryUI, size: 4},

    },
    keyComponent: 'NewWorkerAbsenceDialog'
}
export default function NewWorkerContractDialog(props) {
    const {open, setOpen, worker} = props;
    const [title, setTitle] = React.useState('Nuevo tipo');
    const [contractid, setContractid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setContractid({...record});
        setComplete(complete);
    }

    const addContract = (contractid, callback) => {
        const toSend = {...contractid, workerid: {workerid: worker.workerid}}
        doPost('rest/contract/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const contractid1 = props.contractid;
        setContractid(contractid1 ? contractid1 : {});
        setTitle(contractid1 ? 'Editar contrato' : 'Nuevo contracto');
    }, [props.contractid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addContract(contractid, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={contractid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
