import {doDelete, doPost, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../../../Utils/Notification/notifications";

export const updateSalenoteField = (record, callback) =>{
    doPut('rest/salenote/update',record, response =>{
        if(response){
            callback && callback()
        }
    })
}

export const updateSalenote = (salenoteid, callback) =>{
    doPut('rest/salenote/update',salenoteid, response =>{
        if(response){
            success('Guardado correctamente', callback && callback(response))
            callback && callback(response.data)
        }
    })
}

export const updateSalenotedetail = (salenotedetailid, callback) =>{
    doPut('rest/salenotedetail/update',salenotedetailid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deleteSalenote = (salenoteid, callback) =>{
    doDelete('rest/salenote/delete/' + salenoteid.salenoteid,null, response =>{
        callback && callback();
    })
}
export const deleteSalenotedetail = (salenotedetailid, callback) =>{
    doDelete('rest/salenotedetail/delete/' + salenotedetailid.salenotedetailid,null, response =>{
        callback && callback();
    })
}

export const getDetails = (salenoteid, callback) =>{
    doPost('salenote/getDetails',salenoteid, result => callback && callback(result))
}

export const getTotalAmount = (salenotedetailid) =>{
    const totalsalenotedetailprice = salenotedetailid.unitprice * salenotedetailid.quantity;
    if(salenotedetailid.discount){
        return Math.floor((((totalsalenotedetailprice/100)*(100-salenotedetailid.discount)) *100))/100;
    }
    return (totalsalenotedetailprice * 100) /100;
}