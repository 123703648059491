import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (workernoteid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Nota", component: <EditObjectDialog objectid={workernoteid} entityDefinition={workernoteUI}/> },
            ]}
    })
}

export const workernoteUI = {
    entity : {
        name: 'workernote',
        keyField: 'workernoteid',
        label: 'Nota',
        colorRow: 'read',
    },
    uiTable: {
        fields:{
            workernoteid : {name: 'workernoteid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.5, sort: 'asc' },
            workernote : {name: 'workernote', label:'Nota' , typeName: 'String', editable: true},
            day : {name: 'day', label:'Fecha' , typeName: 'Date', editable: true},
            workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', editable: true},
            readhour : {name: 'readhour', label:'Hora de lectura' , typeName: 'Time', editable: false},
        },
        keyComponent: 'workernoteCrudManagement',
    },
    uiForm : {
        fields: {
            day : {name: 'day', label:'Fecha' , typeName: 'Date', editable: true},
            workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', editable: true},
            workernote : {name: 'workernote', label:'Nota' , typeName: 'String', editable: true, multiline: true, size: 12, rows: 5},
        }
    },
    uiTab: (workernote) => getUiTab(workernote)
}

