import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (absencetypeid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tipo", component: <EditObjectDialog objectid={absencetypeid} entityDefinition={absencetypeUI}/> },
            ]}
    })
}

export const absencetypeUI = {
    entity : {
        name: 'absencetype',
        keyField: 'absencetypeid',
        label: 'Tipo de ausencia'
    },
    uiTable: {
        fields:{
            absencetypeid : {name: 'absencetypeid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            absencetype : {name: 'absencetype', label:'Tipo' , typeName: 'String', editable: true, align: 'center'},
            holidays : {name: 'holidays', label:'Vacaciones' , typeName: 'Boolean', editable: true, align: 'center', optional: true},
        },
        keyComponent: 'absencetypeCrudManagement'
    },
    uiForm : {
        fields:{
            absencetypeid : {name: 'absencetypeid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            absencetype : {name: 'absencetype', label:'Tipo' , typeName: 'String', editable: true },
            holidays : {name: 'holidays', label:'Vacaciones' , typeName: 'Boolean', editable: true, optional: true },
        }
    },
    uiTab: (absencetype) => getUiTab(absencetype)
}

