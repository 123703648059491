import React, { useState } from 'react';
import {Viewer, SpecialZoomLevel, Worker, ScrollMode} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const viewerContainerStyle = {
    width: '100%',
    height: '87vh',
    border: '1px solid rgba(0, 0, 0, 0.3)',
};

const PdfViewer = ({ pdfUrl }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [totalPages, setTotalPages] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPages(numPages);
    };

    return (
        pdfUrl && <div style={viewerContainerStyle}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer
                    fileUrl={pdfUrl}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={SpecialZoomLevel.PageWidth}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    scrollMode={ScrollMode.Vertical}
                />
            </Worker>
        </div>
    );
};

export default PdfViewer;
