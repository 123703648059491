import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {treatmentUI} from "../treatmentCrud";
import {useEffect} from "react";
import NewTreatmentForm from "./NewTreatmentForm";
import SelectCultivationForm from "./SelectCultivationForm";
import NewTreatmentDetails from "./NewTreatmentDetails";
import {success} from "../../../../../../../Utils/Notification/notifications";
import Grid from "@mui/material/Grid";
import {doPost} from "../../../../../../../Utils/Restclient/NetworkActions";

const steps = ['Tratamiento', 'Cultivos', 'Detalles'];

export const uiCultivation = {
    entity : {
        name: 'cultivation',
        keyField: 'cultivationid',
        label: 'Cultivo',
        info: {typeName: 'es.rbm.model.jpa.Cultivation'}
    },
    uiTable: {
        fields:{
            cultivationid : {name: 'cultivationid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3 },
            cultivation : {name: 'cultivation', label:'Nombre' , typeName: 'String', editable: false},
            productid : {name: 'productid', label:'Producto cultivado', typeName: 'es.rbm.model.jpa.Product'},
            since : {name: 'since', label:'Desde' , typeName: 'Date', editable: false},
            until : {name: 'until', label:'Hasta' , typeName: 'Date', editable: false},
            area : {name: 'area', label:'Área(Ha)' , typeName: 'Number', editable: false},
        },
        keyComponent: 'newTreatmentCrudManagement',
        checkboxSelection: true
    },
}

function getStepContent(step, state, setState, setComplete) {
    switch (step) {
        case 0:
            return <NewTreatmentForm setComplete={setComplete} state={state} setState={setState} entityDefinition={treatmentUI}/>;
        case 1:
            return <SelectCultivationForm  setComplete={setComplete} state={state} setState={setState} entityDefinition={uiCultivation}/>;
        case 2:
            return <NewTreatmentDetails setComplete={setComplete} state={state} setState={setState}/>;
        default:
            throw new Error('No se permite esta opción');
    }
}

export default function NewTreatment(props) {
    const {setOpenNewObject, openNewObject} = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [state, setState] = React.useState({});
    const [complete, setComplete] = React.useState(false);


    useEffect(() => {

    }, [state])

    const handleNext = () => {
        if(activeStep === steps.length-1) {
            doPost("agro/generateNewTreatment", state, result =>{
                setOpenNewObject(!openNewObject)
                success("Tratamietno generado correctamente")
            })

        } else{
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
            <Container component="main" maxWidth="xl" sx={{ mb: 2 }}>
                <Grid container>
                    <Grid item md={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button size={'small'} variant="contained" color="error" onClick={() => setOpenNewObject(!openNewObject)} sx={{mt: 3, ml: 1}}>
                                X
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        {activeStep !== steps.length &&
                            <Container component="main" maxWidth="xl" sx={{mb: 2}}>
                                <Typography sx={{mt: 2}} variant="h4" align="center" color="text.primary" paragraph>
                                    Nuevo tratamiento
                                </Typography>
                                <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <React.Fragment>
                                    <React.Fragment>
                                        <Box maxHeight={'60vh'} minHeight={'60vh'} overflow={"auto"}
                                             sx={{width: '100%', height: '50vh', pr: 2}}>
                                            {getStepContent(activeStep, state, setState, setComplete)}
                                        </Box>
                                        <Grid container>

                                            <Grid md={12} item>
                                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    {activeStep !== 0 && (
                                                        <Button size={'small'} onClick={handleBack} sx={{mt: 3, ml: 1}}>
                                                            Atrás
                                                        </Button>
                                                    )}

                                                    <Button size={'small'} variant="contained" disabled={!complete} onClick={handleNext} sx={{mt: 3, ml: 1}}>
                                                        {activeStep === steps.length - 1 ? 'Generar' : 'Siguiente'}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                </React.Fragment>
                            </Container>
                        }
                    </Grid>
                </Grid>
            </Container>
    );
}
