import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import SignIn from "../Login/SignIn";
import Box from "@mui/material/Box";
import logo from "../../../../assets/logo.png"

function PageDescription() {
    return (

        <Grid container bgcolor={'#ffffff'} sx={{height: '88vh'}}>
            <Grid item md={8}>
                <Box
                    sx={{
                        height: '88vh',
                        width: '100%',
                        bgcolor: 'white'
                    }}
                    align={'center'}
                >
                    <img src={logo} height={'100%'}/>
                </Box>
            </Grid>
            <Grid item md={3}>
                    <Paper sx={{height: '53vh', mr: 2, mt: 20, backgroundColor: '#e9e9ea'}} elevation={6}>
                        <SignIn/>
                    </Paper>
            </Grid>
            <Grid item md={1}/>
        </Grid>
    );
}

export default PageDescription;
