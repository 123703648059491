import * as React from 'react';
import {useCallback, useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import Paper from "@mui/material/Paper";

const providerproducttableUI = {
    fields: {
        providerid : {name: 'providerid', label: 'Proveedor',typeName:'es.rbm.model.jpa.Provider', flex: 0.45, editable: false},
        price : {name: 'price', label:'Precio' , typeName: 'Number', flex: 0.4, editable: false},
        since : {name: 'since', label: 'Desde',typeName:'Date', flex: 0.45, editable: false},
        until : {name: 'until', label: 'Hasta',typeName:'Date', flex: 0.45, editable: false},
        fromquantity : {name: 'fromquantity', label:'Desde cantidad' , typeName: 'Number', flex: 0.35, editable: false},
        toquantity : {name: 'toquantity', label:'Hasta cantidad' , typeName: 'Number', flex: 0.35, editable: false},
        reference : {name: 'reference', label:'Referencia' , typeName: 'String', flex: 0.4, align: 'center', editable: false},
    },
    keyComponent: 'ProductProviderproductView',
}

export default function ProviderproductView(props) {
    const {productid} = props;
    const [records, setRecords] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [productid, props])

    const refresh = useCallback(() =>{
        const filters = {
            entity : {name: 'providerproduct'},
            fields: {
                productid : {name: 'productid', operator: OPERATORS.OPERATOR_EQUAL, value: productid},
                since : {name: 'since', operator: OPERATORS.OPERATOR_LTE, value: new Date().getTime()},
                until : {name: 'until', operator: OPERATORS.OPERATOR_GTE, value: new Date().getTime()},
            },
        }
        searchByCriteria(filters, result =>{
            if (result) {
                setRecords(result);
            }
        });
    }, [props])

    return(
        <Paper sx={{m: 2, alignItems: 'center'}}>
            <Paper overflow={'auto'}>
                <GridTable ui={providerproducttableUI}
                           sx={{height: '70vh', mt: 10}} density={'compact'}
                           records={records} rowId={'providerproductid'}
                           toolBar
                />
            </Paper>
        </Paper>
    )
}