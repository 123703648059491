import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doGet, doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import {sidebarTest} from "../../../../../../sidebarTest";
import {appStyles} from "../../../../../../styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AccountWebSidebar from "./AccountWebSidebar";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {success} from "../../../../../../Utils/Notification/notifications";

export default function AccountWebMenu(props) {
    const {accountid} = props;
    const [menu, setMenu] = React.useState(null);
    const [change, setChange] = React.useState(false);
    const [permissions, setPermissions] = React.useState([]);
    const classes = appStyles();


    useEffect(() => {
        refresh()
    }, [])

    const refresh = (callback, cache) => {
        doPost("ui/getMenuWebEntriesAndUserPermissions",accountid, result => {
            processResult(result, callback);
        })
    }

    const processResult = (r) => {
        let menuEntriesUI = r.menuEntriesUI;
        let sidebarTest1 = sidebarTest;
        const filter = sidebarTest1.filter(e => menuEntriesUI.includes(e.key));
        setMenu(filter);
        setPermissions(r.permissions)
    }

    const savePermission = () => {
        const toSend = {permissions: [...permissions], accountid: accountid}
        doPost('ui/saveWebUserPermissions', toSend, r => {
            success('Guardado correctamente');
            setChange(false);
        })
    }

    const selectAlls = (menu) => {
        setChange(true);
        let keys = [];
        function recursiveCheck(obj) {
            if (obj.component !== null && obj.key) {
                keys.push(obj.key);
            }

            if (obj.children !== null && Array.isArray(obj.children)) {
                obj.children.forEach(child => recursiveCheck(child));
            }
        }

        menu.forEach(obj => recursiveCheck(obj));

        setPermissions([...keys]);
    }

    const handleCheckClick = (r) => {
        setChange(true);
        let target = r.target;
        if (target.value && !permissions.includes(target.name)) {
            setPermissions([...permissions, target.name]);
        } else {
            let index = permissions.indexOf(target.name);
            if (index !== -1) {
                  permissions.splice(index, 1);
            }
        }
    }

    const clearAll = () => {
        setChange(true);
        setPermissions([]);
    }

    return (
        <Grid container>
            {menu && <Grid sx={{mt: 5}} item md={11} alignContent={'center'}>
                <Box  sx={{height: '83vh', px: 1, alignContent: 'center'}} overflow={'auto'}>
                    <Paper className={classes.containerOverflow} sx={{pt:1, height: '80vh', bgcolor: 'white'}}>
                        <Box>
                            <AccountWebSidebar id={'AccountWebSidebar'} key={"AccountWebSidebar"} handleCheckClick={handleCheckClick} main={true} permissions={permissions} sidebar={menu}/>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
            }
            <Grid item md={1}>
                <Grid container>
                    <Grid item md={12}>
                        <Box component="form" noValidate sx={{pb: 1, pt: 6}}>
                            <SuccessButton text={'GUARDAR'} fullWidth onClick={(e) => {e.preventDefault(); savePermission()}} disabled={!change}/>
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        <Box component="form" noValidate sx={{pb: 1}}>
                            <SuccessButton text={'SELECCIONAR TODOS'} color={'success'} fullWidth onClick={(e) => {e.preventDefault(); selectAlls(menu)}}/>
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        <Box component="form" noValidate sx={{pb: 1}}>
                            <SuccessButton text={'QUITAR TODOS'} color={'error'} fullWidth onClick={e => {e.preventDefault(); clearAll()}}/>
                        </Box>
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    );
}
