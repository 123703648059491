import * as React from 'react';
import Paper from '@mui/material/Paper';
import MainFeaturedPost from "./MainFeaturePost";
import BodyDescription from "./BodyDescription";

const mainFeaturedPost = {
    title: 'SIBEMA',
    description:
        "",
    image: 'https://s3.eu-west-2.amazonaws.com/transeop/Blog/erp-en-logistica-y-transporte.jpeg',
    imageText: 'Entrar',
    linkText: 'Mas información',
};

const bodyDescriptionPost = {
    image: 'https://s3.eu-west-2.amazonaws.com/transeop/Blog/erp-en-logistica-y-transporte.jpeg',
    imageText: '',
    textDescription: '',
    title: "",
    firstButton:{
        title:"Entrar",
        destination: "/login"
    },
    secondButton:{
        title:"Descargar APK",
        destination: "/"
    },
    footerText: "SIBEMA"
};

function PageDescription() {
    return (
        <Paper>
            <MainFeaturedPost post={mainFeaturedPost}/>
            <BodyDescription post={bodyDescriptionPost}/>
        </Paper>
    );
}

export default PageDescription;
