export const alusaleorderReport = {
    ui : {
        fields :{
            saleorderid : {name: 'saleorderid', label: 'Presupuesto' ,typeName: 'es.rbm.model.jpa.Alusaleorder', size: 12},
        }
    },
    title: 'Informe de costes',
    description: 'Resumen de coste imputados a un presupuesto',
    url: 'reports/report/es.rbm.report.AlmAlussaleorderReport',
    format: () => ('.xlsx'),
    documentName: 'Resumen_mensual' + new Date(),
    keyForm: 'AlmAlussaleorderReport',
}