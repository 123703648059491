import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {getProviderproductprice, updatePurchaseorderdetail} from "./actions";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {productUI} from "../Product/productCrud";

const ui = {
    fields: {
        quantity : {name: 'quantity', label:'Cant.' , typeName: 'Number', editable: true, size: 2, autofocus: true},
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: true, size: 6, addObject: productUI},
        price : {name: 'price', label:'Precio' , typeName: 'Number', editable: true, size: 2},
        discount : {name: 'discount', label:'% Dto' , typeName: 'Number', editable: true, optional: true, size: 2},
        remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', size: 12, align: 'center', optional: true},
    },
    onChangeHandleTrigger : (records, field, value, callback) =>{
        getProviderproductprice(records, field, value, callback);
    }

}

export default function EditPurchaseorderdetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [purchaseorderdetailid, setPurchaseorderdetail] = React.useState({...selected});
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nuevo detalle');

    const handledChange = (record, complete) => {
        setPurchaseorderdetail({...record});
        setComplete(complete);
    }

    useEffect(() => {
        if(purchaseorderdetailid && purchaseorderdetailid.purchaseorderdetailid){
            setTitle('Editar detalle')
        }
    }, [props])

    const onClickSuccess = (e) => {
        e.preventDefault()
        updatePurchaseorderdetail(purchaseorderdetailid, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={purchaseorderdetailid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onClickSuccess} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
