import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useEffect} from "react";

export function SimpleDialog(props) {
    const { onClose, selectedValue, open, options } = props;

    useEffect(() => {}, [props])

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <List sx={{ pt: 0 }}>
                {options && options.map((option, i) => (
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => handleListItemClick(option)} key={i} disabled={!option.component || option.disabled}>
                                {option.icon}
                            <ListItemText sx={{ml: 2}} primary={option.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}