import * as React from 'react';
import {createTheme} from "@mui/material/styles";
import {createStyles} from "@mui/material";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import {Tooltip} from "recharts";
import {useEffect} from "react";

const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                border: `1px solid ${theme?.palette?.divider}`,
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: 26,
                borderRadius: 2
            },
            value: {
                position: "absolute",
                lineHeight: "24px",
                width: "100%",
                display: "flex",
                justifyContent: "center"
            },
            bar: {
                height: "100%",
                display: "flex",
                "& .planned": {
                    backgroundColor: "rgb(255,255,255)"
                },
                "& .launched": {
                    backgroundColor: "#f4f136"
                },
                "& .done": {
                    backgroundColor: "#289e49"
                }
            }
        }),
    { defaultTheme }
);

export const PlannedproductionCell = (props) => {
    const {data, onCellClick, bgcolor} = props;
    const classes = useStyles();
    const {quantity, done, launched} = data;
    const quantityToShow = quantity || 0;
    const doneToShow = done || 0;
    const launchedToShow = launched || 0;
    const [state, setState] = React.useState({});

    useEffect(() => {
        const donePercent = (doneToShow / quantityToShow) * 100;
        const launchedPercent = ((launchedToShow - doneToShow) / quantityToShow) * 100;
        const plannedPercent = 100 - donePercent - launchedPercent;

        setState({donePercent, launchedPercent, plannedPercent})
    }, [props]);

    const resumeCell = doneToShow.toLocaleString() + "/" + launchedToShow.toLocaleString() + "/" + quantityToShow.toLocaleString();
    const titleCell = 'Total: ' + quantityToShow.toLocaleString() + '\nLanzado: ' + launchedToShow + '\nProducido: ' + doneToShow.toLocaleString();

    return (
        data &&
            <Box sx={{bgcolor: bgcolor || 'default'}} className={classes.root} onClick={() => onCellClick(data)}>
                <div className={classes.root} onClick={onCellClick}>
                    <div className={classes.value} title={titleCell}>
                        {`${resumeCell}`}
                    </div>
                    <div className={classes.bar}>
                        <div
                            style={{ width: `${state.donePercent}%` }}
                            className="done"
                            title={launchedToShow}
                        />
                        <div
                            style={{ width: `${state.launchedPercent}%` }}
                            className="launched"
                            title={launchedToShow}
                        />
                        <div
                            style={{ width: `${state.plannedPercent}%` }}
                            className="planned"
                        />
                    </div>
                </div>
            </Box>
    );
};
