import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {
    doGet,
    doGetDownloadFile,
    doPost,
    doPostDownloadFile,
    doPostExcel
} from "../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../View/Button/SuccessButton";
import {error, success} from "../../../../Utils/Notification/notifications";
import Grid from "@mui/material/Grid";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from "@mui/icons-material/Refresh";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import DragDropFiles from "../../../../View/DragDropFiles/DragDropFiles";
import Dialog from "@mui/material/Dialog";
import {getToken, rootServer, serverName} from "../../../../Utils/Constant";
import {groupBy} from "../../../../Utils/UtilsCommon";
import {subsideBarTab} from "../sidebarActions";
import HorizontalTabList from "../../../../View/Tab/HorizontalTabList";
import PhotoIcon from '@mui/icons-material/Photo';

export default function ERPConfigurationView(props) {
    const [records, setRecords] = React.useState(null);
    const [form, setForm] = React.useState({});
    const [upload, setUpload] = React.useState(false);
    const [uploadPhoto, setUploadPhoto] = React.useState(false);
    const [fileToUpload, setFileToUpload] = React.useState(null);

    const handledChange = (record, complete) => {
        setForm({...record});
    }

    useEffect(() => {
        refresh()
    }, [props])

    const refresh = () => {
      doGet("configuration/getServerConfiguration", result => {
          loadRecords(result);
      })
    }

    const loadRecords = (configuration) => {
        const record = configuration.reduce((l, c)=>{
                const key = c.attribute;
                return {...l, [key] : c.value}
            }, {});
        setRecords(configuration);
        setForm(record);
    }

    const generateUIAndRecords = () => {
        if (records && form) {
            const grouped = groupBy(records, 'group');
            const newUI = Object.keys(grouped).map(k => {
                const groupedElement = grouped[k];
                const fields = groupedElement.reduce((l, c)=>{
                    const key = c.attribute;
                    return {...l, [key] : {name: c.attribute, label: c.attribute ,typeName: c.attributeclassname, size: c.size, optional: true}}
                }, {});
                return {label: k, component:
                        <Box maxWidth>
                            <SingleRecordForm sx={{mt: 3}} ui={{fields: {...fields}}} records={form} onChange={handledChange} disableAutoFocus />
                        </Box>}
            })
            return {fields: [...newUI]};
        }
    }

    const updateConfiguration = () => {
        doPost("configuration/updateConfiguration", {attributes: form}, () =>{
            success('La configuración será aplicada al reiniciar el servidor')
        });
    }

    const downloadFile = () => {
        doGetDownloadFile('configuration/downloadConfiguration', serverName + '_config.csv' );
    }

    const uploadFile = () => {
        setUpload(true);
    }

    const uploadMainPhoto = () => {
        setUploadPhoto(true);
    }

    const doImportData = () => {
        if (uploadPhoto) {
            const toSend = {
                data: Array.from(new Uint8Array(fileToUpload.value)),
                filename: serverName,
                path: 'main/documents/' + serverName
            }
            doPost('rest/saveFile', toSend, () => {
                setUploadPhoto(false);
                setFileToUpload(null);
            })
        } else if (upload) {
            doPostExcel('configuration/updateConfigurationFromFile', fileToUpload.value, () => {
                setUpload(false);
                setFileToUpload(null);
                refresh();
                success('La configuración será aplicada al reiniciar el servidor');
            })
        }
    }

    const handledFileAdd = (file1) =>{
        setFileToUpload(file1.target);
    }

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Dialog open={upload || uploadPhoto} disableEscapeKeyDown={false}>
                <Box maxWidth={'md'}>
                    <Grid container>
                        <Grid item md={12} sx={{margin: 2}}>
                            <Grid container sx={{mb:2}}>
                                <Grid item md={11} sx={{mt:6}}>
                                    <Typography  variant="h5" align="center" color="text.secondary" paragraph>
                                        Seleccione un archivo
                                    </Typography>
                                </Grid>
                                <Grid item md={1}>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button size={'small'} variant="contained" color="error" onClick={() => {setFileToUpload(null); setUploadPhoto(false) ;setUpload(false)}}>
                                            X
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <DragDropFiles onLoadFile={handledFileAdd} name={'file'} file={fileToUpload}
                                           types={uploadPhoto ? ["PNG", "JPG"] : ["CSV"]}/>
                            <SuccessButton text={'SUBIR ARCHIVO'} fullWidth sx={{mb: 1, mt: 5}} onClick={doImportData}/>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            <Grid container>
                <Grid item md={2}>
                    <IconButton onClick={() => downloadFile()} color={'primary'} variant="contained" size="small">
                        <DownloadIcon/>
                    </IconButton>
                    <IconButton onClick={() => uploadFile()} color={'primary'} variant="contained" size="small">
                        <UploadFileIcon/>
                    </IconButton>
                    <IconButton onClick={() => uploadMainPhoto()} color={'primary'} variant="contained" size="small">
                        <PhotoIcon/>
                    </IconButton>
                </Grid>
                <Grid item md={9} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography sx={{mb: 3}} component="h1" variant="h5">
                        Configuración del servidor
                    </Typography>
                </Grid>
                <Grid item md={1} spacing={2}>
                    <SuccessButton
                        text={'GUARDAR'}
                        fullWidth
                        onClick={(e) => {
                            e.preventDefault()
                            updateConfiguration()
                        }}
                    />
                </Grid>
            </Grid>

            <Box sx={{height: '50vh'}} maxWidth>
                {records && form && <HorizontalTabList visible={true} showButton={false} expansible={false} key={'products'} sideBarTab={generateUIAndRecords()}/>}
            </Box>
        </Box>
    )
}
