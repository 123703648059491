import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import NewCultivationHarvestDialog from "./NewCultivationHarvestDialog";


const ui = {
    fields: {
        harvestid : {name: 'harvestid', label:'Codigo' , typeName: 'String', editable: true, flex: 0.5, sort: 'asc' },
        since : {name: 'since', label:'Desde' , typeName: 'Time', editable: true},
        until : {name: 'until', label:'Hasta' , typeName: 'Time', editable: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
        remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String',  multiline: true, rows: 3},
    }
}

export default function CultivationharvestView(props) {
    const {cultivationid} = props;
    const [records, setRecords] = React.useState([]);
    const [harvest, setCultivationharvest] = React.useState(null);
    const [openNewCultivationharvest, setOpenNewCultivationharvest] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newCultivationharvest = () => {
        setOpenNewCultivationharvest(true);
    }

    const onEdit = (harvestid) => {
        setCultivationharvest(harvestid);
        setOpenNewCultivationharvest(!openNewCultivationharvest)
    }

    const onDelete = (harvestid) =>{
        deleteObject(harvestid,{name:"harvest", keyField:'harvestid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.harvestid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPutRest('harvest', toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })
    }

    useEffect(() =>{
        if(!openNewCultivationharvest){
            const filters = {
                fields: {cultivationid : {name: 'cultivationid', operator: 'OPERATOR_EQUAL', value: cultivationid},},
                entity : { name: 'harvest'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                setCultivationharvest(null);
            });
        }
    }, [refreshObjects, openNewCultivationharvest, props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <Dialog open={openNewCultivationharvest}>
                    <NewCultivationHarvestDialog harvestid={harvest} cultivation={cultivationid} setOpen={setOpenNewCultivationharvest} open={openNewCultivationharvest}/>
                </Dialog>
                <Button size={'small'} variant="contained" onClick={newCultivationharvest} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir recolección
                </Button>
                <GridTable ui={ui} sx={{mt: 2}} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'harvestid'}
                           onChange={onChange} toolBar
                />
            </Box>
        </Box>
    );
}
