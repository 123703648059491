import {LineAxis} from "@mui/icons-material";
import * as React from "react";
import Dashboard from "./Dashboard/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import SummarizeIcon from '@mui/icons-material/Summarize';
import FactoryIcon from '@mui/icons-material/Factory';
import HandymanIcon from '@mui/icons-material/Handyman';
import StoreIcon from '@mui/icons-material/Store';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HorizontalTabList from "../../../View/Tab/HorizontalTabList";
import {subsideBarTab} from "./sidebarActions";
import AgricultureIcon from '@mui/icons-material/Agriculture';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import MainDashboard from "./Dashboard/mui/MainDashboard";

export const sidebar = [
    {
        label: "Dashboard",
        icon: <LineAxis />,
        component: <MainDashboard/>,//<Dashboard/>,
        value: "summary",
        key: "main_dashboard"
    },
    {
        label: "Mantenimiento",
        icon: <HandymanIcon/>,
        component: <HorizontalTabList key={'products'} sideBarTab={subsideBarTab.maintenance}/>,
        value: "products",
        key: "main_maintenance"
    },
    {
        label: "HR",
        icon: <PeopleAltIcon/>,
        component: <HorizontalTabList key={'hr'} sideBarTab={subsideBarTab.hr}/>,
        value: "hr",
        key: "main_human_resources"
    },
    {
        label: "Compras",
        icon: <StoreIcon />,
        component: <HorizontalTabList key={'purchase'} sideBarTab={subsideBarTab.purchase}/>,
        value: "purchase",
        key: "main_purchase"
    },
    {
        label: "Ventas",
        icon: <LoyaltyIcon />,
        component: <HorizontalTabList key={'sale'} sideBarTab={subsideBarTab.sale}/>,
        value: "sale",
        key: "main_sale"
    },
    {
        label: "Stock",
        icon: <FactoryIcon />,
        component: <HorizontalTabList key={'warehouse'} sideBarTab={subsideBarTab.warehouse}/>,
        value: "warehouse",
        key: "main_factory"
    },
    {
        label: "Producción",
        icon: <PrecisionManufacturingIcon />,
        component: <HorizontalTabList key={'production'} sideBarTab={subsideBarTab.production}/>,
        value: "production",
        key: "main_production"
    },
    {
        label: "Cuaderno de campo",
        icon: <AgricultureIcon />,
        component: <HorizontalTabList key={'fld'} sideBarTab={subsideBarTab.fld}/>,
        value: "fld",
        key: "main_field_book"
    },
    {
        label: "Informes",
        icon: <SummarizeIcon/>,
        component: <HorizontalTabList key={'reports'} sideBarTab={subsideBarTab.reports}/>,
        value: "reports",
        key: "main_reports"
    },
];