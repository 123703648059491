import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Collapse, Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {Checkbox} from "@mui/joy";

export default function AccountWebSidebar(props) {
    const {sidebar, padding, main, permissions, handleCheckClick} = props;

    useEffect(() => {}, [props])

    return (
        <List overflow={main ? 'auto' : null} sx={{ height: '100%', width: '100%', bgcolor: 'white' }} disablePadding component="nav">
            {sidebar && sidebar.map((entry, index) => <RowEntry handleCheckClick={handleCheckClick} permissions={permissions} main={main} id={entry.key + index} key={entry.key + index} entry={entry} padding={(padding || 0)+1}/>)}
        </List>
    );
}

const RowEntry = (props) => {
    const {entry, padding, permissions, handleCheckClick} = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
    }, [props])

    const handleClick = (entry) => {
        if (entry?.children?.length > 0) {
            setOpen(!open);
        }
    };

    return (
        <Box>
            <ListItemButton disabled={entry.disabled} selected={open} onClick={() => handleClick(entry)} >
                <ListItemIcon sx={{transition: '0.2s', ml: 1}}>
                    {entry?.icon}
                </ListItemIcon>
                <ListItemText sx={{ml: -3,}} primary={entry?.label} primaryTypographyProps={{color: 'black', fontWeight:  entry?.children && 'bold', variant: 'strong'}} />
                {entry?.children?.length > 0 ? (open ? <ExpandLess /> : <ExpandMore />) :
                    <Box>
                        <Checkbox key={entry.key} sx={{ml: 1, flex: 1, alignItems: 'right'}} onChange={(e) => handleCheckClick({target : {name: entry.key, value:e.target.checked}})} checked={permissions.includes(entry.key)}/>
                    </Box>
                    }
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{pl: padding}}>
                    <AccountWebSidebar handleCheckClick={handleCheckClick} permissions={permissions} sidebar={entry?.children}/>
                </List>
            </Collapse>
            {entry.divider && <Divider/>}
        </Box>
    )
}
