import * as React from 'react';
import {Card, CardContent} from '@mui/material';
import Orders from "../../Orders";
import {getOrdersResume} from "../../actions";
import {useEffect} from "react";

export const LastOrders = (props) => {
  const {sx} = props;
  const [orders, setOrders] = React.useState([]);



  useEffect(() => {
      getOrdersResume(result => {
          setOrders(result.lastsaleorders);
      });
  }, [])
  return (
    <Card sx={sx}>
      <CardContent>
        <Orders orders={orders}/>
      </CardContent>
    </Card>
  );
};

LastOrders.prototypes = {
};
