import {doDelete, doPost, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../../../Utils/Notification/notifications";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

export const updateSaleorderField = (record, callback) =>{
    doPut('rest/saleorder/update',record, response =>{
        if(response){
            callback && callback()
        }
    })
}

export const updateSaleorder = (saleorderid, callback) =>{
    doPut('rest/saleorder/update',saleorderid, response =>{
        if(response){
            success('Guardado correctamente', callback && callback(response))
            callback && callback(response.data)
        }
    })
}

export const updateSaleorderdetail = (saleorderdetailid, selected, callback) =>{
    doPost('rest/saleorderdetail/create',saleorderdetailid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deleteSaleorder = (saleorderid, callback) =>{
    doDelete('rest/saleorder/delete/' + saleorderid.saleorderid,null, response =>{
        callback && callback();
    })
}
export const deleteSaleorderdetail = (saleorderdetailid, callback) =>{
    doDelete('rest/saleorderdetail/delete/' + saleorderdetailid.saleorderdetailid,null, response =>{
        callback && callback();
    })
}

export const getDetails = (saleorderid, callback) =>{
    //console.log('getDetails saleorder')
    //console.log( saleorderid)
    doPost('saleorder/getDetails',saleorderid, result => callback && callback(result))
}

export const getTotalAmount = (saleorderdetailid) =>{
    const totalsaleorderdetailprice = saleorderdetailid.unitprice * saleorderdetailid.quantity;
    if(saleorderdetailid.discount){
        return Math.floor((((totalsaleorderdetailprice/100)*(100-saleorderdetailid.discount)) *100))/100;
    }
    return (totalsaleorderdetailprice * 100) /100;
}


export const getCustomerproductprice = (record, field, value, callback) =>{
    const fieldstoChange = ['quantity', 'productid']
    if(fieldstoChange.includes(field)){
        if(record.saleorderid.customerid && record.quantity && record.productid){
            const filters = {
                entity : {name: 'customerproduct'},
                fields: {
                    customerid : {name: 'customerid', operator: OPERATORS.OPERATOR_EQUAL, value: record.saleorderid.customerid},
                    productid : {name: 'productid', operator: OPERATORS.OPERATOR_EQUAL, value: record.productid},
                    fromquantity : {name: 'fromquantity', operator: OPERATORS.OPERATOR_LTE, value: record.quantity},
                    toquantity : {name: 'toquantity', operator: OPERATORS.OPERATOR_GTE, value: record.quantity},
                    since : {name: 'since', operator: OPERATORS.OPERATOR_LTE, value: record.saleorderid.date},
                    until : {name: 'until', operator: OPERATORS.OPERATOR_GTE, value: record.saleorderid.date},
                },
            }
            searchByCriteria(filters, result =>{
                if (result && result.length > 0) {
                    callback && callback({...record, unitprice: result[0].price, discount: result[0].discount || 0})
                } else {
                    callback && callback({...record, discount: record.productid.discount || 0, unitprice: record.productid.unitprice || 0})
                }
            });
        }
    }
    callback && callback(record);
}