import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {doGetDownloadFile, doGetPDF} from "../../../../../../Utils/Restclient/NetworkActions";
import DetailsSaleorderDialog from "./DetailsSaleorderDialog";
import * as React from "react";
import SaleorderSalenoteView from "./SaleorderSalenoteView";
import SaleorderSaleinvoiceView from "./SaleorderSaleinvoiceView";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {customerUI} from "../Customer/customerCrud";
import {saleorderstatusUI} from "./saleorderstatusUI";

const getUiTab = (saleorderid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Pedido de venta", component: <EditObjectDialog objectid={saleorderid} entityDefinition={saleorderUI}/> },
                {label : "Detalles", component: <DetailsSaleorderDialog selected={saleorderid}/> },
                {label : "Facturas", component: <SaleorderSaleinvoiceView saleorderid={saleorderid}/> },
                {label : "Albaránes", component: <SaleorderSalenoteView saleorderid={saleorderid}/> },
            ]}
    })
}

const viewPDF = (saleorderid, setPDF) =>{
    doGetPDF('saleorder/downloadSaleorder/' + saleorderid.saleorderid,null, result =>{
        setPDF(result);
    })
}

const sendMail = (saleorderid, setOpenMail, setMailRecords) =>{
    const to = saleorderid.email || saleorderid.customerid?.email;
    setMailRecords({to: to, subject: 'PEDIDO: '+ saleorderid.saleorderid + ' : ' + saleorderid.saleorder})
    setOpenMail(true);
}

const downloadPDF = (saleorderid) =>{
    doGetDownloadFile('saleorder/downloadSaleorder/' + saleorderid.saleorderid,  'Pedido_venta-n' + saleorderid.saleorderid + '.pdf')
}

const autoComplete = (record, field, value, callback) =>{
    const valuesToChange = ['townid', 'postalcode', 'stateid', 'countryid']
    if (field === 'customerid') {
        const newRecord = {...record};
        const customerid = record.customerid;
        valuesToChange.forEach(value => {
            newRecord[value] = customerid ? customerid[value] : null;
        })
        newRecord.firstaddress = customerid.address
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}

export const saleorderUI = {
    entity : {
        name: 'saleorder',
        keyField: 'saleorderid',
        label: 'Pedido de venta',
        colorRow: 'status',
        info: {typeName: 'es.rbm.model.jpa.Saleorder'}
    },
    uiTable: {
        fields:{
            saleorderid : {name: 'saleorderid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            saleorderstatusid : {name: 'saleorderstatusid', label: 'Estado',typeName:'es.rbm.model.jpa.Saleordestatus', align: 'center'},
            firstaddress : {name: 'firstaddress', label:'Dirección' , typeName: 'String'},
            townid : {name: 'townid', label:'Localidad' , typeName: 'es.rbm.model.jpa.Town', flex: 0.8, align: 'center'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center'},
        },
        actions:{
            /*viewPDF:{
                fun: (entity, setPDF) => viewPDF(entity, setPDF)
            },*/
            downloadPDF:{
                fun: (entity) => downloadPDF(entity),
            },
            sendEmail:{
                fun: (entity, setOpenMail, setMailRecords) => sendMail(entity, setOpenMail, setMailRecords)
            },
        },
        keyComponent: 'saleorderCrudManagement'
    },
    uiForm : {
        fields:{
            saleorderid : {name: 'saleorderid', label: 'Código' ,typeName: 'String', editable: false, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', addObject: customerUI},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            firstaddress : {name: 'firstaddress', label: 'Primera dirección' ,typeName: 'String', size : 12},
            townid : {name: 'townid', label: 'Población' ,typeName: 'es.rbm.model.jpa.Town'},
            postalcode : {name: 'postalcode', label: 'Código postal' ,typeName: 'Number'},
            stateid : {name: 'stateid', label: 'Provincia' ,typeName: 'es.rbm.model.jpa.State'},
            countryid : {name: 'countryid', label: 'País' ,typeName: 'es.rbm.model.jpa.Country'},
            saleorderstatusid : {name: 'saleorderstatusid', label: 'Estado' ,typeName: 'es.rbm.model.jpa.Saleorderstatus', addObject: saleorderstatusUI},
            date : {name: 'date', label: 'Fecha' ,typeName: 'Date'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center'},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        fields: {
            saleorderid : {name: 'saleorderid', label: 'Nº pedido' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            saleorderstatusid : {name: 'saleorderstatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Saleorderstatus', operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    uiTab: (saleorder) => getUiTab(saleorder)
}

