import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateDataperioddetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const defaultUI = {
    fields:{
        workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker'},
        taskid : {name: 'taskid', label: 'Tarea', typeName:'es.rbm.model.jpa.Task'},
        workspaceid : {name: 'workspaceid', label: 'Finca',typeName:'es.rbm.model.jpa.Workspace'},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product'},
        transport : {name: 'transport', label: 'Transporte (€)',typeName:'Number', optional: true},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', optional: true},
        hours : {name: 'hours', label: 'Horas',typeName:'Number', optional: true},
    }
}

export default function EditDataperioddetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [dataperioddetailid, setDataperioddetail] = React.useState(selected);
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Añadir trabajador/es');
    const [ui, setUI] = React.useState(defaultUI);

    const handledChange = (record, complete) => {
        setDataperioddetail({...record});
        setComplete(complete);
    }

    useEffect(() =>{
        if(dataperioddetailid && dataperioddetailid.dataperioddetailid){
            setTitle('Editar trabajador')
        } else {
            const {taskid, workspaceid, productid} = selected.dataperiodid;
            const newFields =  {...defaultUI.fields, workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', isMulti: true}}
            setUI({...defaultUI, fields: newFields})
            setDataperioddetail({...dataperioddetailid, taskid, workspaceid, productid})
        }
    }, [props])

    const onSuccessClick = (e) => {e.preventDefault();updateDataperioddetail(dataperioddetailid, () => setOpen(!open))};
    const onNegativeClick = (e) => {e.preventDefault();setOpen(!open)};
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Grid container>
                    <Grid item md={1}/>
                    <Grid item md={10}>
                        <Box  sx={{m: 2}}>
                            <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                            <SingleRecordForm ui={ui} records={dataperioddetailid} onChange={handledChange} disableAutoFocus />
                        </Box>
                        <Box>
                            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                        </Box>
                    </Grid>
                    <Grid item md={1}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>X</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
    )
}
