
const getUiTab = (vatid) =>{
    return ({uiTabs : {fields : []}})
}

export const vatUI = {
    entity : {
        name: 'vat',
        keyField: 'vatid',
        label: 'Impuesto'
    },
    uiTable: {
        fields:{
            vatid : {name: 'vatid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3 },
            vat : {name: 'vat', label:'Descripción' , typeName: 'String', editable: true, align: 'center' },
            value : {name: 'value', label:'Valor' , typeName: 'Number', editable: true },
        },
        keyComponent: 'vatCrudManagement'
    },
    uiForm : {
        fields:{
            vatid : {name: 'vatid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            vat : {name: 'vat', label:'Descripción' , typeName: 'String', editable: true },
            value : {name: 'value', label:'Valor' , typeName: 'Number', editable: true },
        }
    },
    uiTab: (vat) => getUiTab(vat)
}

