import * as React from 'react';
import {createTheme} from "@mui/material/styles";
import {createStyles} from "@mui/material";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";

const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                border: `1px solid ${theme.palette.divider}`,
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: 26,
                borderRadius: 2
            },
            value: {
                position: "absolute",
                lineHeight: "24px",
                width: "100%",
                display: "flex",
                justifyContent: "center"
            },
            bar: {
                height: "100%",
                "&.low": {
                    backgroundColor: "#f44336"
                },
                "&.medium": {
                    backgroundColor: "#efbb5aa3"
                },
                "&.high": {
                    backgroundColor: "#088208a3"
                },
                "&.warning": {
                    backgroundColor: "rgba(252,237,75,0.85)"
                },
            }
        }),
    { defaultTheme }
);

export const ProgressBar = React.memo(function ProgressBar(props) {
    const { value, bgcolor } = props;
    const valueInPercent = value;
    const classes = useStyles();

    return (
        <Box sx={{bgcolor: bgcolor || 'default'}} className={classes.root}>
            <div
                className={classes.value}
            >{`${valueInPercent.toLocaleString()} %`}</div>
            <div
                className={clsx(classes.bar, {
                    low: valueInPercent < 30,
                    medium: valueInPercent >= 30 && valueInPercent <= 70,
                    high: valueInPercent > 70 && valueInPercent <= 100,
                    warning: valueInPercent > 100
                })}
                style={{ maxWidth: `${valueInPercent}%` }}
            />
        </Box>
    );
});