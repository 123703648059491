import * as React from 'react';
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';

export default function PersistentHorizontalTabList(props) {
    const { defaultValue, sideBarTab, orientation, showButton, expansible, onCloseTab } = props;
    const [value, setValue] = React.useState('1');
    const [visible, setVisible] = React.useState(true);
    const [timeouts, setTimeouts] = React.useState(null);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue + '');
    };

    useEffect(() => {
        if (props.visible !== false) {
            setVisible(true);
        } else {
            setVisible(false);
        }
        refreshTimeout();
    }, [props.visible]);

    useEffect(() => {
        setValue(defaultValue + '');
    }, [defaultValue]);

    const refreshTimeout = () => {
        if (expansible !== false) {
            if (timeouts) {
                clearTimeout(timeouts);
            }
            const timeout = setTimeout(() => {
                setVisible(false);
            }, 60 * 1000);
            setTimeouts(timeout);
        }
    };

    return (
        <Box>
            <TabContext value={value + ""}>
                {visible ? (
                    <TabList
                        allowScrollButtonsMobile
                        style={{minHeight: '30px', height: '30px' }}
                        sx={{ }}
                        variant={'fullWidth'}
                        onChange={handleChange}
                        selectionFollowsFocus
                        orientation={orientation ? orientation : 'horizontal'}
                    >
                        {sideBarTab?.fields?.map((e, i) => (
                            <Tab
                                sx={{ fontWeight: 'bold', fontSize: 10.5, color: theme.palette.primary.main, minHeight: '32px', height: '32px',
                                    bgcolor: value ===  (i + 1 + '') ? '#f4f7fc' : '#ffffff' }}
                                iconPosition={'end'}
                                key={e.label + i}
                                disabled={e.disabled}
                                label={
                                    onCloseTab && value ===  (i + 1 + '') ? (
                                        <Box display="flex" alignItems="center" >
                                            { (e.preLabelTab || '') + (e.labelTab || e.label)}
                                            <IconButton size="small" onClick={() => onCloseTab(e)}>
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        (e.preLabelTab || '') + (e.labelTab || e.label)
                                    )
                                }
                                value={i + 1 + ''}
                            />
                        ))}
                    </TabList>
                ) : (
                    showButton !== false && (
                        <Grid container>
                            <Grid item md={1}>
                                <IconButton color={'warning'} onClick={() => { setVisible(true); refreshTimeout(); }} variant="contained" size="small">
                                    <ArrowDropDownIcon fontSize={'large'} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )
                )}
                {sideBarTab?.fields?.map((e, i) => (
                    <Box key={e.key} sx={{ display: value === (i + 1 + '') ? 'block' : 'none', paddingBottom: 0, paddingTop: 0 }}>
                        {e.children ? (
                            <PersistentHorizontalTabList showButton={false} expansible={true} sideBarTab={e.children} />
                        ) : (
                            <Box>
                                {e.component}
                            </Box>
                        )}
                    </Box>
                ))}
            </TabContext>
        </Box>
    );
}
