import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import NewProductContainerDialog from "./NewProductContainerDialog";


const ui = {
    fields: {
        movementunitid : {name: 'movementunitid', label:'Unidad' , typeName: 'es.rbm.model.jpa.Movementunit', editable: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}

export default function ProductcontainerView(props) {
    const {productid} = props;
    const [records, setRecords] = React.useState([]);
    const [container, setContainer] = React.useState(null);
    const [openNewContainer, setOpenNewContainer] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newContainer = () => {
        setOpenNewContainer(true);
    }

    const onEdit = (containerid) => {
        setContainer(containerid);
        setOpenNewContainer(!openNewContainer)
    }

    const onDelete = (containerid) =>{
        deleteObject(containerid,{name:"container", keyField:'containerid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.containerid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPutRest('container', toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })
    }

    useEffect(() =>{
        if(!openNewContainer){
            const filters = {
                fields: {productid : {name: 'productid', operator: 'OPERATOR_EQUAL', value: productid},},
                entity : { name: 'container'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                setContainer(null);
            });
        }
    }, [refreshObjects, openNewContainer, props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <Dialog fullWidth maxWidth={"md"} open={openNewContainer}>
                    <NewProductContainerDialog containerid={container} product={productid} setOpen={setOpenNewContainer} open={openNewContainer}/>
                </Dialog>
                <Button variant="contained" onClick={newContainer} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir detalle
                </Button>
                <GridTable ui={ui} sx={{mt: 2}} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'containerid'}
                           onChange={onChange} toolBar
                />
            </Box>
        </Box>
    );
}
