import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import AttributeProductDialog from "./AttributeProductDialog";
import ProductcompositionView from "./ProductcompositionView";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {producttypeUI} from "./producttypeCrud";
import {movementunitUI} from "../Movementunit/movementunitCrud";
import {vatUI} from "./vatCrud";
import ProductcontainerView from "./ProductcontainerView";
import ProviderproductView from "./ProviderproductView";

const getUiTab = (productid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Producto", component: <EditObjectDialog objectid={productid} entityDefinition={productUI}/> },
                {label : "Atributos", component: <AttributeProductDialog productid={productid}/> },
                {label : "Composición", component: <ProductcompositionView productid={productid}/> },
                {label : "Contenedores", component: <ProductcontainerView productid={productid}/> },
                {label : "Tarifas activas", component: <ProviderproductView productid={productid}/> },
            ]}
    })
}

export const productUI = {
    entity : {
        name: 'product',
        keyField: 'productid',
        label: 'Producto',
        colorRow: 'active',
        urlExcelImport: 'importer/importProductFromExcel',
        urlDownloadTemplate: 'importer/getProductTemplate',
        info: {typeName: 'es.rbm.model.jpa.Product'}
    },
    uiTable: {
        fields:{
            productid : {name: 'productid', label:'#' , typeName: 'Number', editable: false,flex:0.3},
            product : {name: 'product', label:'Producto' , typeName: 'String', flex: 1.5},
            //description : {name: 'description', label:'Descripción' , typeName: 'String', editable: false},
            producttypeid : {name: 'producttypeid', label:'Tipo', typeName: 'es.rbm.model.jpa.Producttype'},
            vatid : {name: 'vatid', label:'IVA', typeName: 'es.rbm.model.jpa.Vat'},
            unitprice : {name: 'unitprice', label:'Precio venta', typeName: 'Number', flex: 0.5},
            unitcost : {name: 'unitcost', label:'Precio compra', typeName: 'Number', flex: 0.5},
            movementunitid : {name: 'movementunitid', label:'Unidad de movimiento' , typeName: 'es.rbm.model.jpa.Movementunit', flex: 0.9},
            active : {name: 'active', label:'Activo' , typeName: 'Checkbox', flex: 0.5},
            discount : {name: 'discount', label:'Descuento' , typeName: 'Number'},
            externalcode : {name: 'externalcode', label:'Cod. externo' , typeName: 'String', flex: 0.5, align: 'center'},
        },
        keyComponent: 'productCrudManagement'
    },
    uiForm : {
        fields: {
            product : {name: 'product', label: 'Producto' ,typeName: 'String'},
            //description : {name: 'description', label: 'Descripción' ,typeName: 'String'},
            producttypeid : {name: 'producttypeid',label: 'Tipo de producto', typeName: 'es.rbm.model.jpa.Producttype', addObject: producttypeUI},
            vatid : {name: 'vatid', label: 'IVA' ,typeName: 'es.rbm.model.jpa.Vat', addObject: vatUI},
            unitprice : {name: 'unitprice', label: 'Precio venta' ,typeName: 'Number'},
            unitcost : {name: 'unitcost', label: 'Precio compra' ,typeName: 'Number'},
            movementunitid : {name: 'movementunitid', label: 'Unidad de movimiento' ,typeName: 'es.rbm.model.jpa.Movementunit', addObject: movementunitUI},
            discount : {name: 'discount', label: 'Descuento' ,typeName: 'Number', optional: true},
            externalcode : {name: 'externalcode', label: 'Codigo externo' ,typeName: 'String', optional: true},
            extrainformation : {name: 'extrainformation', label: 'Información extra' ,typeName: 'String', optional: true},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
        }
    },
    filters : {
        startBegin : true,
        fields: {
            product : {name: 'product', label: 'Producto' ,typeName: 'String', operator: 'OPERATOR_LIKE', optional: true},
            producttypeid : {name: 'producttypeid',label: 'Tipo de producto', typeName: 'es.rbm.model.jpa.Producttype', operator: "OPERATOR_EQUAL", optional: true},
            vatid : {name: 'vatid',label: 'IVA', typeName: 'es.rbm.model.jpa.Vat', operator: "OPERATOR_EQUAL", optional: true},
            movementunitid : {name: 'movementunitid',label: 'Unidad de movimiento', typeName: 'es.rbm.model.jpa.Movementunit', operator: "OPERATOR_EQUAL", optional: true},
            externalcode : {name: 'externalcode',label: 'Código externo', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            active : {name: 'active',label: 'Activo', typeName: 'Boolean', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
    uiTab: (product) => getUiTab(product)
}
