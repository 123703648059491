import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {getObject, updateObject} from "./actions";
import {useEffect} from "react";
import SingleRecordForm from "../SingleRecordForm";
import Grid from "@mui/material/Grid";
import SaveRoundedButton from "../../Button/SaveRoundedButton";

export default function EditObjectDialog(props) {
    const {entityDefinition} = props;
    const [objectid, setObjectid] = React.useState({});
    const [update, setUpdate] = React.useState(true);
    const [isComplete, setComplete] = React.useState(false);
    const [ui, setUI] = React.useState(null);


    const handledChange = (record, complete) => {
        setObjectid({...record});
        setComplete(complete);
    }

    const calculateUI = () => {
        let fields = entityDefinition.uiForm.fields;
        let excludeOnEdit = entityDefinition.uiForm.excludeOnEdit
        excludeOnEdit = excludeOnEdit ? excludeOnEdit : [];
        let keys = Object.keys(fields);
        let newUI = keys.reduce((l, c) =>  excludeOnEdit.includes(c) ? {...l} : {...l, [c] : fields[c]}, {});
        setUI({...entityDefinition.uiForm, fields: newUI});
    }

    useEffect(() => {
        const object = props.objectid;
        const keyField = entityDefinition?.entity?.keyField;
        if (!ui) {
            calculateUI();
        }

        if (object[keyField] !== objectid[keyField]) {
            getObject(object, entityDefinition.entity, (result) => {
                setObjectid(result)
                setUpdate(false);
            })
        }
    }, [props, update]);

    const updateObjectForm = () => {
        updateObject(objectid, entityDefinition.entity, data => {
            setUpdate(data);
            setComplete(false);
        })
    }

    return (
        <Box sx={{m: 5}}>
            <Grid container>
                <Grid item md={11}>
                    <Typography sx={{mt: 1}} component="h5" variant="h5" align={'center'}>
                        Editar {entityDefinition.entity.label.toLowerCase()}
                    </Typography>
                </Grid>
                <Grid item md={1}>
                    <Box component="form" noValidate align={'right'}>
                        <SaveRoundedButton onClick={(e) => {e.preventDefault();updateObjectForm()}} disabled={!isComplete}/>
                    </Box>
                </Grid>
            </Grid>
            <Box component="form" noValidate sx={{mt: 1, pt:1, height:'70vh'}} overflow={'auto'}>
                {ui ? <SingleRecordForm ui={ui} records={objectid} onChange={handledChange} disableAutoFocus /> : ''}
            </Box>
        </Box>
    );
}
