import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (warehouseid) => {
    return ({
        uiTabs : {fields : [
                {label : "Almacen", component: <EditObjectDialog objectid={warehouseid} entityDefinition={warehouseUI}/> },
            ]}
    })
}

export const warehouseUI = {
    entity : {
        name: 'warehouse',
        keyField: 'warehouseid',
        label: 'Almacen'
    },
    uiTable: {
        fields: {
            warehouseid : {name: 'warehouseid', label:'#' , typeName: 'String', editable: false, flex: 0.3, optional: true, align: 'center'},
            warehouse : {name: 'warehouse', label:'Almacén' , typeName: 'String', editable: true, align: 'center', flex: 1.5},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean'},
        },
        keyComponent: 'warehouseCrudManagement'
    },
    uiForm : {
        fields: {
            warehouseid : {name: 'warehouseid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            warehouse : {name: 'warehouse', label:'Almacén' , typeName: 'String', editable: true, autofocus: true},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', editable: true, optional: true},
        }
    },
    uiTab: (warehouse) => getUiTab(warehouse)
}

