import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#0e4675', // Color principal
            light: '#dae8f5', // Color claro
            dark: '#0d274e', // Color oscuro
            contrastText: '#fff', // Texto en contraste
        },
        secondary: {
            main: '#FFC107',
            light: '#FFECB3',
            dark: '#FFA000',
            contrastText: '#000'
        },
        info: {
            main: '#1a5567',
            light: '#FFECB3',
            dark: '#FFA000',
            contrastText: '#fff'
        },
        text: {
            primary: '#212121', // Texto principal
            secondary: '#757575', // Texto secundario
            disabled: '#BDBDBD' // Texto desactivado
        },
        background: {
            default: '#fff', // Color de fondo predeterminado
            paper: '#faf9f5', // Color de fondo del papel
            box: '#fff',
            tab: '#fff'
        }
    },
});

export default theme;