import {doPost} from "../../../../Utils/Restclient/NetworkActions";

export const getPlanification = (filters, callback) => {
    doPost("plannedproduction/getPlanification", filters, result => {
        if(result) {
            const records = result.reduce((l, c) => {
                const plannedproductionProductDTOS = c.plannedproductionProductDTOS;
                const reduce = plannedproductionProductDTOS.reduce((last, current) => {
                    const reduceWithProduct = current.plannedproductionDayDTOS.reduce( (l, c) => {
                        const day = c.day;
                        return {...l,['day' + day]: c, productid: current.productid}
                        }, {});

                    return [...last, reduceWithProduct]
                }, []);

                return [...l, {...c, details: [...reduce]}]
            }, []);
            callback && callback(records);
        }
    })
}

export const generatePlannedProduction = (record, callback) => {
    doPost('plannedproduction/generateplannedproduction', record, callback);
}

export const saveExecution = (record, callback) => {
    doPost('plannedproduction/generateExecution', record, callback);
}


export const getExecutionsFromSaleorderdetails = (saleorderdetails, callback) => {
    doPost("plannedproduction/getExecutionsFromSaleorderdetails", saleorderdetails, result => {
        if (result) {
            callback && callback(result);
        }
    })
}

export const getPlannedproductionFromSaleorderdetails = (saleorderdetails, callback) => {
    doPost("plannedproduction/getPlannedproductionFromSaleorderdetails", saleorderdetails, result => {
        if (result) {
            callback && callback(result);
        }
    })
}