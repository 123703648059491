import {doGet, doPost} from "../../../../../../../Utils/Restclient/NetworkActions";

export const getCultivationInformation = (cultivationid, callback) => {
    if(cultivationid){
        const url = 'agro/getCultivationInformation/' + cultivationid?.cultivationid;
        doGet(url, r => {
            if(r){
                callback && callback(r);
            }
        })
    } else {
        callback && callback()
    }
}

export const getCultivationsInformation = (callback) => {
    const url = 'agro/getCultivationsInformation';
    doGet(url, r => {
        if(r){
            callback && callback(r);
        }
    })
}

export const saveCultivationCoordinates = (coordinates, cultivation, callback) => {
    const url = 'agro/saveCultivationPolygon';
    doPost(url, {coordinates, cultivationid: cultivation}, () => {
        callback && callback()
    });
}