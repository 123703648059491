export const treatmentReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date',  optional: true},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date',  optional: true},
            cultivationid : {name: 'cultivationid', label: 'Cultivo' ,typeName: 'es.rbm.model.jpa.Cultivation', size: 12, optional: true, isMulti: true},
            pdf : {name: 'pdf', label: 'Formato PDF' ,typeName: 'Boolean',  optional: true},
        }
    },
    title: 'Informe Tratamientos',
    description: 'Informe con los tratamientos realizados',
    url: 'agro/getTreatmentReport',
    format: (filters) => (filters.pdf ? '.pdf' : '.xlsx'),
    documentName: 'Informe_tratamientos' + new Date(),
    keyForm: 'defaultTreatmentReport',
}

export const fertigationReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date', optional: true},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date', optional: true},
            cultivationid : {name: 'cultivationid', label: 'Cultivo' ,typeName: 'es.rbm.model.jpa.Cultivation', size: 12, optional: true, isMulti: true},
            pdf : {name: 'pdf', label: 'Formato PDF' ,typeName: 'Boolean',  optional: true},
        }
    },
    title: 'Informe Fertirrigaciones',
    description: 'Informe con los riegos realizados',
    url: 'agro/getFertigationReport',
    format: (filters) => (filters.pdf ? '.pdf' : '.xlsx'),
    documentName: 'Informe_fertirrigación' + new Date(),
    keyForm: 'defaultFertigationReport',
}