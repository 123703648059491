import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateInventorydetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const ui = {
    fields:{
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: true, flex:3},
        batchid : {name: 'batchid', label:'Lote' , typeName: 'es.rbm.model.jpa.Batch', editable: true, flex:3, optional: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}

export default function EditInventorydetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [inventorydetailid, setInventorydetail] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nuevo detalle');

    const handledChange = (record, complete) => {
        setInventorydetail({...record});
        setComplete(complete);
    }

    useEffect(() =>{
        if(inventorydetailid && inventorydetailid.inventorydetailid){
            setTitle('Editar detalle')
        }
    }, [props])

    const onSuccessClick = (e) => {
        e.preventDefault()
        updateInventorydetail(inventorydetailid, () => setOpen(!open))
    };

    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };

    return(
        <Box sx={{p: 5, m: 2}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={inventorydetailid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>X</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
