import React, {useState, useEffect, useRef} from "react";
import {MapContainer, Polygon, Popup, TileLayer} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import {getPlotInformation} from "./actions";
import Typography from "@mui/material/Typography";

const PlotMapView = (props) => {
  const [state, setState] = useState({
    zoom: 15,
    data: [],
  });
  const mapRef = useRef(null);

  useEffect(() => {
    const {coordinates, plotid} = props;
    if(coordinates.latitude && coordinates.longitude){
      getPlotInformation(plotid, r => {
        const currentLocation = {
          lat: r && r.length > 0 && r[0].coordinates.length > 0 ?  r[0].coordinates[0][0] : coordinates.latitude,
          lng: r && r.length > 0 && r[0].coordinates.length > 0 ?  r[0].coordinates[0][1] : coordinates.longitude,
        };
        panToNewLocation(currentLocation);
        setState({
          ...state,
          data: r,
          currentLocation,
        });
      })
    }
  }, [props.coordinates]);
  
  const panToNewLocation = (currentLocation) => {
    const newLocation = [currentLocation.lat, currentLocation.lng];
    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
    }
  };

  return (
      <Box>
        {state.currentLocation &&
            <MapContainer center={state.currentLocation} zoom={state.zoom} style={{ marginTop: 10, height: '77vh', width: '100%' }} ref={mapRef}>
              <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              {state.data.map(p => {
                return (<Polygon positions={p.coordinates} color="blue">
                <Popup>
                  <Typography> <strong>{p.plot}</strong></Typography>
                  <strong>{'PR: '}</strong> {p.state}
                  <strong>{', MU: '}</strong> {p.townid}
                  <strong>{', AG: '}</strong> {p.aggregate}
                  <strong>{', ZO: '}</strong> {p.zone}
                  <strong>{', PO: '}</strong> {p.polygon}
                  <strong>{', PA: '}</strong> {p.parcel}
                  <strong>{', RE: '}</strong> {p.enclosure}
                  <br/>
                  <strong>{'Área: '}</strong> {p.area}
                </Popup>
              </Polygon>) })}
            </MapContainer>}
      </Box>
  );
};

export default PlotMapView;
