import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {addSalenote, filterNotes, getSalenoteDetails} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {getEntityDescription} from "../../../../../../Utils/UtilsCommon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";

const ui = {
    fields:{
        salenoteid : {name: 'salenoteid', label:'Albarán' , typeName: 'es.rbm.model.jpa.Salenote', size: 12, filterRecords: filterNotes},
    }
}

export default function AddSalenoteDialog(props) {
    const {open, setOpen, saleinvoiceid} = props;
    const options = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const [isComplete, setComplete] = React.useState(false);
    const [record, setRecord] = React.useState({});
    const [details, setDetails] = React.useState([]);

    const handledChange = (record, complete) => {
        setRecord({...record});
        setComplete(complete);
        if (record.salenoteid) {
            getSalenoteDetails(record.salenoteid, result => setDetails(result));
        }
    }

    useEffect(() =>{
        setRecord({...record, saleinvoiceid: saleinvoiceid})
        }, [props])

    const onSuccessClick = (e) => {e.preventDefault(); addSalenote(saleinvoiceid, {...record.salenoteid}, () => setOpen(false))};
    const onNegativeClick = (e) => {e.preventDefault();setOpen(false)};
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Box  sx={{m: 2}}>
                        <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>Añadir albarán</Typography>
                        <SingleRecordForm ui={ui} records={record} onChange={handledChange} disableAutoFocus />
                        {record.salenoteid && <Grid container sx={{mt:2}}>
                            <Grid item md={12}>
                                <Typography component="h7" variant="h7" sx={{ display: 'flex'}}>
                                    <strong>{'Pedido'}</strong>
                                </Typography>
                                <Typography component="h7" variant="h7" sx={{ display: 'flex'}}>
                                    {getEntityDescription(record.salenoteid, 'es.rbm.model.jpa.Salenote')}
                                </Typography>
                                <Typography component="h7" variant="h8" sx={{ display: 'flex',  mt: 1}}>
                                    <strong>{'Cliente'}</strong>
                                </Typography>
                                <Typography component="h7" variant="h8" sx={{ display: 'flex'}}>
                                    {getEntityDescription(record.salenoteid.customerid, 'es.rbm.model.jpa.Customer')}
                                </Typography>
                                <Typography component="h7" variant="h7" sx={{ display: 'flex',  mt: 1}}>
                                    <strong>{'Fecha albarán'}</strong>
                                </Typography>
                                <Typography component="h7" variant="h7" sx={{ display: 'flex'}}>
                                    {new Date(record.salenoteid.date).toLocaleDateString('es-ES', options)}
                                </Typography>
                                {details &&
                                    <Grid item md={12}>
                                        <Typography component="h7" variant="h7" sx={{ display: 'flex', mt: 5, mb:1}}>
                                            <strong>{'Detalles'}</strong>
                                        </Typography>
                                        <Table stickyHeader size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center"><strong>Nº</strong></TableCell>
                                                    <TableCell align="center"><strong>Producto</strong></TableCell>
                                                    <TableCell align="center"><strong>Unidades</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {details.map((detail, i) =>
                                                    <TableRow key={i}>
                                                        <TableCell align="center">{i+1}</TableCell>
                                                        <TableCell align="center">{detail.productid.product}</TableCell>
                                                        <TableCell align="center">{detail.quantity}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>}
                            </Grid>
                        </Grid>
                        }
                    </Box>
                    <Box>
                        <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>X</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
