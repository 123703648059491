import PropTypes from 'prop-types';
import ArrowDownIcon from '@heroicons/react/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import {Avatar, Box, Card, CardContent, LinearProgress, Stack, SvgIcon, Typography, Icon} from '@mui/material';
import {getIcon} from "../Icons";

export const CustomCard = (props) => {
  const { sx, data} = props;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant="overline"
            >
              {data.title}
            </Typography>
            <Typography variant="h4" align={'center'}>
              {data.value}
            </Typography>
          </Stack>
          <Avatar sx={{backgroundColor: data.color, height: 56, width: 56}}>
              {getIcon(data.icon)}
          </Avatar>
        </Stack>
        {data.difference ? (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={0.5}
            >
              <SvgIcon
                color={data.positive ? 'success' : 'error'}
                fontSize="small"
              >
                {data.positive ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </SvgIcon>
              <Typography
                color={data.positive ? 'success.main' : 'error.main'}
                variant="body2"
              >
                {data.difference}%
              </Typography>
            </Stack>
            <Typography
              color="text.secondary"
              variant="caption"
            >
              {data.label}
            </Typography>
          </Stack>
        ) : ''}
        {data.progress ? (
            <Box sx={{ mt: 3 }}>
              <LinearProgress
                  value={data.progress}
                  variant="determinate"
              />
            </Box>
        ) : ''}
      </CardContent>
    </Card>
  );
};

CustomCard.prototypes = {
  sx: PropTypes.object,
  data: PropTypes.object,
};
