import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {
    deletePurchaseorderdetail,
    getDetails,
    updatePurchaseorderdetail
} from "./actions";
import Dialog from "@mui/material/Dialog";
import EditPurchaseorderdetailDialog from "./EditPurchaseorderdetailDialog";
import AddIcon from "@mui/icons-material/Add";
import GridTable from "../../../../../../View/Form/GridTable";
import {doGet, doGetDownloadFile, doPost, doPostDownloadFile} from "../../../../../../Utils/Restclient/NetworkActions";
import {Download} from "@mui/icons-material";
import MenuButton from "../../../../../../View/Button/MenuButton";
import {Stack} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import PropTypes from "prop-types";


const ui = {
    fields: {
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 1.5},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', flex: 0.7},
        price : {name: 'price', label:'Precio unitario' , typeName: 'Number', flex: 0.7},
        discount : {name: 'discount', label:'Descuento (%)' , typeName: 'Number', flex: 0.7},
        remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', flex: 1.5, align: 'center'},
        //total : {name: 'total', label:'Total' , typeName: 'Function', func: (v) => getTotalAmount(v) + ' €' },
    }
}

export default function DetailsPurchaseorderDialog(props) {
    const {selected} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [purchaseorderdetails, setPurchaseorderdetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    const [reportOptions, setReportOptions] = React.useState(null);

    useEffect(() =>{
        if(selected && selected.purchaseorderid){
            getDetails(selected, result => {
                setPurchaseorderdetails(result);
                getReportOptions();
            });
        }
    }, [openEditDetail, selected, refresh])


    const onDelete = (purchaseorderdetailid) => {
        deletePurchaseorderdetail(purchaseorderdetailid, () => setRefresh(!refresh))
    }

    const onEdit = (purchaseorderdetailid) => {
        setSelectedDetail(purchaseorderdetailid)
        setOpenEditdetail(!openEditDetail);
    }

    const onChange = (e) => {
        const record = purchaseorderdetails.filter(r => r.purchaseorderdetailid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            updatePurchaseorderdetail(record[0])
        } else{
            updatePurchaseorderdetail(e, () => setRefresh(!refresh));
        }
    }

    const downloadPDF = (purchaseorderid) =>{
        doGetDownloadFile('purchaseorder/downloadPurchaseorder/' + purchaseorderid.purchaseorderid, 'Pedido_compra_n' + purchaseorderid.purchaseorderid + '.pdf')
    }

    const onSelectReport = (op) => {
       doPostDownloadFile('purchaseorder/downloadPurchaseorderReport/' + selected.purchaseorderid, op, op.label + '_' + selected.purchaseorderid + '.pdf');
    }

    const getReportOptions = () => {
        doGet('purchaseorder/getPurchaseorderReportOptions/' + selected.purchaseorderid, result => {
            setReportOptions(result);
        });
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} >
            <Dialog maxWidth={"md"} open={openEditDetail}>
                <EditPurchaseorderdetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
            </Dialog>
            <Stack direction="row"
                   spacing={2}
                   sx={{mb: 2}}
                   justifyContent="center"
                   alignItems="center">
                <Button size={'small'} variant="contained" onClick={() => onEdit({purchaseorderid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.purchaseorderid}>
                    Añadir detalle
                </Button>
                <MenuButton
                    label={'IMPRIMIR'}
                    startIcon={<PrintIcon/>}
                    onClickOption={onSelectReport}
                    options={reportOptions || []}
                />
            </Stack>
            <Box>
                <GridTable ui={ui} onDelete={onDelete} onEdit={onEdit}
                           records={purchaseorderdetails} rowId={'purchaseorderdetailid'}
                           onChange={onChange} actionFlex={0.4}
                />
            </Box>
        </Box>
    )
}

DetailsPurchaseorderDialog.propTypes = {
    selected: PropTypes.object
}
