import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (tractorid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tractor", component: <EditObjectDialog objectid={tractorid} entityDefinition={tractorUI}/> },
            ]}
    })
}

export const tractorUI = {
    entity : {
        name: 'tractor',
        keyField: 'tractorid',
        label: 'Tractor',
        info: {typeName: 'es.rbm.model.jpa.Tractor'},
    },
    uiTable: {
        fields:{
            tractorid : {name: 'tractorid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, sort: 'asc' },
            tractor : {name: 'tractor', label:'Descripción' , typeName: 'String'},
            model : {name: 'model', label:'Modelo' , typeName: 'String'},
            licenseplate : {name: 'licenseplate', label:'Matrícula' , typeName: 'String'},
            roma : {name: 'roma', label:'ROMA' , typeName: 'String'},
        },
        keyComponent: 'tractorCrudManagement',
    },
    uiForm : {
        fields:{
            tractorid : {name: 'tractorid', label:'Codigo' , typeName: 'String', editable: false, optional: true, flex: 0.3, sort: 'asc' },
            tractor : {name: 'tractor', label:'Descripción' , typeName: 'String'},
            model : {name: 'model', label:'Modelo' , typeName: 'String'},
            licenseplate : {name: 'licenseplate', label:'Matrícula' , typeName: 'String'},
            roma : {name: 'roma', label:'ROMA' , typeName: 'String'},
        }
    },
    uiTab: (tractorid) => getUiTab(tractorid)
}