import * as React from 'react';
import { createTheme } from "@mui/material/styles";
import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import {useEffect} from "react";

const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                border: `1px solid ${theme?.palette?.divider}`,
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: 26,
                borderRadius: 2
            },
            value: {
                position: "absolute",
                lineHeight: "24px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                pointerEvents: "none"
            },
            segment: {
                position: "absolute",
                height: "100%",
            }
        }),
    { defaultTheme }
);

export const ProgressBarV2 = (props) => {
    const classes = useStyles();
    const [segments, setSegments] = React.useState([]);

    useEffect(() => {
       setSegments(props.segments || [])
    }, [props]);

    return (
        <Box className={classes.root}>
            {segments.map((segment, index) => (
                <div
                    key={index}
                    className={classes.segment}
                    style={{
                        backgroundColor: segment.color,
                        width: `${segment.value}%`,
                        zIndex: index
                    }}
                    title={segment.label}
                >
                    {segment.value >= 33 && segment.printPercent ? segment.value.toFixed(2) + ' %': ''}
                </div>
            ))}
        </Box>
    );
};
