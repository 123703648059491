import * as React from "react";
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import uiDefinition from "../../Utils/uiDefinition";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MailIcon from "@mui/icons-material/Mail";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {ProgressBar} from "../ProgressBar";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Stack from "@mui/material/Stack";
import DownloadIcon from '@mui/icons-material/Download';
import MenuPrintIconButton from "../Button/MenuPrintIconButton";
import {getEntityDescription} from "../../Utils/UtilsCommon";
import Tooltip from "@mui/material/Tooltip";


export const getGridTableUI = (props) => {
    const {onEdit, onDelete, downloadPDF,sendEmail, checkShipping, actionFlex, onPrint} = props;
    const fields = getFields(props);
    if (onEdit || onDelete || downloadPDF || sendEmail || checkShipping || actionFlex || onPrint) {
        const newFields = {columns: [...fields, {...getActions(props)}]};
        newFields.columns.forEach(c => {
            c.width = c.flex * 160;
        })
        return( props?.ui?.fields? newFields :  {columns: []})
    } else {
        const newFields = {columns: [...fields]};
        return( props?.ui?.fields? newFields :  {columns: []})
    }
}

export const defaultSendMail = (entity, emailField, setOpenMail, setMailRecords) =>{
    const to = entity[emailField];
    setMailRecords({to: to})
    setOpenMail(true);
}

const getActions = (props) => {
    const {onEdit, onDelete, downloadPDF, sendEmail, checkShipping, downloadData, actionFlex, onPrint, printOptions, pdfSet} = props;
    const actionsSize = actionFlex || 0.7;
    return {field: 'actions', type: 'actions', headerName: 'Acciones', flex: actionsSize, printable: false, headerAlign: 'center', align: 'center',  headerClassName: 'super-app-theme--header',
        renderCell: (e) =>
            <Box justifyContent={'center'} justifyItems={'center'} sx={{m: -2}}>
                <Stack direction="row"
                       spacing={0}
                       justifyContent="center"
                       alignItems="center">
                {onEdit &&
                    <IconButton color={'warning'} value={e.row} onClick={() => onEdit(e.row)} variant="contained" size="small">
                        <OpenInNewIcon/>
                    </IconButton>}
                {downloadPDF &&
                    <IconButton size={'small'} color={'error'} value={e.row} onClick={() => downloadPDF(e.row)} variant="contained">
                        <PictureAsPdfIcon/>
                    </IconButton>
                }
                {onPrint &&
                    <MenuPrintIconButton row={e.row} options={printOptions} onClickOption={(op) => onPrint.onSelectedOption(op, e.row, pdfSet)} />
                }
                {sendEmail &&
                    <IconButton size={'small'} color={'primary'} value={e.row} onClick={() => sendEmail(e.row)} variant="contained">
                        <MailIcon/>
                    </IconButton>
                }
                {checkShipping &&
                    <IconButton color={'warning'} value={e.row} onClick={() => checkShipping(e.row)} variant="contained" size="small">
                        <LocalShippingIcon/>
                    </IconButton>
                }
                {downloadData &&
                    <IconButton color={'info'} value={e.row} onClick={() => downloadData(e.row)} variant="contained" size="small">
                        <DownloadIcon/>
                    </IconButton>
                }
                {onDelete &&
                    <IconButton color={'error'} value={e.row} onClick={() => onDelete(e.row)} variant="contained" size="small">
                        <DeleteIcon/>
                    </IconButton>
                }
                </Stack>
            </Box>
    }
}

const getFields = (props) => {
    const {onChange, ui, handleSelectEntity} = props;
    const fields = ui.fields;

    const newFields = fields && Object.keys(fields).map(key => {
        const field = fields[key];
        if(field?.name){
            switch (field.typeName){
                case 'String' :
                    return {field: field.name, headerName: field.label, flex: field.flex? field.flex : 1,
                        headerAlign: field.headerAlign? field.headerAlign : 'center', align: 'center', editable: field.editable===false? field.editable : onChange,
                        hide: field.visible === false ? !field.visible : false,  headerClassName: 'super-app-theme--header',
                        preProcessEditCellProps: (params) => {
                            const hasError = !params.props.value.length && !field.optional;
                            return { ...params.props, error: hasError };
                        },}
                case 'Number' :
                    return {field: field.name, type: 'number',headerName: field.label, flex: field.flex? field.flex : 1,
                        editable: field.editable === false ? field.editable : onChange,  align: field.align? field.align : 'center',
                        hide: field.visible === false ? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center',  headerClassName: 'super-app-theme--header',
                        preProcessEditCellProps: (params) => {
                            let value = params.props.value;
                            const hasError = !value && value !== 0  && !field.optional;
                            return { ...params.props, error: hasError };
                        },}
                case 'Function' :
                    return (
                        {field: field.name, headerName: field.label, align: field.align? field.align : 'center', flex: field.flex? field.flex : 1, sortable: false, filterable: false,
                            hide: field.visible === false? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center', headerClassName: 'super-app-theme--header',
                            renderCell: (v, row, column, apiRef) => field.func(v)}
                    )
                case 'Hour' :
                    return (
                        { field: field.name, headerName: field.label, align: field.align? field.align : 'center', flex: field.flex? field.flex : 1, sortable: false, filterable: false,
                            hide: field.visible===false? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center', headerClassName: 'super-app-theme--header',
                            renderCell: field.renderCell ? field.renderCell : (v, row, column, apiRef) => {
                                        if (v.value) {
                                            const inCell = new Date(field.hourField ? v.value[field.hourField] : v.value).toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'}).toString();
                                            const inTooltip = new Date(field.hourField ? v.value[field.hourField] : v.value).toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit'}).toString();
                                            return <Tooltip title={inTooltip}>
                                                {inCell}
                                                    </Tooltip>
                                        }
                                        return '';
                            }
                        }
                    )
                case 'Select' :
                    return (
                        {field: field.name, type: 'singleSelect', valueOptions: field.valueOptions ? field.valueOptions : field.className? [] : [],
                            headerName: field.label, flex: field.flex? field.flex : 1, editable: field.editable===false? field.editable : onChange,  align: field.align? field.align : 'center',
                            hide: field.visible===false ? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center',
                            headerClassName: 'super-app-theme--header'}
                    )
                case 'Date' :
                    return (
                        {field: field.name, headerName: field.label || 'Fecha', editable: field.editable, flex: field.flex ? field.flex : 1,
                            align: field.align || 'center', headerAlign: field.headerAlign || 'center',
                            hide: field.visible === false ? !field.visible : false, headerClassName: 'super-app-theme--header',
                            type: 'date',
                            valueGetter: (value, row, column, apiRef) => {
                                return value && new Date(value)
                            },
                        })
                case 'Time' :
                    return (
                        {field: field.name, headerName: field.label || 'Fecha', editable: field.editable, type:'dateTime', flex: field.flex || 1,
                            align: field.align ? field.align : 'center',headerAlign: field.headerAlign ? field.headerAlign : 'center',
                            hide: field.visible === false ? !field.visible : false, headerClassName: 'super-app-theme--header',
                            valueGetter: (value, row, column, apiRef) => value && new Date(value),
                        })
                case 'Progress' :
                    return (
                        {
                            field: field.name,
                            headerName: field.label || '', headerAlign: field.headerAlign? field.headerAlign : 'center',
                            editable: false,
                            flex: field.flex ? field.flex : 1,
                            align: field.align? field.align : 'center',
                            hide: field.visible===false? !field.visible : false, headerClassName: 'super-app-theme--header',
                            renderCell: (v, row, column, apiRef) =>
                                    <Box sx={{width: '100vh'}}>
                                        <ProgressBar value={ v.row[field.name] || 0}/>
                                    </Box>
                        })
                case 'Checkbox' :
                case 'Boolean' :
                    return (
                        {field: field.name, headerName: field.label || '', editable: true ,flex: field.flex ? field.flex : 1, type: 'boolean',
                            hide: field.visible===false? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center',
                            headerClassName: 'super-app-theme--header'})
                default:
                     if (uiDefinition[field.typeName]) {
                         return (
                             {  field: field.name, headerName: field.label, flex: field.flex? field.flex : 1,
                                 align: field.align ? field.align : 'center', headerAlign: field.headerAlign? field.headerAlign : 'center',
                                 hide: field.visible === false ? !field.visible : false, headerClassName: 'super-app-theme--header',
                                 valueGetter: (value, row, column, apiRef) => getEntityDescription(value, field.typeName),
                                 renderCell: (value, row, column, apiRef) => <Box display="flex"
                                                         justifyContent={field.align}
                                                         alignItems="center" sx={{height: '100%', width: '100%'}}
                                                         onDoubleClick={() => field.editable !== false && onChange && handleSelectEntity(value, field)}> { getEntityDescription(value?.row[value?.field], field.typeName)} </Box>
                             }
                         )
                     }
            }
        }
    });
    return (fields? newFields : [])
}
