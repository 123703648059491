import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (movementunitid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Unidad", component: <EditObjectDialog objectid={movementunitid} entityDefinition={movementunitUI}/> },
            ]}
    })
}

export const movementunitUI = {
    entity : {
        name: 'movementunit',
        keyField: 'movementunitid',
        label: 'Unidades'
    },
    uiTable: {
        fields:{
            movementunitid : {name: 'movementunitid', label:'#' , typeName: 'Number', editable: false, flex: 0.3, optional: true },
            movementunit : {name: 'movementunit', label:'Unidad de movimiento' , typeName: 'String', editable: true, align: 'center'},
        },
        keyComponent: 'movementunitCrudManagement'
    },
    uiForm : {
        fields:{
            movementunitid : {name: 'movementunitid', label:'Codigo' , typeName: 'String', editable: false, size: 2, optional: true },
            movementunit : {name: 'movementunit', label:'Unidad de movimiento' , typeName: 'String', editable: true, size:10}
        }
    },
    uiTab: (movementunit) => getUiTab(movementunit)
}

