import React from "react";
import { Popup } from "react-leaflet";

const MarkerPopup = (props) => {
    const { workerid, timetick, timeticktype } = props.data;
    const ticksTypes = { 1 : 'ENTRADA', 2: 'SALIDA'}
    return (
        <Popup>
            <div><strong>{workerid.name + ' ' + workerid.surname}</strong></div>
            <div>{'Hora: ' + new Date(timetick).toLocaleTimeString('es-ES', {hour: 'numeric', minute: 'numeric', second: 'numeric'})}</div>
            <div>{timeticktype ? ticksTypes[timeticktype] : 'SIN TIPO'}</div>
        </Popup>
      );
};

export default MarkerPopup;
