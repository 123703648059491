import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateRecipedetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {converterRecord} from "../../../../../../View/Form/SingleSelectForm";

const defaultUI = {
    fields:{
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', urlRecords: 'agro/getAllsFertigationproduct'},
        dose : {name: 'dose', label:'Dosis por Ha' , typeName: 'Number'},
    }
}

const extrainformation = [
    {field: 'activeingredient', description: 'Ingrediente activo'},
    {field: 'reasonuseid', description: 'Razón de uso', type: 'es.rbm.model.jpa.Reasonuse'},
    {field: 'dose', description: 'Dosis Ha'},
    {field: 'measureunitid', description: 'Unidad de medida', type: 'es.rbm.model.jpa.Movementunit'},
    {field: 'watervolume', description: 'Volumen de agua'},
    {field: 'securityperiod', description: 'Plazo de seguridad'},
    {field: 'nitrogen', description: '% Nitrogeno (N)'},
    {field: 'phosphorus', description: '% Fósforo (P)'},
    {field: 'potassium', description: '% Potasio (K)'},
    {field: 'calcium', description: '% Calcio (Ca)'},
    {field: 'magnesium', description: '% Magnesio (Mg)'},
    {field: 'sulfur', description: '% Azufre (S)'},
    {field: 'iron', description: '% Hierro (Fe)'},
    {field: 'boron', description: '% Boro (Fe)'},
    {field: 'molybdenum', description: '% Molibdeno  (Mo)'},
    {field: 'zinc', description: '% Zinc (Zn)'},
    {field: 'cobre', description: '% Cobre (Cu)'},
    {field: 'manganeso', description: '% Manganeso (Mn)'},
]

export default function EditRecipedetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [recipedetailid, setRecipedetail] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [ui, setUI] = React.useState(defaultUI);
    const [title, setTitle] = React.useState('Añadir producto a receta');

    const handledChange = (record, complete) => {
        if((record?.productid?.dose && !record.dose) || (record?.productid?.productid !== recipedetailid.productid?.productid)){
            record = {...record, dose: record.productid.dose}
            complete = true;
        }
        setRecipedetail({...record});
        setComplete(complete);
    }

    const generateUI = (selected) => {
        switch(selected.recipeid.recipetype){
            case 'TRATAMIENTO' :
                defaultUI.fields.productid = {name: 'productid', label:'Cod' , typeName: 'es.rbm.model.jpa.Product', urlRecords: 'agro/getAllsTreatmentproduct'}
                setUI(defaultUI);
                break
            case 'FERTIRRIGACIÓN' :
                defaultUI.fields.productid = {name: 'productid', label:'Cod' , typeName: 'es.rbm.model.jpa.Product', urlRecords: 'agro/getAllsFertigationproduct'}
                setUI(defaultUI);
                break
            default:
                setUI(defaultUI)
        }
    }
    useEffect(() =>{
        if(recipedetailid && recipedetailid.recipedetailid){
            setTitle('Editar detalle')
        }
        generateUI(selected);
    }, [props])

    const onSuccessClick = (e) => {e.preventDefault();updateRecipedetail(recipedetailid, () => setOpen(!open))};
    const onNegativeClick = (e) => {e.preventDefault();setOpen(!open)};
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Grid container>
                    <Grid item md={1}/>
                    <Grid item md={10}>
                        <Box  sx={{m: 2, minHeight: '25vh', maxHeight: '25vh'}}>
                            <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                            {ui && <SingleRecordForm ui={ui} records={recipedetailid} onChange={handledChange} disableAutoFocus />}
                            {recipedetailid?.productid &&
                                <Grid container sx={{mt:2}}>{
                                    extrainformation.map(i => {
                                        if(recipedetailid.productid[i.field]){
                                            const productidElement = i.type ? converterRecord(recipedetailid.productid[i.field], {typeName: i.type}).label : recipedetailid.productid[i.field];
                                            return <Grid item md={6}>
                                                <Typography component="h7" variant="h8" sx={{ display: 'flex'}}>
                                                    {i.description + ': ' + productidElement + '\n'}
                                                </Typography>
                                            </Grid>
                                        }
                                    })}
                                </Grid>
                            }
                        </Box>
                        <Box>
                            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                        </Box>
                    </Grid>
                    <Grid item md={1}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>X</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
    )
}
