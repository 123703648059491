import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import AluDataperioddetailsDialog from "./AluDataperioddetailsDialog";
import DataperiodDocumentView from "../../../Maintenance/Crud/Dataperiod/DataperiodDocumentView";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doGet, doPostDownloadFile, doPostPDF} from "../../../../../../Utils/Restclient/NetworkActions";
import DataperioexecutionDialog from "../../../Agro/Crud/Dataperiod/DataperioexecutionDialog";
import DataperioconsumptionDialog from "../../../Agro/Crud/Dataperiod/DataperioconsumptionDialog";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";

const getUiTab = (dataperiodid) => {
    return ({
        uiTabs : {fields : [
                {label : "Parte de trabajo", component: <EditObjectDialog objectid={dataperiodid} entityDefinition={aludataperiodUI}/> },
                {label : "Trabajadores", component: <AluDataperioddetailsDialog selected={dataperiodid}/> },
                {label : "Consumos", component: <DataperioconsumptionDialog selected={dataperiodid}/>},
                {label : "Elaboraciones", component: <DataperioexecutionDialog selected={dataperiodid}/>,},
                {label : "Documentos", component: <DataperiodDocumentView dataperiodid={dataperiodid}/> },
            ]}
    })
}


export const getNextField = (record, callback) =>{
    const fieldstoChange = ['customerid', 'saleorderid']
    const className = ['customer','alusaleorder']
    const isReference = []
    const fields = Object.keys(record).filter(r => record[r] && fieldstoChange.includes(r));
    if (fields.length){
        const field = fieldstoChange[fields.length-1];
        if (fieldstoChange.includes(field)){
            const index = fieldstoChange.indexOf(field);
            if (index !== fieldstoChange.length-1){
                const classEntity = className[index+1];
                let filters = {
                    entity : {name: classEntity},
                    fields: {
                        [field] : {name: field, operator: OPERATORS.OPERATOR_EQUAL, value: record[field]},
                    }
                }
                if (classEntity === 'alusaleorder') {
                    filters.fields = {
                        ...filters.fields,
                        deliverydate: {name: 'deliverydate', operator: OPERATORS.OPERATOR_GT, value: new Date().getTime()},
                    }
                }
                const nextField = fieldstoChange[index+1];
                if(nextField){
                    searchByCriteria(filters, result =>{
                        if (result && result.length > 0) {
                            let toReturn = [...result];
                            if(isReference.includes(nextField)){
                                toReturn = result.map(r => r[nextField]);
                            }
                            callback && callback(toReturn)
                        } else {
                            callback && callback([])
                        }
                    });
                }

            }
        }
    }
    callback && callback([]);
}

const getReportOptions = (setOption) => {
    doGet('almdataperiod/getHouseHistoryReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    let url = 'almdataperiod/downloadDataperiodReport/' + selected.dataperiodid;
    let fileName = op.label + '_' + selected.dataperiodid + '.pdf';
    showOrDownloadDocument(url, op, fileName, pdfSet)
}

const filterSaleorder = {
    entity : {name: 'alusaleorder'},
    fields: {
        deliverydate : {name: 'deliverydate', operator: OPERATORS.OPERATOR_LTE, value: new Date()},
    },
}

export const aludataperiodUI= {
    entity : {
        name: 'aludataperiod',
        keyField: 'dataperiodid',
        label: 'Parte de trabajo',
        info: {typeName: 'es.rbm.model.jpa.Aludataperiod'},
    },
    uiTable: {
        fields:{
            dataperiodid : {name: 'dataperiodid', label:'Nº' , typeName: 'Number', editable: false,flex:0.3, align: 'center', optional: true},
            dataperiod : {name: 'dataperiod', label: 'Descripción',typeName:'String', optional: true},
            since : {name: 'since', label: 'Fecha',typeName:'Date', optional: false},
            responsibleid : {name: 'responsibleid', label: 'Responsable',typeName:'es.rbm.model.jpa.Worker', optional: false, urlRecords: 'worker/getResponsibles', editable: true},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', optional: false},
            constructionid : {name: 'constructionid', label: 'Obra',typeName:'es.rbm.model.jpa.Construction', optional: false, editable: false},
            saleorderid : {name: 'saleorderid', label: 'Presupuesto',typeName:'es.rbm.model.jpa.Alusaleorder', optional: false, editable: false},
            houseid : {name: 'houseid', label: 'Vivienda',typeName:'es.rbm.model.jpa.House', optional: false, editable: false},
            externalcode : {name: 'externalcode', label: 'Codigo externo',typeName:'String', optional: true, hide: true},
            remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', optional: true, align: 'center'},
        },
        keyComponent: 'AludataperiodCrudManagement',
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            }
        },
    },
    uiForm : {
        fields:{
            dataperiodid : {name: 'dataperiodid', label: 'Código' ,typeName: 'String', editable: false, optional: true},
            dataperiod : {name: 'dataperiod', label: 'Descripción' ,typeName: 'String', optional: true},
            since : {name: 'since', label: 'Desde',typeName:'Datetime', optional: false},
            until : {name: 'until', label: 'Hasta',typeName:'Datetime', optional: false},
            responsibleid : {name: 'responsibleid', label: 'Responsable',typeName:'es.rbm.model.jpa.Worker', optional: false, urlRecords: 'worker/getResponsibles'},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', optional: false},
            constructionid : {name: 'constructionid', label: 'Obra',typeName:'es.rbm.model.jpa.Construction', optional: false},
            saleorderid : {name: 'saleorderid', label: 'Presupuesto',typeName:'es.rbm.model.jpa.Saleorder', optional: false, filterRecords: getNextField},
            houseid : {name: 'houseid', label: 'Vivienda',typeName:'es.rbm.model.jpa.House', optional: false, editable: false},
            externalcode : {name: 'externalcode', label: 'Codigo externo',typeName:'String', optional: true},
            remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', optional: true,  multiline: true, rows: 8, size: 12},
        },
    },
    filters : {
        startBegin : true,
        openEdit : true,
        initialsValues: {
            since_1: new Date(new Date().setDate(new Date().getDate() - 7))
        },
        fields: {
            since : {name: 'since',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            ['responsibleid-workerid'] : {name: 'responsibleid-workerid',label: 'Responsable', typeName: 'es.rbm.model.jpa.Worker', operator: OPERATORS.OPERATOR_EQUAL, empty: true, optional: true},
            since_1 : {name: 'since_1',label: 'Desde', typeName: 'Date', operator: OPERATORS.OPERATOR_GTE, empty: true, optional: true},
            since_2 : {name: 'since_2',label: 'Hasta', typeName: 'Date', operator: OPERATORS.OPERATOR_LTE, empty: true, endDay: true, optional: true},
            customerid : {name: 'customerid',label: 'Cliente', typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, empty: true, optional: true},
            constructionid : {name: 'constructionid',label: 'Obra', typeName: 'es.rbm.model.jpa.Construction', operator: OPERATORS.OPERATOR_EQUAL, empty: true, optional: true},
            saleorderid : {name: 'saleorderid',label: 'Presupuesto', typeName: 'es.rbm.model.jpa.Saleorder', operator: OPERATORS.OPERATOR_EQUAL, empty: true, optional: true},
            houseid : {name: 'houseid',label: 'Vivienda', typeName: 'es.rbm.model.jpa.House', operator: OPERATORS.OPERATOR_EQUAL, empty: true, optional: true},
            externalcode : {name: 'externalcode',label: 'Código externo', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, empty: true, optional: true},

        }
    },
    uiTab: (dataperiod) => getUiTab(dataperiod)
}




