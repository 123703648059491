import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";


const ui = {
    fields:{
        since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
        until : {name: 'until', label: 'Hasta' ,typeName: 'Date', optional: true, empty: true},
        absencetypeid : {name: 'absencetypeid', label: 'Tipo de ausencia' ,typeName: 'es.rbm.model.jpa.Absencetype'},
    },
    keyComponent: 'NewWorkerAbsenceDialog'
}
export default function NewWorkerAbsenceDialog(props) {
    const {open, setOpen, worker} = props;
    const [title, setTitle] = React.useState('Nuevo tipo');
    const [absenceid, setAbsenceid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setAbsenceid({...record});
        setComplete(complete);
    }

    const addAbsence = (absenceid, callback) => {
        const toSend = {...absenceid, workerid: worker}
        doPost('rest/absence/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const absenceid1 = props.absenceid;
        setAbsenceid(absenceid1 ? {...absenceid1} : {});
        setTitle(absenceid1 ? 'Editar ausencia' : 'Nueva ausencia');
    }, [props])

    const onSucessClick = (e) => {
        e.preventDefault()
        addAbsence(absenceid, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={absenceid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSucessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
