import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {tasktypeUI} from "./tasktypeCrud";

const getUiTab = (taskid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tarea", component: <EditObjectDialog objectid={taskid} entityDefinition={taskUI}/> },
            ]}
    })
}

export const taskUI = {
    entity : {
        name: 'task',
        keyField: 'taskid',
        label: 'Tarea'
    },
    uiTable: {
        fields:{
            taskid : {name: 'taskid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            task : {name: 'task', label:'Tarea' , typeName: 'String', editable: true, align: 'center'},
            tasktypeid : {name: 'tasktypeid', label:'Tipo' , typeName: 'es.rbm.model.jpa.Tasktype', editable: true, flex: 0.3 },
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
        },
        keyComponent: 'taskCrudManagement'
    },
    uiForm : {
        fields:{
            taskid : {name: 'taskid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
            task : {name: 'task', label:'Tarea' , typeName: 'String' },
            tasktypeid : {name: 'tasktypeid', label:'Tipo' , typeName: 'es.rbm.model.jpa.Tasktype', editable: true, flex: 0.3, addObject: tasktypeUI },
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
        }
    },
    uiTab: (task) => getUiTab(task)
}

