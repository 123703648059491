import React, {useState, useEffect, useRef} from "react";
import {MapContainer, Polygon, Popup, TileLayer, useMapEvents} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CancelIcon from '@mui/icons-material/Cancel';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PushPinIcon from '@mui/icons-material/PushPin';
import SaveIcon from '@mui/icons-material/Save';
import Stack from "@mui/material/Stack";
import {getConstructionInformation, saveConstructionCoordinates} from "./actions";
import Grid from "@mui/material/Grid";
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";


const ConstructionMapView = (props) => {
  const [state, setState] = useState({
    zoom: 17,
    data: {},
    marking : false,
    cursor : 'pointer',
    constructionInfo: [],
    construction : null,
    view: '1',
    markButton: false,
    filter: {parcel: true, construction: true}
  });
  const mapRef = useRef(null);
  const panToNewLocation = (currentLocation) => {
    const newLocation = [currentLocation.lat, currentLocation.lng];
    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
    }
  };

  useEffect(() => {
    refresh()
  }, [props.coordinates, state.marking]);

  const refresh = () => {
    const {constructionid} = props;
      getConstructionInformation(constructionid, r => {
        refreshView(r)
      })
  }

  const refreshView = (r) => {
    const {coordinates, constructionid, showButton, markButton} = props;
    if (r) {
      const currentLocation = {
        lat: r && r.length > 0 && r[0].coordinates.length > 0 ?  r[0].coordinates[0][0] : coordinates.latitude,
        lng: r && r.length > 0 && r[0].coordinates.length > 0 ?  r[0].coordinates[0][1] : coordinates.longitude,
      };
      panToNewLocation(currentLocation);
      setState({
        ...state,
        data: {},
        constructionInfo: r,
        records: sortRecords(r),
        marking: state.marking,
        currentLocation,
        showButtons : showButton,
        markButton : markButton,
        construction: constructionid
      });
    }
  }

  const ClickController = () => {
    const map = useMapEvents({
      click: (e) => {
        if(state.marking){
          const { lat, lng } = e.latlng;
          const point = [lat, lng];
          const newPoints = state.data.coordinates ? [...state.data.coordinates] : [];
          newPoints.push(point);
          setState({...state, data:{...state.data, coordinates: [...newPoints]}});
        }
      }
    });
    return null;
  }
  const clearPoints = () => {
    setState({...state, data:{...state.data, coordinates:[] }})
  }

  const markPoint = () => {
    setState({...state, marking: true})
  }

  const saveConstructionPoints = () => {
    saveConstructionCoordinates(state.data.coordinates, state.construction, () => {
      setState({...state, marking: false})
    })
  }

  const cancelMarking = () => {
    setState({...state, marking: false, data:{...state.data, coordinates:[]}})
  }

  const handleChangeView = (e) => {
    setState({...state, view: e.target.id})
  }

  const filterRecords = (records) => {
    if(records){
      let newRecords = state.constructionInfo;
      if (!records.parcel) {
        newRecords = newRecords.filter(r => r.product);
      }
      if (!records.construction) {
        newRecords = newRecords.filter(r => !r.product);
      }
      setState({...state, records: sortRecords(newRecords), filter: {...records}})
    }
  }

  const sortRecords = (records) => {
    return records.sort((a, b) => (!a.product && b.product) ? 1 : ((a.product && !b.product) ? -1 : 0))
  }

  const handledFormChange = (record) => {
    filterRecords(record)
  }

  return (
      <Box>
        {state.currentLocation &&
            <Paper sx={{ml: 15, mt: 5}}>
              <Grid container>
                <Grid item md={2}/>
                <Grid item md={8}>
                  {state.markButton ?
                    <Stack
                        sx={{ pt: 2 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                      {state.marking && <CleaningServicesIcon onClick={clearPoints} size="large" />}
                      {state.marking && <SaveIcon onClick={saveConstructionPoints} size="large" />}
                      {state.marking && <CancelIcon onClick={cancelMarking} size="large" />}
                      {!state.marking && <PushPinIcon onClick={markPoint} size="large" />}
                  </Stack> : <Box sx={{mt:2}}><strong>{props.title || ''}</strong></Box>}
                </Grid>
                <Grid item md={2}  sx={{ pt: 1 }}>
                  <ButtonGroup
                      disableElevation
                      variant="contained"
                  >
                    <Button size={'small'} id={'1'} onClick={handleChangeView}>Relieve</Button>
                    <Button size={'small'} id={'2'} onClick={handleChangeView}>Satélite</Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <MapContainer center={state.currentLocation} zoom={state.zoom} ref={mapRef} whenCreated={(map) => (mapRef.current = map)}
                            style={{ marginTop: 10, height: '73vh', width: '100%', cursor: state.cursor }}>
                { state.view === '1' ?
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    /> :
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        attribution="Tiles &copy; Esri - Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                    /> }
                <ClickController/>
                {state.data && state.marking && <Polygon positions={state.data.coordinates || []} color="red"/>}
                {!state.marking && state.constructionInfo &&
                    state.records.reverse().map( (p, i) => {
                      //const color = p.product ? "yellow" : 'blue';
                      return <Polygon key={/*color + */i} positions={p.coordinates || []} /*color={color}*/>
                            <Popup>
                              {/*p.coordinates &&
                                  <div>
                                    <strong>{p.product ? 'Cultivo: ' : 'Parcela: '}</strong> {p.construction} <br/>
                                    {p.product && <strong>{'Producto cultivado: ' + p.product}</strong>}
                                  </div>*/
                              }
                            </Popup>
                          </Polygon>;
                        }
                    )
                    }
              </MapContainer>
            </Paper>
            }
      </Box>
  );
};

export default ConstructionMapView;
