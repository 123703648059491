import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (producttypeid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tipo", component: <EditObjectDialog objectid={producttypeid} entityDefinition={producttypeUI}/> },
            ]}
    })
}

export const producttypeUI = {
    entity : {
        name: 'producttype',
        keyField: 'producttypeid',
        label: 'Tipo de producto'
    },
    uiTable: {
        fields:{
            producttypeid : {name: 'producttypeid', label:'#' , typeName: 'Number', editable: false, flex: 0.5 },
            producttype : {name: 'producttype', label:'Tipo' , typeName: 'String', editable: true, align: 'center' },
            purchaseproduct : {name: 'purchaseproduct', label:'Producto de compras' , typeName: 'Boolean', editable: true, align: 'center', optional: true},
            saleproduct : {name: 'saleproduct', label:'Producto de ventas' , typeName: 'Boolean', editable: true, align: 'center', optional: true},
        },
        keyComponent: 'producttypeCrudManagement'
    },
    uiForm : {
        fields:{
            producttypeid : {name: 'producttypeid', label:'Codigo' , typeName: 'Number', editable: false,  optional: true },
            producttype : {name: 'producttype', label:'Tipo' , typeName: 'String', editable: true },
            purchaseproduct : {name: 'purchaseproduct', label:'Producto de compras' , typeName: 'Boolean', editable: true, optional: true, size: 3},
            saleproduct : {name: 'saleproduct', label:'Producto de ventas' , typeName: 'Boolean', editable: true, optional: true, size: 3},
        }
    },
    uiTab: (producttype) => getUiTab(producttype)
}

