import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (vehiclebrandid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Marca de vehículo", component: <EditObjectDialog objectid={vehiclebrandid} entityDefinition={vehiclebrandUI}/> },
            ]}
    })
}

export const vehiclebrandUI = {
    entity : {
        name: 'vehiclebrand',
        keyField: 'vehiclebrandid',
        label: 'Marca de vehículo'
    },
    uiTable: {
        fields:{
            vehiclebrandid : {name: 'vehiclebrandid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            vehiclebrand : {name: 'vehiclebrand', label:'Marca' , typeName: 'String', editable: true, align: 'center'},
        },
        keyComponent: 'vehiclebrandCrudManagement'
    },
    uiForm : {
        fields:{
            vehiclebrandid : {name: 'vehiclebrandid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            vehiclebrand : {name: 'vehiclebrand', label:'Marca' , typeName: 'String', editable: true },
        }
    },
    uiTab: (vehiclebrand) => getUiTab(vehiclebrand)
}

