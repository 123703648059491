import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {defaultSendMail} from "../../../../../../View/Form/utils";
import PurchasenoteProviderView from "./PurchasenoteProviderView";
import PurchaseorderProviderView from "./PurchaseorderProviderView";
import ProviderproductView from "./ProviderproductView";
import AttributeProviderDialog from "./AttributeProviderDialog";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

const getUiTab = (providerid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Proveedor", component: <EditObjectDialog objectid={providerid} entityDefinition={providerUI}/> },
                {label : "Atributos", component: <AttributeProviderDialog providerid={providerid}/> },
                {label : "Tarifas", component: <ProviderproductView providerid={providerid}/> },
                {label : "Pedidos", component: <PurchaseorderProviderView providerid={providerid}/> },
                {label : "Albaranes", component: <PurchasenoteProviderView providerid={providerid}/> },
                {label : "Facturas", component: null, disabled: true },
            ]}
    })
}

export const providerUI = {
    entity : {
        name: 'provider',
        keyField: 'providerid',
        label: 'Proveedor',
        urlExcelImport: 'importer/importProviderFromExcel',
        urlDownloadTemplate: 'importer/getProviderTemplate',
        info: {typeName: 'es.rbm.model.jpa.Provider'}
    },
    uiTable: {
        fields:{
            providerid : {name: 'providerid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            provider : {name: 'provider', label:'Nombre' , typeName: 'String', editable: true, flex: 1.5},
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true, align: 'center'},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true, align: 'center'},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, align: 'center'},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, align: 'center'},
        },
        keyComponent: 'providerCrudManagement',
        actions:{
            sendEmail: {
                fun: (entity ,setOpenMail, setMailRecords) => defaultSendMail(entity,'email',setOpenMail, setMailRecords)
            },
        },
    },
    uiForm : {
        fields:{
            providerid : {name: 'providerid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
            provider : {name: 'provider', label:'Nombre' , typeName: 'String' },
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'Number', editable: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true},
        }
    },
    filters : {
        startBegin : true,
        fields: {
            provider : {name: 'provider', label: 'Proveedor' ,typeName: 'String', operator: 'OPERATOR_LIKE', optional: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI/CIF' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            email : {name: 'email', label:'Email' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
    uiTab: (provider) => getUiTab(provider)
}

