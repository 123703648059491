import {doDelete, doPost, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../../../Utils/Notification/notifications";

export const updatePurchasenoteField = (record, callback) =>{
    doPut('rest/purchasenote/update',record, response =>{
        if(response){
            callback && callback()
        }
    })
}

export const updatePurchasenote = (purchasenoteid, callback) =>{
    doPut('rest/purchasenote/update',purchasenoteid, response =>{
        if(response){
            success('Guardado correctamente', callback && callback(response))
            callback && callback(response.data)
        }
    })
}

export const updatePurchasenotedetail = (purchasenotedetailid, callback) =>{
    doPut('rest/purchasenotedetail/update',purchasenotedetailid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deletePurchasenote = (purchasenoteid, callback) =>{
    doDelete('rest/purchasenote/delete/' + purchasenoteid.purchasenoteid,null, response =>{
        callback && callback();
    })
}
export const deletePurchasenotedetail = (purchasenotedetailid, callback) =>{
    doDelete('rest/purchasenotedetail/delete/' + purchasenotedetailid.purchasenotedetailid,null, response =>{
        callback && callback();
    })
}

export const getDetails = (purchasenoteid, callback) =>{
    doPost('purchasenote/getDetails',purchasenoteid, result => callback && callback(result))
}

export const getTotalAmount = (purchasenotedetailid) =>{
    const totalpurchasenotedetailprice = purchasenotedetailid.price * purchasenotedetailid.quantity;
    if(purchasenotedetailid.discount){
        return Math.floor((((totalpurchasenotedetailprice/100)*(100-purchasenotedetailid.discount)) *100))/100;
    }
    return (totalpurchasenotedetailprice * 100) /100;
}