import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";


const ui = {
    fields:{
        compositiondetailid : {name: 'compositiondetailid', label:'Codigo' , typeName: 'String', editable: false, optional: true },
        rawmaterial : {name: 'rawmaterial', label:'Material consumido' , typeName: 'es.rbm.model.jpa.Product', editable: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}
export default function NewProductCompositiondetailDialog(props) {
    const {open, setOpen, product} = props;
    const [title, setTitle] = React.useState('Nuevo detalle');
    const [compositiondetailid, setCompositiondetailid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setCompositiondetailid({...record});
        setComplete(complete);
    }

    const addCompositiondetail = (compositiondetailid, callback) => {
        const toSend = {...compositiondetailid, productid: {productid: product.productid}}
        doPost('rest/compositiondetail/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const compositiondetailid1 = props.compositiondetailid;
        setCompositiondetailid(compositiondetailid1 ? compositiondetailid1 : {});
        setTitle(compositiondetailid1 ? 'Editar detalle' : 'Nueva detalle');
    }, [props.compositiondetailid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addCompositiondetail(compositiondetailid, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={compositiondetailid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
