import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import ConstructionHouseView from "./ConstructionHouseView";
import {customerUI} from "../../../Maintenance/Crud/Customer/customerCrud";
import {WorkspaceMapDialog} from "../../../Agro/Crud/Workspace/WorkspaceMapDialog";
import {ConstructionMapDialog} from "./ConstructionMapDialog";

const getUiTab = (constructionid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Obra", component: <EditObjectDialog objectid={constructionid} entityDefinition={constructionUI}/> },
                {label : "Viviendas", component: <ConstructionHouseView constructionid={constructionid} />},
                {label : "Mapa", component: <ConstructionMapDialog constructionid={constructionid}/> },
            ]
    }})
}

export const constructionUI = {
    entity : {
        name: 'construction',
        keyField: 'constructionid',
        label: 'Obra',
        info: {typeName: 'es.rbm.model.jpa.Construction'}
    },
    uiTable: {
        fields:{
            constructionid : {name: 'constructionid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            construction : {name: 'construction', label:'Nombre' , typeName: 'String', editable: true, align: 'center'},
            customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer', editable: true},
            active : {name: 'active', label:'Activa' , typeName: 'Boolean', optional: true},
        },
        actionFlex: 0.4,
        keyComponent: 'constructionCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            constructionid : {name: 'constructionid', label:'Código' , typeName: 'String', editable: false, optional: true, flex: 0.3},
            construction : {name: 'construction', label:'Nombre' , typeName: 'String', editable: true},
            customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer', editable: true, addObject: customerUI},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true}
        }
    },

    uiTab: (construction) => getUiTab(construction)
}

