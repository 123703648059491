import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme} from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import {error} from "../../../../Utils/Notification/notifications";
import {rootServer, serverName} from "../../../../Utils/Constant";
import {doGetDownloadFile} from "../../../../Utils/Restclient/NetworkActions";

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const theme = createTheme();
export default function BodyDescription({post}) {

    const downloadAPK = () => {
        const fileName = 'Sibema.apk';
        const url = "https://" + serverName + '/' + fileName;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const urlArchivo = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = urlArchivo;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            });
    }
    return (
        <Paper sx={{
            position: 'relative',
            backgroundColor: 'grey.800',
            color: '#fff',
            mb: 4,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${post.image})`,
        }}>
            {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        {post.title}
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        {post.textDescription}
                    </Typography>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Link to={post.firstButton.destination} style={{ textDecoration: 'none', color: 'white' }}>
                            <Button size={'small'} variant="contained">{post.firstButton.title}</Button>
                        </Link>
                        <Link to={post.secondButton.destination} style={{ textDecoration: 'none', color: 'white' }}>
                            <Button size={'small'} variant="outlined" onClick={downloadAPK}>{post.secondButton.title}</Button>
                        </Link>
                    </Stack>
                </Container>
            </Box>
            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <Typography variant="h6" align="center" gutterBottom>

                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    {post.footerText}
                </Typography>
            </Box>
            {/* End footer */}
        </Paper>
    );
}

