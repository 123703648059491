import * as React from 'react';
import {Box, Container, Unstable_Grid2 as Grid} from '@mui/material';
import {CustomCard} from "./overview/custom-card";
import {LastOrders} from "./overview/LastOrders";
import {SaleSimpleBarChart} from "./overview/SaleSimpleBarChart";
import {useEffect} from "react";
import {doGet} from "../../../../../Utils/Restclient/NetworkActions";


const MainDashboard = () => {
    const [records, setRecords] = React.useState({});

    useEffect(() => {
        refresh()
    }, [])

    const refresh = () => {
      doGet("dashboard/getDashboardInfo", result => setRecords(result))
    }

    const printCards = () => {
        const {cards} = records;
        if (cards) {
            return Object.keys(cards).map(c =>
                <Grid xs={12} sm={6} lg={3}>
                    <CustomCard
                        sx={{height: '100%'}}
                        data={cards[c]}
                    />
                </Grid>
            )
        }
    }

    return (<>
        <Box component="main" sx={{flexGrow: 1, py: 8}}>
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    {printCards()}
                    <Grid xs={12} sm={6} lg={6}>
                        <LastOrders sx={{width: '100%', height: '100%'}}/>
                    </Grid>
                    <Grid xs={12} sm={6} lg={6}>
                        <SaleSimpleBarChart url={'dashboard/getEarningsDashboardInfo'}  sx={{width: '100%', height: '100%'}}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </>)
}

export default MainDashboard;
