import {sideBarReports} from "./Report/sideBarReports";
import ObjectManagementView from "../../../View/Form/CrudForm/ObjectManagementView";
import {providerUI} from "./Maintenance/Crud/Provider/providerCrud";
import {customerUI} from "./Maintenance/Crud/Customer/customerCrud";
import {workerUI} from "./Maintenance/Crud/Worker/workerCrud";
import {productUI} from "./Maintenance/Crud/Product/productCrud";
import {accountUI} from "./Maintenance/Crud/Account/AccountCrud";
import StockmovementResumeView from "./Stock/StockmovementResumeView";
import {taskUI} from "./Maintenance/Crud/Task/taskCrud";
import {warehouseUI} from "./Maintenance/Crud/Warehouse/warehouseCrud";
import {tasktypeUI} from "./Maintenance/Crud/Task/tasktypeCrud";
import {saleorderUI} from "./Maintenance/Crud/Saleorder/saleorderCrud";
import {salenoteUI} from "./Maintenance/Crud/Salenote/salenoteCrud";
import {purchasenoteUI} from "./Maintenance/Crud/Purchasenote/purchasenoteCrud";
import StockmovementManagementView from "./Stock/StockmovementManagementView";
import {producttypeUI} from "./Maintenance/Crud/Product/producttypeCrud";
import {inventoryUI} from "./Maintenance/Crud/Inventory/InventoryCrud";
import SendMailView from "../../../View/Form/MailForm/SendMailView";
import {cultivationUI} from "./Agro/Crud/Cultivation/cultivationCrud";
import {treatmentproductUI} from "./Agro/Crud/Treatmentproduct/treatmentproductCrud";
import {fertigationproductUI} from "./Agro/Crud/Fertigationproduct/fertigationproductCrud";
import {recipeUI} from "./Agro/Crud/Recipe/recipeCrud";
import {treatmentUI} from "./Agro/Crud/Treatment/treatmentCrud";
import {fertigationUI} from "./Agro/Crud/Fertigation/fertigationCrud";
import {workspaceUI} from "./Maintenance/Crud/Workspace/workspaceCrud";
import TimetickDay from "./Views/TimetickDay/TimetickDay";
import {workernoteUI} from "./Maintenance/Crud/Worker/workernoteCrud";
import {applicatorUI} from "./Agro/Crud/Applicator/applicatorCrud";
import {tractorUI} from "./Agro/Crud/Tractor/tractorCrud";
import {tankUI} from "./Agro/Crud/Tank/tankCrud";
import {plotUI} from "./Agro/Crud/Plot/plotCrud";
import {campaignUI} from "./Agro/Crud/Campaign/campaignCrud";
import {movementunitUI} from "./Maintenance/Crud/Movementunit/movementunitCrud";
import TimetickMapView from "./Views/TimetickDay/TimeticksMapView/TimetickMapView";
import {dataperiodUI} from "./Agro/Crud/Dataperiod/dataperiodCrud";
import PlannedproductionView from "./Production/PlannedproductionView";
import StockforecastView from "./Stock/StockforecastView";
import {saleinvoiceUI} from "./Maintenance/Crud/Saleinvoice/saleinvoiceCrud";
import {purchaseinvoiceUI} from "./Maintenance/Crud/Purchaseinvoice/purchaseinvoiceCrud";
import MainSidebar from "../../../MainSidebar";
import {purchaseorderUI} from "./Maintenance/Crud/Purchaseorder/purchaseorderCrud";

export const subsideBarTab = {
    sale : {
        fields : [
            {label : "Pedidos", component: <ObjectManagementView entityDefinition={saleorderUI}/>},
            {label : "Facturas", component: <ObjectManagementView entityDefinition={saleinvoiceUI}/>},
            {label : "Albaranes", component: <ObjectManagementView entityDefinition={salenoteUI}/>},
        ]
    },
    maintenance : {
        fields : [
            {label : "Trabajadores", component: <ObjectManagementView entityDefinition={workerUI}/>},
            {label : "Clientes", component:<ObjectManagementView entityDefinition={customerUI}/>},
            {label : "Proveedores", component: <ObjectManagementView entityDefinition={providerUI}/>},
            {label : "Productos", component: null,
                children: {
                    fields :[
                        {label : "Productos", component: <ObjectManagementView entityDefinition={productUI}/>},
                        {label : "Tipos", component: <ObjectManagementView entityDefinition={producttypeUI}/>},
                    ]
                }
            },
            {label : "Tareas", component: null, children: {
                    fields :[
                        {label : "Tareas", component: <ObjectManagementView entityDefinition={taskUI}/>},
                        {label : "Tipos de tarea", component: <ObjectManagementView entityDefinition={tasktypeUI}/>},
                    ]
                }},
            {label : "Almacenes", component: <ObjectManagementView entityDefinition={warehouseUI}/>},
            {label : "Espacios", component: <ObjectManagementView entityDefinition={workspaceUI}/>},
            {label : "Unidades", component: <ObjectManagementView entityDefinition={movementunitUI}/>},
        ]
    },
    purchase : {
        fields : [
            {label : "Pedidos", component: <ObjectManagementView entityDefinition={purchaseorderUI}/>},
            {label : "Facturas", component: <ObjectManagementView entityDefinition={purchaseinvoiceUI}/>},
            {label : "Albaranes", component: <ObjectManagementView entityDefinition={purchasenoteUI}/>},
        ]
    },
    warehouse : {
        fields : [
            {label : "Resumen", component: <StockmovementResumeView/>},
            {label : "Stock", component: <StockmovementManagementView/>},
            {label : "Previsiones", component: <StockforecastView/>},
            {label : "Inventarios", component: <ObjectManagementView entityDefinition={inventoryUI}/>},
        ]
    },
    production : {
        fields : [
            {label : "Planificación", component: <PlannedproductionView/>},
            {label : "Resumen", component: null, disabled: true},
        ]
    },
    fld : {
        fields : [
            {label : "Mantenimiento", component: null,
                children: {
                    fields : [
                        {label : "Aplicadores", component:  <ObjectManagementView entityDefinition={applicatorUI}/>},
                        {label : "Cultivos", component:  <ObjectManagementView entityDefinition={cultivationUI}/>},
                        {label : "Campañas", component:  <ObjectManagementView entityDefinition={campaignUI}/>},
                        {label : "Parcelas", component: <ObjectManagementView entityDefinition={plotUI}/>},
                        {label : "Abonos", component: <ObjectManagementView entityDefinition={fertigationproductUI}/>},
                        {label : "Fitosanitarios", component: <ObjectManagementView entityDefinition={treatmentproductUI}/>},
                        {label : "Recetas", component: <ObjectManagementView entityDefinition={recipeUI}/>},
                        {label : "Maquinaria", children : {
                            fields: [
                                {label : "Tractores", component: <ObjectManagementView entityDefinition={tractorUI}/>},
                                {label : "Cubas", component: <ObjectManagementView entityDefinition={tankUI}/>},
                                {label : "Herramientas", component: null, disabled: true},
                            ]
                        }},
                    ]
                }},
            {label : "Partes de trabajo", component: <ObjectManagementView entityDefinition={dataperiodUI}/>},
            {label : "Tratamientos", component: <ObjectManagementView entityDefinition={treatmentUI}/>},
            {label : "Fertirrigaciones", component: <ObjectManagementView entityDefinition={fertigationUI}/>},
        ]
    },
    hr : {
        fields : [
            {label : "Marcajes", component: <TimetickDay/>, children: null},
            {label : "Mapa de marcajes", component: <TimetickMapView/>, children: null},
            {label : "Enviar correo", component:<SendMailView/>, disabled: false},
            {label : "Notas", component: <ObjectManagementView entityDefinition={workernoteUI}/>},
            {label : "Test", component: <MainSidebar/>},
        ]
    },
    reports : {
        fields : [
            {label : "General", component: null, children: sideBarReports.generalReport},
            {label : "Trabajadores", component: null, children: sideBarReports.workerReport},
            {label : "Productos", component: null, children: sideBarReports.productReport},
            {label : "Tareas", component: null, children: sideBarReports.taskReport},
            {label : "Compras", component: null, children: sideBarReports.purchaseReport},
            {label : "Ventas", component: null, children: sideBarReports.saleReport},
            {label : "Cuaderno de campo", component: null, children: sideBarReports.agroReport},
        ]
    },
    accounts : {
        fields : [
            {label : "Cuentas", component: <ObjectManagementView entityDefinition={accountUI}/>},
            {label : "Roles", component: null},
        ]
    },

}
