import React from "react";
import Button from "@mui/material/Button";
import {appStyles} from "../../styles";
import PropTypes from "prop-types";

export default function SuccessButton(props){
    const {onClick, disabled, text, name, size, endIcon, startIcon} = props;
    let classes = appStyles();
    return (
        <Button size={'small'} className={classes.SuccessButton}  name={name} onClick={onClick} disabled={disabled} variant="contained"
                size={size || "small"} endIcon={endIcon} startIcon={startIcon} type="submit" {...props}>
            {text}
        </Button>
    )
}

SuccessButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    endIcon: PropTypes.object,
    startIcon: PropTypes.object
}
