import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import DataperioddetailsDialog from "./DataperioddetailsDialog";
import {checkFields, getNextField} from "./actions";
import {getProviderproductprice} from "../../../Maintenance/Crud/Purchaseorder/actions";
import DataperiodDocumentView from "../../../Maintenance/Crud/Dataperiod/DataperiodDocumentView";

const getUiTab = (dataperiodid) => {
    return ({
        uiTabs : {fields : [
                {label : "Parte de trabajo", component: <EditObjectDialog objectid={dataperiodid} entityDefinition={dataperiodUI}/> },
                {label : "Trabajadores", component: <DataperioddetailsDialog selected={dataperiodid}/> },
                {label : "Consumos", component: null, disabled: true },
                {label : "Elaboraciones", component: null, disabled: true },
                {label : "Documentos", component: <DataperiodDocumentView dataperiodid={dataperiodid}/> },
            ]}
    })
}

export const dataperiodUI = {
    entity : {
        name: 'agrodataperiod',
        keyField: 'dataperiodid',
        label: 'Parte de trabajo',
        info: {typeName: 'es.rbm.model.jpa.Agrodataperiod'},
    },
    uiTable: {
        fields:{
            dataperiodid : {name: 'dataperiodid', label:'Nº' , typeName: 'Number', editable: false,flex:0.3, align: 'center', optional: true},
            dataperiod : {name: 'dataperiod', label: 'Descripción',typeName:'String', optional: true},
            since : {name: 'since', label: 'Desde',typeName:'Date', optional: false},
            until : {name: 'until', label: 'Hasta',typeName:'Date', optional: false},
            responsibleid : {name: 'responsibleid', label: 'Responsable',typeName:'es.rbm.model.jpa.Worker', optional: false, urlRecords: 'worker/getResponsibles'},
            taskid : {name: 'taskid', label: 'Tarea',typeName:'es.rbm.model.jpa.Task', optional: false},
            workspaceid : {name: 'workspaceid', label: 'Finca',typeName:'es.rbm.model.jpa.Workspace', optional: true},
            plotid : {name: 'plotid', label: 'Sector',typeName:'es.rbm.model.jpa.Plot', optional: true},
            cultivationid : {name: 'cultivationid', label: 'Cultivo',typeName:'es.rbm.model.jpa.Cultivation', optional: true},
            productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', optional: true,  urlRecords: 'agro/getAllsCultivationproduct'},
            externalcode : {name: 'externalcode', label: 'Codigo externo',typeName:'String', optional: true},
        },
        keyComponent: 'AgrodataperiodCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            dataperiodid : {name: 'dataperiodid', label: 'Código' ,typeName: 'String', editable: false, optional: true},
            dataperiod : {name: 'dataperiod', label: 'Descripción' ,typeName: 'String', optional: true},
            since : {name: 'since', label: 'Desde',typeName:'Date', optional: false},
            until : {name: 'until', label: 'Hasta',typeName:'Date', optional: false},
            responsibleid : {name: 'responsibleid', label: 'Responsable',typeName:'es.rbm.model.jpa.Worker', optional: true},
            taskid : {name: 'taskid', label: 'Tarea',typeName:'es.rbm.model.jpa.Task', optional: false},
            workspaceid : {name: 'workspaceid', label: 'Finca',typeName:'es.rbm.model.jpa.Workspace'},
            plotid : {name: 'plotid', label: 'Sector',typeName:'es.rbm.model.jpa.Plot', filterRecords: getNextField},
            cultivationid : {name: 'cultivationid', label: 'Cultivo',typeName:'es.rbm.model.jpa.Cultivation', filterRecords: getNextField},
            productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', optional: true,  urlRecords: 'agro/getAllsCultivationproduct'},
            externalcode : {name: 'externalcode', label: 'Codigo externo',typeName:'String', optional: true},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            checkFields(records, field, value, callback);
        }
    },

    uiTab: (dataperiod) => getUiTab(dataperiod)
}

