import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {movementunitUI} from "../Movementunit/movementunitCrud";


const ui = {
    fields:{
        movementunitid : {name: 'movementunitid', label:'Unidad' , typeName: 'es.rbm.model.jpa.Movementunit', editable: true, addObject: movementunitUI},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}
export default function NewProductContainerDialog(props) {
    const {open, setOpen, product} = props;
    const [title, setTitle] = React.useState('Nueva detalle');
    const [containerid, setContainerid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setContainerid({...record});
        setComplete(complete);
    }

    const addContainer = (containerid, callback) => {
        const toSend = {...containerid, productid: {productid: product.productid}}
        doPost('rest/container/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const containerid1 = props.containerid;
        setContainerid(containerid1 ? containerid1 : {});
        setTitle(containerid1 ? 'Editar detalle' : 'Nueva detalle');
    }, [props.containerid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addContainer(containerid, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={containerid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}