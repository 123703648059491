import * as React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import {Card, CardContent} from "@mui/material";
import Title from "../../Title";
import {useEffect} from "react";
import {doGet} from "../../../../../../Utils/Restclient/NetworkActions";

export const SaleSimpleBarChart = (props) => {
    const {sx, url} = props;
    const [bars, setBars] = React.useState([]);
    const [data, setData] = React.useState([]);

    useEffect(() => {
        refresh()
    }, [])

    const refresh = () => {
        doGet(url, result => {
            setBars(result.bars);
            setData(result.data);
        } )
    }

    return (
        <Card sx={sx}>
            <CardContent>
                <Title sx={{align: 'center'}}>Facturado (€)</Title>
                <BarChart width={700} height={390} data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {bars && bars.map( b => <Bar name={b.name} unit={b.unit} dataKey={b.dataKey} fill={b.fill} />)}
                </BarChart>
            </CardContent>
        </Card>
    );

}
