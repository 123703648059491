import React, {  useEffect } from "react";
import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import CultivationMapView from "./MapView/CultivationMapView";

export const CultivationMapDialog = (props) => {
    const [cultivation, setCultivation] = React.useState(null);


    useEffect(() => {
        const {cultivationid} = props;
        refresh(cultivationid)
    }, [props]);

    const refresh = (cultivationid) => {
        setCultivation(cultivationid)
    }

    return (
        <Box>
            <CultivationMapView markButton={true} cultivationid={cultivation} coordinates={{latitude: 38.06820389131554, longitude: -0.872540639659949}}/>
        </Box>
    );
};

export default CultivationMapView;
