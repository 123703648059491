import {lighten} from "@mui/material";

const getColors = (color) =>{
    return {bgcolor: lighten(color, 0.6), '&:hover': {bgcolor: lighten(color, 0.2)}}
}

export const GREEN_COLOR = '#baf8b4'
export const RED_COLOR = '#f39292'
export const YELLOW_COLOR = '#faf6b2'
export const PURPLE_COLOR = '#e8b0f8'
export const BLUE_LIGHT_COLOR = '#ade7f6'
export const ORANGE_LIGHT_COLOR = '#f6c7ad'
export const SILVER_COLOR = '#C0C0C0'
export const GRAY_COLOR = '#C0C0C0'
export const WHITE_COLOR = '#ffffff'
export const BLACK_COLOR = '#000000'

export const uiRowDatagridColor = {
    '& .color--default': {bgcolor: lighten('#ffffff', 0.6), '&:hover': {bgcolor: lighten('#ecebeb', 0.2)}},
    '& .color--even': {bgcolor: lighten(WHITE_COLOR, 0.6), '&:hover': {bgcolor: lighten('#ecebeb', 0.2)}},
    '& .color--red': {...getColors(RED_COLOR)},
    '& .color--yellow': {...getColors(YELLOW_COLOR)},
    '& .color--green': {...getColors(GREEN_COLOR)},
    '& .color--purple': {...getColors(PURPLE_COLOR)},
    '& .color--blue': {...getColors(BLUE_LIGHT_COLOR)},
    '& .color--silver': {...getColors(SILVER_COLOR)},
    '& .color--gray': {...getColors(GRAY_COLOR)},
    '& .color--white': {...getColors(WHITE_COLOR)},
    '& .color--black': {...getColors(BLACK_COLOR)},
    '& .color--productCrudManagement--true': {...getColors(GREEN_COLOR)},
    '& .color--productCrudManagement--false': {...getColors(RED_COLOR)},
    '& .color--treatmentproductCrudManagement--true': {...getColors(GREEN_COLOR)},
    '& .color--treatmentproductCrudManagement--false': {...getColors(RED_COLOR)},
    '& .color--fertigationproductCrudManagement--true': {...getColors(GREEN_COLOR)},
    '& .color--fertigationproductCrudManagement--false': {...getColors(RED_COLOR)},
    '& .color--treatmentCrudManagement--null': {...getColors(RED_COLOR)},
    '& .color--treatmentCrudManagement--true': {...getColors(GREEN_COLOR)},
    '& .color--treatmentCrudManagement--false': {...getColors(RED_COLOR)},
    '& .color--fertigationCrudManagement--null': {...getColors(RED_COLOR)},
    '& .color--fertigationCrudManagement--true': {...getColors(GREEN_COLOR)},
    '& .color--fertigationCrudManagement--false': {...getColors(RED_COLOR)},
    '& .color--cell--TimetickDayView--1': {...getColors(GREEN_COLOR)},
    '& .color--cell--TimetickDayView--2': {...getColors(RED_COLOR)},
    '& .color--cell--TimetickDayView--3': {...getColors(BLUE_LIGHT_COLOR)},
    '& .color--cell--TimetickDayView--4': {...getColors(PURPLE_COLOR)},
    '& .color--cell--TimetickDayView--5': {...getColors(ORANGE_LIGHT_COLOR)},
    '& .color--workernoteCrudManagement--true': {...getColors(GREEN_COLOR)},
    '& .color--workernoteCrudManagement--false': {...getColors(RED_COLOR)},
    '& .color--workernoteCrudManagement--null': {...getColors(RED_COLOR)},
    '& .color--salenoteCrudManagement--null': {...getColors(RED_COLOR)},
    '& .color--salenoteCrudManagement--object': {...getColors(GREEN_COLOR)},
    '& .color--alusalenoteCrudManagement--null': {...getColors(RED_COLOR)},
    '& .color--alusalenoteCrudManagement--object': {...getColors(GREEN_COLOR)},
    '& .color--purchasenoteCrudManagement--null': {...getColors(RED_COLOR)},
    '& .color--purchasenoteCrudManagement--object': {...getColors(GREEN_COLOR)},
    '& .color--TimetickDayView--KO': {...getColors(RED_COLOR)},
    '& .color--ImputationDayView--0': {...getColors(RED_COLOR)},
    '& .color--vehicleCrudManagement--true': {...getColors(GREEN_COLOR)},
    '& .color--vehicleCrudManagement--null': {...getColors(RED_COLOR)},

}

