import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {vehicleUI} from "./vehicleCrud";

const getUiTab = (vehicleoperationid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Operación", component: <EditObjectDialog objectid={vehicleoperationid} entityDefinition={vehicleoperationUI}/> },
            ]}
    })
}

export const vehicleoperationUI = {
    entity : {
        name: 'vehicleoperation',
        keyField: 'vehicleoperationid',
        label: 'Operación'
    },
    uiTable: {
        fields:{
            vehicleoperationid : {name: 'vehicleoperationid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            vehicleoperation : {name: 'vehicleoperation', label:'Operación' , typeName: 'String', editable: true, align: 'center'},
            vehicleid : {name: 'vehicleid', label:'Vehículo' , typeName: 'es.rbm.model.jpa.Vehicle', editable: true},
            date : {name: 'date', label:'Fecha' , typeName: 'Date', editable: true},
            vehicleoperationtypeid : {name: 'vehicleoperationtypeid', label:'Tipo de operación' , typeName: 'es.rbm.model.jpa.Vehicleoperationtype', editable: true},
            kms : {name: 'kms', label:'Kilómetros' , typeName: 'Number', align: 'center'},
            cost : {name: 'cost', label:'Coste (€)' , typeName: 'Number', optional: true, align: 'center'},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', optional: true, align: 'center'},
        },
        keyComponent: 'vehicleoperationCrudManagement'
    },
    uiForm : {
        fields:{
            vehicleoperationid : {name: 'vehicleoperationid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
            vehicleoperation : {name: 'vehicleoperation', label:'Operación' , typeName: 'String' },
            vehicleid : {name: 'vehicleid', label:'Vehículo' , typeName: 'es.rbm.model.jpa.Vehicle', editable: true, addObject: vehicleUI },
            date : {name: 'date', label:'Fecha' , typeName: 'Date', editable: true},
            vehicleoperationtypeid : {name: 'vehicleoperationtypeid', label:'Tipo de operación', typeName: 'es.rbm.model.jpa.Vehicleoperationtype', editable: true },
            kms : {name: 'kms', label:'Kilómetros' , typeName: 'Number'},
            cost : {name: 'cost', label:'Coste (€)' , typeName: 'Number'},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', size: 12, optional: true, multiline: true, rows: 5},
        }
    },
    uiTab: (vehicleoperation) => getUiTab(vehicleoperation)
}

