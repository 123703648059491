import {doDelete, doGet, doPost, doPut} from "../../../Utils/Restclient/NetworkActions";
import {success} from "../../../Utils/Notification/notifications";


export const addNewObject = (object, entity, callback) => {
    if(!object[entity.keyField] && entity.createUrl){
        doPost(entity.createUrl, object, result => callback && callback(result))
    } else{
        doPost('rest/'+ entity.name +'/create', object, result => callback && callback(result))
    }
}

export const updateObject = (object, entity, callback) => {
    doPut('rest/'+ entity.name +'/update', object, result =>{
        if(result){
            success('Guardado correctamente');
            callback && callback(result.data)
        }
    })
}

export const getObject = (object, entity, callback) => {
    doGet('rest/'+ entity.name +'/'+object[entity.keyField], result =>{
        if(result){
            callback && callback(result)
        }
    })
}

export const deleteObject = (object, entity, callback) => {
    doDelete('rest/' + entity.name +'/delete/' + object[entity.keyField], null,
        () => {
                callback && callback();
        })
}