import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {warehouseUI} from "../Warehouse/warehouseCrud";
import {WorkspaceMapDialog} from "../../../Agro/Crud/Workspace/WorkspaceMapDialog";
import {townUI} from "../Other/townCrud";
import {stateUI} from "../Other/stateCrud";

const getUiTab = (workspaceid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Espacio de trabajo", component: <EditObjectDialog objectid={workspaceid} entityDefinition={workspaceUI}/> },
                {label : "Mapa", component: <WorkspaceMapDialog workspaceid={workspaceid}/> },
            ]}
    })
}

export const workspaceUI = {
    entity : {
        name: 'workspace',
        keyField: 'workspaceid',
        label: 'Espacio de trabajo'
    },
    uiTable: {
        fields:{
            workspaceid : {name: 'workspaceid', label:'#' , typeName: 'Number', editable: false, flex: 0.3, optional: true },
            workspace : {name: 'workspace', label:'Espacio de trabajo' , typeName: 'String', editable: true, flex: 1.5, align: 'center'},
            warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: true, urlRecords: 'rest/warehouse/getActives'},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true, align: 'center'},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', editable: true, align: 'center'},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, align: 'center'},
        },
        keyComponent: 'workspaceCrudManagement'
    },
    uiForm : {
        fields:{
            workspaceid : {name: 'workspaceid', label:'Codigo' , typeName: 'String', editable: false, size: 2, optional: true },
            workspace : {name: 'workspace', label:'Espacio de trabajo' , typeName: 'String', editable: true, size:10},
            warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: true, optional: true, addObject: warehouseUI, urlRecords: 'rest/warehouse/getActives'},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true, optional: true},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', editable: true, optional: true, addObject: townUI},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, optional: true, addObject: stateUI},
        }
    },
    uiTab: (workspace) => getUiTab(workspace)
}

