import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {
    deleteSaleorderdetail,
    getDetails,
    updateSaleorderdetail
} from "./actions";
import Dialog from "@mui/material/Dialog";
import EditSaleorderdetailDialog from "./EditSaleorderdetailDialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";


const ui = {
    fields: {
        //saleorderdetailid : {name: 'saleorderdetailid', label:'Codigo' , typeName: 'Number', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 3, editable: false},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
        unitprice : {name: 'unitprice', label:'Precio unitario' , typeName: 'Number'},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number', optional: true},
        remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', align: 'center', flex: 3, optional: true},
    }
}

export default function DetailsSaleorderDialog(props) {
    const {selected, entityDefinition} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [saleorderdetails, setSaleorderdetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);

    useEffect(() =>{
        if(selected && selected.saleorderid){
            getDetails(selected, result =>{
                setSaleorderdetails(result);
            });
        }
    }, [openEditDetail, selected, refresh])

    const onDelete = (saleorderdetailid) => {
        deleteSaleorderdetail(saleorderdetailid, () => setRefresh(!refresh))
    }

    const onEdit = (saleorderdetailid) => {
        setSelectedDetail(saleorderdetailid)
        setOpenEditdetail(!openEditDetail);
    }

    const onChange = (e) => {
        const record = saleorderdetails.filter(r => r.saleorderdetailid=== e.id);
        if (record && record.length>0) {
            record[0][e.field] = e.value;
            updateSaleorderdetail(record[0], selected)
        } else{
            updateSaleorderdetail(e, selected, () => setRefresh(!refresh))
        }
    }

    return(
        <Box sx={{m: 2, mr: 0}}>
            <Box align={'center'}>
                <Dialog maxWidth={"md"} open={openEditDetail}>
                    <EditSaleorderdetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
                </Dialog>
                <Typography sx={{mb: 3}} component="h1" variant="h5">Detalles del {entityDefinition ? entityDefinition.entity.label : 'pedido'}</Typography>
                <Button size={'small'} variant="contained" onClick={() => onEdit({saleorderid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.saleorderid}>
                    Añadir detalle
                </Button>
            </Box>
            <div>
                <GridTable ui={ui} onDelete={onDelete} onEdit={onEdit}
                           records={saleorderdetails} rowId={'saleorderdetailid'}
                           onChange={onChange} />
            </div>
        </Box>
    )
}
