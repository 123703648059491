import {doGet, doPost} from "../../../../../../../Utils/Restclient/NetworkActions";

export const getWorkspaceInformation = (workspaceid, callback) => {
    if(workspaceid){
        const url = 'workspace/getWorkspaceInformation/' + workspaceid?.workspaceid;
        doGet(url, r => {
            if(r){
                callback && callback(r);
            }
        })
    } else {
        callback && callback()
    }
}

export const saveWorkspaceCoordinates = (coordinates, workspace, callback) => {
    const url = 'workspace/saveWorkspacePolygon';
    doPost(url, {coordinates, workspaceid: workspace}, () => {
        callback && callback()
    });
}