import {doDelete, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../../../Utils/Notification/notifications";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

export const updateInventoryField = (record, callback) =>{
    doPut('rest/inventory/update',record, response =>{
        if(response){
            callback && callback()
        }
    })
}

export const updateInventory = (treatmentid, callback) =>{
    doPut('rest/inventory/update',treatmentid, response =>{
        if(response){
            success('Guardado correctamente', callback && callback(response))
            callback && callback(response.data)
        }
    })
}

export const updateTreatmentcultivation = (treatmentcultivationid, callback) =>{
    doPut('rest/treatmentcultivation/update',treatmentcultivationid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deleteInventory = (treatmentid, callback) =>{
    doDelete('rest/inventory/delete/' + treatmentid.treatmentid,null, response =>{
        callback && callback();
    })
}
export const deleteTreatmentcultivation = (treatmentcultivationid, callback) =>{
    doDelete('rest/treatmentcultivation/delete/' + treatmentcultivationid.treatmentcultivationid,null, response =>{
        callback && callback();
    })
}

export const getDetails = (treatmentid, callback) =>{
    const filters = {
        entity : { name: 'treatmentcultivation'},
        fields: {treatmentid : {name: 'treatmentid', operator: 'OPERATOR_EQUAL', value: treatmentid},},
    }
    searchByCriteria(filters, result =>{
        callback && callback(result)
    });
}

export const getTotalAmount = (inventorydetailid) =>{
    const totalinventorydetailprice = inventorydetailid.unitprice * inventorydetailid.quantity;
    if(inventorydetailid.discount){
        return Math.floor((((totalinventorydetailprice/100)*(100-inventorydetailid.discount)) *100))/100;
    }
    return (totalinventorydetailprice * 100) /100;
}