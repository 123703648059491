import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {
    deleteRecipedetail,
    getDetails,
    updateRecipedetail
} from "./actions";
import Dialog from "@mui/material/Dialog";
import EditRecipedetailDialog from "./EditRecipedetailDialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";


const ui = {
    fields: {
        recipedetailid : {name: 'recipedetailid', label:'Codigo' , typeName: 'Number', editable: false, optional: true},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product' },
        dose : {name: 'dose', label:'Dosis por Ha' , typeName: 'Number'},
    }
}

export default function RecipedetailsDialog(props) {
    const {selected} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [recipedetails, setSaleorderdetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);

    useEffect(() =>{
        if(selected && selected.recipeid){
            refresh()
        }
    }, [openEditDetail, selected])

    const refresh = () => {
        getDetails(selected, result =>{
            setSaleorderdetails(result);
        });
    }

    const onDelete = (recipedetailid) => {
        deleteRecipedetail(recipedetailid)
    }

    const onEdit = (recipedetailid) => {
        setSelectedDetail(recipedetailid)
        setOpenEditdetail(!openEditDetail);
    }

    const onChange = (e) => {
        const record = recipedetails.filter(r => r.recipedetailid=== e.id);
        if(record && record.length > 0){
            record[0][e.field] = e.value;
            updateRecipedetail(record[0])
        } else{
           updateRecipedetail(e, () => refresh());
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} align={'center'}>
            <Dialog  fullWidth maxWidth="md" open={openEditDetail}>
                <EditRecipedetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
            </Dialog>
            <Typography sx={{mb: 3}} component="h1" variant="h5">Detalles de la receta</Typography>
            <Button size={'small'} variant="contained" onClick={() => onEdit({recipeid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.recipeid}>
               Añadir detalle
            </Button>
            <Box>
                <GridTable ui={ui}  onDelete={onDelete} onEdit={onEdit}
                           records={recipedetails} rowId={'recipedetailid'}
                           onChange={onChange} />
            </Box>
        </Box>
    )
}
