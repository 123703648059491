import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import Typography from "@mui/material/Typography";

const fertigationUI = {
    fields: {
        fertigationid : {name: 'fertigationid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3},
        fertigation : {name: 'fertigation', label: 'Fertirrigación' ,typeName: 'String', editable: false},
        since : {name: 'since', label: 'Fecha' ,typeName: 'Date', editable: false},
        workerid : {name: 'workerid', label: 'Responsable' ,typeName: 'es.rbm.model.jpa.Worker', optional: true, editable: false, urlRecords: 'worker/getResponsibles'},
    }
}

const fertigationcultivationUI = {
    fields: {
        productid : {name: 'productid', label: 'Producto' ,typeName: 'es.rbm.model.jpa.Product', optional: true, editable: false},
        quantity : {name: 'quantity', label: 'Cantidad' ,typeName: 'Number', optional: true, editable: false},
    }
}

export default function CultivationFertigationView(props) {
    const {cultivationid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [props])

    const groupBy = (items, key) => items.reduce(
        (result, item) => {
            const newKey = key.split('.').reduce((l, c) => {
                    return l[c]},
                item);
            return {
                ...result,
                [newKey]: [
                    ...(result[newKey] || []),
                    item,
                ],
            }
        },
        {});

    const refresh = () => {
        const filters = {
            entity : {name: 'fertigationcultivation'},
            fields: {cultivationid : {name: 'cultivationid', operator: 'OPERATOR_EQUAL', value: cultivationid}},
        }
        searchByCriteria(filters, result =>{
            const fertigationGroup = groupBy(result, "fertigationid.fertigationid");
            setGroups(fertigationGroup);
            const newRecords = Object.keys(fertigationGroup).map(key => fertigationGroup[key][0].fertigationid);
            setRecords(newRecords)
            setDetails([]);
        });
    }

    const onChange = (e) => {

    }
    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.fertigationid]);
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} align={'center'}>
            <Typography sx={{mb:2}} component="p" variant="h4">{'(' + cultivationid.cultivationid + ') ' + cultivationid.cultivation}</Typography>
            <Box overflow={'auto'}>
                <GridTable ui={fertigationUI}
                           sx={{height: '40vh'}}
                           records={records} rowId={'fertigationid'} onRowClick={onRowClick}
                           onChange={onChange} toolBar={'S'} hideFooter/>
            </Box>
            <Box overflow={'auto'}>
                <GridTable ui={fertigationcultivationUI}
                           sx={{height: '30vh', mt:2}}
                           records={details} rowId={'fertigationcultivationid'}
                           onChange={onChange}  hideFooter/>
            </Box>
        </Box>
    )
}