import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import EditTreatmentdetailDialog from "./EditTreatmentdetailDialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";
import {deleteTreatmentcultivation, getDetails, updateTreatmentcultivation} from "./networkActions";
import Grid from "@mui/material/Grid";
import SingleSelectForm from "../../../../../../View/Form/SingleSelectForm";

const ui = {
    fields: {
        cultivationid : {name: 'cultivationid', label:'Cultivo' , typeName: 'es.rbm.model.jpa.Cultivation', editable: false, flex:2},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false},
        area : {name: 'area', label:'Area (Ha)' , typeName: 'Number', flex: 0.6, editable: false},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', flex: 0.6},
    }
}

export default function DetailsTreatmentDialog(props) {
    const {selected} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [treatmentdetails, setTreatmentdetails] = React.useState([]);
    const [originalsDetails, setOriginalsDetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [selectedCultivation, setSelectedCultivation] = React.useState(null);

    useEffect(() => {
        if(selected && selected){
            getDetails(selected, result =>{
                const records = result.map(r => {
                    return {...r}
                });
                setTreatmentdetails(records);
                setOriginalsDetails(records);
            });
        }
    }, [openEditDetail, selected])

    const onDelete = (treatmentcultivationid) => {
        deleteTreatmentcultivation(treatmentcultivationid, () => setOpenEditdetail(false));

    }

    const onEdit = (treatmentcultivationid) => {
        setSelectedDetail(treatmentcultivationid)
        setOpenEditdetail(!openEditDetail);
    }

    const handledFiltersChange = (var1, var2) =>{
        var recordsToShow = originalsDetails;
        if(var2.name === "productid"){
            if(var1){
                if(var1 && var1.length===0){
                    setSelectedProduct(null)
                } else{
                    setSelectedProduct(var1)
                    recordsToShow = recordsToShow.filter(d => d.productid.productid === var1[0].productid.productid);
                }
            }
            if(selectedCultivation){
                recordsToShow = recordsToShow.filter(d => d.cultivationid.cultivationid === selectedCultivation[0].cultivationid.cultivationid)
            }
        } else {
            if(var1){
                if( var1 && var1.length === 0){
                    setSelectedCultivation(null)
                } else{
                    setSelectedCultivation(var1)
                    recordsToShow = recordsToShow.filter(d => d.cultivationid.cultivationid === var1[0].cultivationid.cultivationid);
                }
            }
            if(selectedProduct){
                recordsToShow = recordsToShow.filter(d => d.productid.productid === selectedProduct[0].productid.productid)
            }
        }
        setTreatmentdetails(recordsToShow);
    }

    const onChange = (e) => {
        const record = treatmentdetails.filter(r => r.treatmentcultivationid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            updateTreatmentcultivation(record[0])
        } else{
            alert('No se puede modificar el detalle')
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} align={'center'}>
            <Dialog fullWidth maxWidth="md" open={openEditDetail}>
                <EditTreatmentdetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
            </Dialog>
            <Typography sx={{mb: 3}}component="h1" variant="h5">
                Detalles del tratamiento
            </Typography>
            <Grid container spacing={1} display={'flex'} justifyContent={'center'}  sx={{maxHeight: '10vh'}}>
                <Grid item md={3}>
                    <Button size={'small'} fullWidth variant="contained" onClick={() => onEdit({treatmentid: selected})} startIcon={<AddIcon />} sx={{mb: 2}} disabled={!selected?.treatmentid}>
                        Añadir detalle
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <SingleSelectForm name={'cultivationid'}
                                      defaultValue={null} typeName={'es.rbm.model.jpa.Cultivation'}
                                      label={'Buscar por cultivo'} isClearable={handledFiltersChange}
                                      onChange={handledFiltersChange} isMulti
                                      key={'FilterCultivationTreatment'}/>
                </Grid>
                <Grid item md={3}>
                    <SingleSelectForm name={'productid'}
                                      defaultValue={null} typeName={'es.rbm.model.jpa.Product'}
                                      label={'Buscar por producto'} isClearable={handledFiltersChange}
                                      onChange={handledFiltersChange} isMulti
                                      urlRecords={'agro/getAllsTreatmentproduct'}
                                      key={'FilterCultivationProductTreatment'}/>
                </Grid>
            </Grid>

            <Box overflow={'auto'}>
                <GridTable ui={ui}
                           sx={{height: '50vh'}}
                           records={treatmentdetails} rowId={'treatmentcultivationid'}
                           onChange={onChange} onDelete={onDelete} onEdit={onEdit} toolBar={'S'}/>
            </Box>
        </Box>
    )
}
