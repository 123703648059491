import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

const defaultUI = {
    fields:{
        houseid : {
            name: 'houseid', label: 'Vivienda', typeName: 'es.rbm.model.jpa.House', size: 12, addObject: {
                uiForm : {
                    entity : {
                        name: 'house',
                        keyField: 'houseid',
                        label: 'Vivienda',
                        info: {typeName: 'es.rbm.model.jpa.House'}
                    },
                    fields: {
                        house: {name: 'house', label: 'Vivienda', typeName: 'String', align: 'center'},
                        constructionid : {name: 'constructionid', label: 'Obra',typeName:'es.rbm.model.jpa.Construction', optional: false},
                    }
                }
            }
        }
    },
    keyComponent: 'NewBudgetAbsenceDialog'
}
export default function NewAlusaleorderHouseDialog(props) {
    const {open, setOpen, alusaleorder, records} = props;
    const [title, setTitle] = React.useState('Nuevo tipo');
    const [houseid, setHouseid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);
    const [ui, setUI] = React.useState(defaultUI);

    const handledChange = (record, complete) =>{
        setHouseid({...record});
        setComplete(complete);
    }

    const addHouse = (houseid, callback) => {
        const toSend = {...houseid, alusaleorderid: {saleorderid: alusaleorder.saleorderid}}
        doPost('rest/saleorderhouse/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const houseid1 = props.houseid;
        setHouseid(houseid1 ? houseid1 : {});
        setTitle(houseid1 ? 'Editar vivienda' : 'Nueva vivienda');
        const newUI = {
            fields:{
                houseid : {name: 'houseid', label: 'Vivienda' ,typeName: 'es.rbm.model.jpa.House', size: 12,
                    criteriaFilter: {
                        entity : { name: 'house'},
                        fields: {
                            constructionid : {name: 'constructionid', operator: OPERATORS.OPERATOR_EQUAL, value: alusaleorder.constructionid},
                        },
                    },
                    addObject: {
                        entity : {
                            name: 'house',
                            keyField: 'houseid',
                            label: 'Vivienda',
                            info: {typeName: 'es.rbm.model.jpa.House'}
                        },
                        uiForm : {
                            fields: {
                                house: {name: 'house', label: 'Vivienda', typeName: 'String', align: 'center'},
                                constructionid : {name: 'constructionid', label: 'Obra',typeName:'es.rbm.model.jpa.Construction', optional: false},
                            }
                        }
                    }},
            }
        }
        setUI(newUI);
    }, [props.houseid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addHouse(houseid, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={houseid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
