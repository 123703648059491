import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {
    deleteSalenotedetail,
    getDetails,
    updateSalenotedetail
} from "./actions";
import Dialog from "@mui/material/Dialog";
import EditSalenotedetailDialog from "./EditSalenotedetailDialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";


const ui = {
    fields: {
        //salenotedetailid : {name: 'salenotedetailid', label:'Codigo' , typeName: 'Number', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 3 },
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
        //total : {name: 'total', label:'Total' , typeName: 'Function', func: (v) => getTotalAmount(v) + ' €' },
    },
}

export default function DetailsSalenoteDialog(props) {
    const {selected} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [salenotedetails, setSalenotedetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);

    useEffect(() =>{
        if(selected && selected.salenoteid){
            getDetails(selected, result =>{
                setSalenotedetails(result);
            });
        }
    }, [openEditDetail, selected, refresh])

    const onDelete = (salenotedetailid) => {
        deleteSalenotedetail(salenotedetailid, () => setRefresh(!refresh))
    }

    const onEdit = (salenotedetailid) => {
        setSelectedDetail(salenotedetailid)
        setOpenEditdetail(!openEditDetail);
    }

    const onChange = (e) => {
        const record = salenotedetails.filter(r => r.salenotedetailid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            updateSalenotedetail(record[0])
        } else{
            updateSalenotedetail(e, () => setRefresh(!refresh))
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}} align={'center'}>
            <Dialog maxWidth={"md"} open={openEditDetail}>
                <EditSalenotedetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
            </Dialog>
            <Typography sx={{
                mb: 3
            }}component="h1" variant="h5">
                Detalles del albarán
            </Typography>
            <Button size={'small'} variant="contained" onClick={() => onEdit({salenoteid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.salenoteid}>
               Añadir detalle
            </Button>
            <Box>
                <GridTable ui={ui} onDelete={onDelete} onEdit={onEdit}
                           records={salenotedetails} rowId={'salenotedetailid'}
                           onChange={onChange} actionFlex={0.4}
                />
            </Box>
        </Box>
    )
}
