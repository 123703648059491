import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (tankid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Cuba", component: <EditObjectDialog objectid={tankid} entityDefinition={tankUI}/> },
            ]}
    })
}

export const tankUI = {
    entity : {
        name: 'tank',
        keyField: 'tankid',
        label: 'Cuba',
        info: {typeName: 'es.rbm.model.jpa.Tank'},
    },
    uiTable: {
        fields:{
            tankid : {name: 'tankid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, sort: 'asc' },
            tank : {name: 'tank', label:'Descripción' , typeName: 'String'},
            model : {name: 'model', label:'Modelo' , typeName: 'String'},
            volume : {name: 'volume', label:'Capacidad' , typeName: 'Number'},
            roma : {name: 'roma', label:'ROMA' , typeName: 'String'},
        },
        keyComponent: 'tankCrudManagement',
    },
    uiForm : {
        fields:{
            tankid : {name: 'tankid', label:'Codigo' , typeName: 'String', editable: false, optional: true, flex: 0.3, sort: 'asc' },
            tank : {name: 'tank', label:'Descripción' , typeName: 'String'},
            model : {name: 'model', label:'Modelo' , typeName: 'String'},
            volume : {name: 'volume', label:'Capacidad' , typeName: 'Number'},
            roma : {name: 'roma', label:'ROMA' , typeName: 'String'},
        }
    },
    uiTab: (tankid) => getUiTab(tankid)
}