import {error} from "../Notification/notifications";
import store from "../Redux/store";
import {actionTypes} from "../Redux/reducer";
import {getToken, rootServer} from "../Constant";
import {JSOGdecode} from "../Jsog/JSOG";
import {useLocation} from "react-router-dom";


const handleJsog = (entity, jsog) =>  {

}

const disableNetwork = () => {
    store.dispatch({type: actionTypes.DISABLE_NETWORK})
}

const enableNetwork = () => {
    store.dispatch({type: actionTypes.ACTIVE_NETWORK})
}

export const networkHandle = () => {
    const originalFetch = window.fetch;
    window.fetch = (url, options = {}) => {
        enableNetwork();
        const event = new Event('networkRequestStart');
        document.dispatchEvent(event);
        return originalFetch(url, options)
            .then(response => {
                const event = new Event('networkRequestEnd');
                document.dispatchEvent(event);
                return response;
            })
            .finally(() => {
                disableNetwork();
            });
    };
}

const handleResponse = (response, entity ,callback, json, jsog) =>{
    if (response.ok){
        if(json){
            return response.json().then(json => {
                if(json){
                    json = JSOGdecode(json);
                    callback && callback(json)
                }
            }).catch(e => callback && callback());
        } else{
            callback && callback()
        }
    } else if(response.status === 500){
        response.text().then(message => error(message));
    } else if(response.status === 401){
        const pathname = window.location.pathname;
        if (pathname.length > 0 && pathname !== "/") {
            store.dispatch({type: actionTypes.UNAUTHORIZED, authorized: false })
        }
        store.dispatch({type: actionTypes.SET_USER, token: null})
        error("No autorizado. Inicie sesión con su cuenta");
        localStorage.removeItem("token-session-erp");
    } else if( response.status === 404) {
        error("No se encuentra la ruta en el servidor");
    } else{
        response.text().then(message =>error(message));
    }
}

export const doGet = (url, callback, jsog) =>{
    const token = getToken();
    fetch(rootServer + url, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            'Authorization': 'Bearer ' + token,
        }
    })
        .then(response =>{
            handleResponse(response,null,callback, true, jsog)
        })
        .catch(e => {
            error(e.toString());
        })

}

export const doPost = (url, entity, callback, json, jsog) => {
    handleJsog(entity, jsog !== false);
    fetch(rootServer + url, {
        method: 'POST',
        body: JSON.stringify(entity),
        headers: {
            "Content-type": "application/json",
            'Authorization': 'Bearer ' + getToken(),
        }
    })
        .then(response =>{
            handleResponse(response,entity ,callback, true, jsog)
        })
        .catch(e => error(e.toString()))
}

export const doDelete = (url, entity, callback, jsog) =>{
    handleJsog(entity, jsog !== false);
    fetch(rootServer + url, {
        method: 'DELETE',
        body: JSON.stringify(entity),
        headers: {
            "Content-type": "application/json",
            'Authorization': 'Bearer ' + getToken(),
        }
    })
        .then(response =>{
            handleResponse(response, entity, callback, false, jsog)
        })
        .catch(e => error(e.toString()))
}

export const doPut = (url, entity, callback, jsog) =>{
    handleJsog(entity, jsog !== false);
    fetch(rootServer + url, {
        method: 'PUT',
        body: JSON.stringify(entity),
        headers: {
            "Content-type": "application/json",
            'Authorization': 'Bearer ' + getToken(),
        }
    })
        .then(response =>{
            handleResponse(response, entity, callback, true)
        })
        .catch(e => error(e.toString()))
}

export const doGetDownloadFile = (url, fileName) =>{
    fetch(rootServer + url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getToken(),
            }
        }
        ).then(response => {
        if (response.ok){
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        } else if(response.status === 500){
            response.text().then(message => error(message));
        } else if(response.status === 401){
            const pathname = window.location.pathname;
            if (pathname.length > 0 && pathname !== "/") {
                store.dispatch({type: actionTypes.UNAUTHORIZED, authorized: false })
            }
            store.dispatch({type: actionTypes.SET_USER, token: null})
            error("No autorizado. Inicie sesión con su cuenta");
            localStorage.removeItem("token-session-erp");
        } else if( response.status === 404) {
            error("No se encuentra la ruta en el servidor");
        } else{
            response.text().then(message =>error(message));
        }
    }).catch( err => error(err))
}


export const doPostDownloadImage = (url, body, callback) =>{
    fetch(rootServer + url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            }
        }
        ).then(response => {
        response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            callback && callback(fileURL)
        })
    }).catch( err => error(err))
}

export const doPostDownloadFile = (url, toSent, fileName) =>{
    fetch(rootServer + url, {
            method: 'POST',
            body: JSON.stringify(toSent),
            headers: {
                "Content-type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            }
        }
        ).then(response => {
        response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        })
    }).catch( err => error(err))
}

export const doGetPDF = (url, callback) => {
    fetch(rootServer + url,
        {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            }
        }
        )
        .then( res => res.blob() )
        .then( blob => {
            var file = window.URL.createObjectURL(blob);
            callback && callback(file);
        })
        .catch( err => error(err.toString()));
}

export const doPostPDF = (url, request, callback) => {
    fetch(rootServer + url,
        {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                "Content-type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            }
        }
        )
        .then( res => res.blob() )
        .then( blob => {
            var file = window.URL.createObjectURL(blob);
            callback && callback(file);
        })
        .catch( err => error(err.toString()));
}

export const doPostFile = (url,entity,fileName, jsog) =>{
    handleJsog(entity, jsog !== false);
    fetch(rootServer + url,
        {
            method: 'POST',
            body: JSON.stringify(entity),
            headers: {
                "Content-type": "application/json",
                'Authorization': 'Bearer ' + getToken(),
            }
        }).then(response => {
            if (response.ok) {
                response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob);
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = fileName;
                    alink.click();
                })
            } else{
                handleResponse(response)
            }

    }).catch( err => error(err))
}

export const doPostExcel = (url, file, callback, json) =>{
    fetch(rootServer + url, {
        method: 'POST',
        body: file,
        headers: {
            'Content-type': 'application/octet-stream',
            'Authorization': 'Bearer ' + getToken(),
        }
    })
        .then(response =>{
            handleResponse(response,file ,callback, false)
        })
        .catch(e => error(e.toString()))
}

