import * as React from 'react';

import Box from "@mui/material/Box";
import {useEffect} from "react";
import GridTable from "./GridTable";
import uiDefinition from "../../Utils/uiDefinition";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {filterRecordFromText} from "../../Utils/UtilsCommon";

export default function SelectSelectEntity(props) {
    const {onSelected, onClose, refreshData, defaultRecords} = props;
    const [ui, setUI] = React.useState({fields: {}});
    const [records, setRecords] = React.useState([]);
    const [originalRecords, setOriginalRecords] = React.useState([]);
    const [filter, setFilter] = React.useState('');

    useEffect(() => {
        getUI(props);
    }, [defaultRecords])

    useEffect(() => {
        if (!filter || !filter.length) {
            setOriginalRecords(records)
        }
    }, [records])

    const handledChange = (v) => {
        onSelected(v.row);
        onClose();
    }

    const getUI = (props) => {
        const uiDefinitionElement = uiDefinition[props.typeName];
        if (uiDefinitionElement) {
            const descriptiveFields = uiDefinitionElement.descriptiveField.split('.').reduce((l, c, i) => {
                const newField = {[c] : {name: c, label: props.label, typeName: 'String', editable: false, align: 'center'}}
                    return {...l, ...newField}
            }, {[uiDefinitionElement.keyField] : {name: uiDefinitionElement.keyField, label:'Codigo' , typeName: 'Number', editable: false, flex: 0.5}});

            const ui  = {fields: {...descriptiveFields}}
            setUI(ui)
            if (refreshData) {
                refreshData(result => setRecords(result));
            }
        }
    }

    const onKeyDown = (params, event) => {
        if (event.key === 'Enter') {
            onSelected(params.row);
            onClose();
        }
    };

    const onChangeFilter = (var1) => {
        const value = var1.target.value;
        setFilter(value);
        if (value) {
            const columns = Object.keys(ui.fields);
            const filteredRecords = filterRecordFromText(value, originalRecords, columns);
            setRecords(filteredRecords);
        } else {
            setRecords(originalRecords);
        }
    };

    return (
        <Box>
            <Grid container sx={{m: 2, width: '96%'}}>
                <Grid item md={12}>
                    <Paper sx={{bgcolor: 'white'}}>
                        <TextField fullWidth autoFocus value={filter}
                                   label={'Buscar...'} onChange={onChangeFilter}/>
                    </Paper>
                </Grid>
            </Grid>
            <GridTable ui={ui} onCellKeyDown={onKeyDown}
                               records={records} filterFocus={true}
                               onRowDoubleClick={handledChange} hideFooter
                               sx={{height:'63vh'}}/>
        </Box>
    )
}

SelectSelectEntity.propTypes = {
    defaultRecords: PropTypes.array
}