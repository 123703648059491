export const defaultTimetickReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date',  optional: false},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date',  optional: false},
            //cultivationid : {name: 'cultivationid', label: 'Cultivo' ,typeName: 'es.rbm.model.jpa.Cultivation', size: 12, optional: true, isMulti: true},
            //pdf : {name: 'pdf', label: 'Formato PDF' ,typeName: 'Boolean',  optional: true},
        }
    },
    title: 'Informe de marcajes',
    description: 'Informe con los marcajes de personas',
    url: 'presence/getTimeticksJasperReport',
    format: () => ('.pdf'),
    documentName: 'Informe_marcajes' + new Date(),
    keyForm: 'defaultTimetickReport',
}


export const defaultMonthlyExcelReport = {
    ui : {
        fields :{
            month : {name: 'month', label: 'Mes' ,typeName: 'Number',  optional: false},
            year : {name: 'year', label: 'Año' ,typeName: 'Number',  optional: false}
        }
    },
    title: 'Informe mensual',
    description: 'Resumen de presencia y horas mensuales',
    url: 'reports/report/es.rbm.report.MonthlyResumeExcelReport',
    format: () => ('.xlsx'),
    documentName: 'Resumen_mensual' + new Date(),
    keyForm: 'defaultMonthlyExcelReport',
}
