import * as React from 'react';
import {useCallback, useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doDelete} from "../../../../../../Utils/Restclient/NetworkActions";
import {error} from "../../../../../../Utils/Notification/notifications";
import Dialog from "@mui/material/Dialog";
import EditCustomerproductDialog from "./EditCustomerproductDialog";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {updateCustomerproduct} from "./actions";



const customerproducttableUI = {
    fields: {
        customerproductid : {name: 'customerproductid', label:'Codigo' , typeName: 'String', editable: false, optional: true},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product' },
        since : {name: 'since', label: 'Desde',typeName:'Date'},
        until : {name: 'until', label: 'Hasta',typeName:'Date'},
        fromquantity : {name: 'fromquantity', label:'Desde cantidad' , typeName: 'Number'},
        toquantity : {name: 'toquantity', label:'Hasta cantidad' , typeName: 'Number'},
        price : {name: 'price', label:'Precio' , typeName: 'Number'},
    }
}

export default function CustomerproductView(props) {
    const {customerid} = props;
    const [records, setRecords] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    const [editDialog, setEditDialog] = React.useState(false);

    useEffect(() =>{
        refresh()
    }, [customerid, editDialog, props])

    const refresh = useCallback(() =>{
        const filters = {
            entity : {name: 'customerproduct'},
            fields: {customerid : {name: 'customerid', operator: 'OPERATOR_EQUAL', value: customerid}},
        }
        searchByCriteria(filters, result =>{
            if(result){
                setRecords(result);
            }
        });
        }, [editDialog, props])

    const onChange = (e) => {
        const record = records.filter(r => r['customerproductid'] === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        updateCustomerproduct(toSend)
    }

    const onEdit = (object) => {
        setSelected(object)
        setEditDialog(true);
    }

    const onDelete = (customerproduct) => {
        doDelete('rest/customerproduct/delete/' + customerproduct.customerproductid, null, refresh)
    }

    const closeEditDialog = () =>{
        setEditDialog(!editDialog);
    }


    function addObject() {
        setSelected({customerid:customerid});
        setEditDialog(true);
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Dialog maxWidth={"md"} open={editDialog}>
                <EditCustomerproductDialog setOpen={closeEditDialog} selected={selected}/>
            </Dialog>
            <Button size={'small'} variant="contained" onClick={addObject} startIcon={<AddIcon />} sx={{m: 1, mb: 2}}> Añadir tarifa </Button>
            <Box overflow={'auto'}>
                <GridTable ui={customerproducttableUI}
                           sx={{height: '70vh'}}
                           records={records} rowId={'customerproductid'}
                           onChange={onChange} onEdit={onEdit} onDelete={onDelete}
                           toolBar={'S'} hideFooter/>
            </Box>
        </Box>
    )
}
