import {doDelete, doPost, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

export const updateAlusalenotedetail = (salenotedetailid, callback) =>{
    doPut('rest/alusalenotedetail/update',salenotedetailid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const newAlusalenotedetail = (request, callback) =>{
    doPost('mobile-production/generateSalenotedetail',request, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deleteAlusalenotedetail = (salenotedetailid, callback) =>{
    doDelete('rest/alusalenotedetail/delete/' + salenotedetailid.salenotedetailid,null, () =>{
        callback && callback();
    })
}

export const getDetails = (salenoteid, callback) => {
    const request = {...salenoteid, saleorderid : {saleorderid : salenoteid?.saleorderid?.saleorderid}};
    const filters = {
        entity : {name: 'alusalenotedetail'},
        fields: {salenoteid : {name: 'salenoteid', operator: 'OPERATOR_EQUAL', value: request}},
    }

    searchByCriteria(filters, result => callback && callback(result));
}
