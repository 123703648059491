import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {vehiclebrandUI} from "./vehiclebrandCrud";
import {doPostDownloadFile, doPostPDF} from "../../../../../../Utils/Restclient/NetworkActions";
import VehicleCardcodeView from "./VehicleCardcodeView";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";

const getUiTab = (vehicleid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Vehículo", component: <EditObjectDialog objectid={vehicleid} entityDefinition={vehicleUI}/> },
                {label : "Identicadores", component: <VehicleCardcodeView vehicleid={vehicleid}/>},
            ]}
    })
}

const getReportOptions = (setOption) => {
    const options = [
        {
            id: 1,
            label: 'Informe vehículo',
            value: 'reports/vehicle/vehicleoperations.jrxml'
        }
    ]
    setOption && setOption([...options]);
}

const onSelectReport = (op, selected, pdfSet) => {
    let toSent = {parameters: {vehicleid: {vehicleid: selected.vehicleid}}};
    let url = 'vehicle/getVehicleReport/';
    let fileName = op.label + '_' + selected.vehicle + '.pdf';
    showOrDownloadDocument(url, toSent, fileName, pdfSet)
}

export const vehicleUI = {
    entity : {
        name: 'vehicle',
        keyField: 'vehicleid',
        label: 'Vehículo',
        colorRow: 'active',
    },
    uiTable: {
        fields:{
            vehicleid : {name: 'vehicleid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            vehicle : {name: 'vehicle', label:'Vehículo' , typeName: 'String', editable: true, align: 'center'},
            vehiclebrandid : {name: 'vehiclebrandid', label:'Marca' , typeName: 'es.rbm.model.jpa.Vehiclebrand', editable: true},
            model : {name: 'model', label:'Modelo' , typeName: 'String', optional: true, align: 'center'},
            platenumber : {name: 'platenumber', label:'Matrícula' , typeName: 'String', align: 'center'},
            birthday : {name: 'birthday', label:'Fecha de matriculación' , typeName: 'Date', align: 'center'},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', optional: true, align: 'center'},
        },
        actions: {
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            }
        },
        keyComponent: 'vehicleCrudManagement'
    },
    uiForm : {
        fields:{
            vehicleid : {name: 'vehicleid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
            vehicle : {name: 'vehicle', label:'Vehículo' , typeName: 'String'},
            vehiclebrandid : {name: 'vehiclebrandid', label:'Marca' , typeName: 'es.rbm.model.jpa.Vehiclebrand', editable: true, flex: 0.3, addObject: vehiclebrandUI },
            model : {name: 'model', label:'Modelo' , typeName: 'String'},
            platenumber : {name: 'platenumber', label:'Matrícula', typeName: 'String'},
            birthday : {name: 'birthday', label:'Fecha de matriculación', typeName: 'Date'},
            active : {name: 'active', label:'Activo' , typeName: 'Boolean', optional: true},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', size: 12, optional: true, multiline: true, rows: 5},
        }
    },
    uiTab: (vehicle) => getUiTab(vehicle)
}

