import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";

const getUiTab = (purchaseorderstatus) =>{
    return ({
        uiTabs : {fields : [
                {label : "Color", component: <EditObjectDialog objectid={purchaseorderstatus} entityDefinition={purchaseorderstatusUI}/> },
            ]}
    })
}

export const purchaseorderstatusUI = {
    entity : {
        name: 'purchaseorderstatus',
        keyField: 'purchaseorderstatusid',
        label: 'Estado de pedido de compra',
        info: {typeName: 'es.rbm.model.jpa.Purchaseorderstatus'},
        colorValue: 'colorid',
    },
    uiTable: {
        fields:{
            purchaseorderstatusid : {name: 'purchaseorderstatusid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            purchaseorderstatus : {name: 'purchaseorderstatus', label:'Estado' , typeName: 'String', flex: 0.8, align: 'center'},
            nature : {name: 'nature', label:'Naturaleza' , typeName:'Number', align: 'center'},
            colorid : {name: 'colorid', label:'Color' , typeName:'es.rbm.model.jpa.Color', align: 'center'},

        },
        keyComponent: 'colorCrudManagement'
    },
    uiForm : {
        fields:{
            purchaseorderstatusid : {name: 'purchaseorderstatusid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            purchaseorderstatus : {name: 'purchaseorderstatus', label:'Estado' , typeName: 'String', flex: 0.8, align: 'center'},
            nature : {name: 'nature', label:'Naturaleza' , typeName:'Number', align: 'center'},
            colorid : {name: 'colorid', label:'Color' , typeName:'es.rbm.model.jpa.Color', align: 'center', editable: false},

        },
    },
    uiTab: (purchaseorderstatus) => getUiTab(purchaseorderstatus)

}

