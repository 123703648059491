import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";


const getUiTab = (colorid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Color", component: <EditObjectDialog objectid={colorid} entityDefinition={purchaseinvoicestatusUI}/> },
            ]}
    })
}

export const purchaseinvoicestatusUI = {
    entity : {
        name: 'purchaseinvoicestatus',
        keyField: 'purchaseinvoicestatusid',
        label: 'Estado de factura de venta',
        info: {typeName: 'es.rbm.model.jpa.Purchaseinvoicestatus'},
        colorValue: 'colorid',
    },
    uiTable: {
        fields:{
            purchaseinvoicestatusid : {name: 'purchaseinvoicestatusid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            purchaseinvoicestatus : {name: 'purchaseinvoicestatus', label:'Estado' , typeName: 'String', flex: 0.8, align: 'center'},
            nature : {name: 'nature', label:'Naturaleza' , typeName:'Number', align: 'center'},
            colorid : {name: 'colorid', label:'Color' , typeName:'es.rbm.model.jpa.Color', align: 'center', editable: false},

        },
        keyComponent: 'purchaseinvoicestatusCrudManagement'
    },
    uiForm : {
        fields:{
            purchaseinvoicestatusid : {name: 'purchaseinvoicestatusid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            purchaseinvoicestatus : {name: 'purchaseinvoicestatus', label:'Estado' , typeName: 'String', flex: 0.8, align: 'center'},
            nature : {name: 'nature', label:'Naturaleza' , typeName:'Number', align: 'center'},
            colorid : {name: 'colorid', label:'Color' , typeName:'es.rbm.model.jpa.Color', align: 'center'},

        },
    },
    uiTab: (purchaseinvoicestatusid) => getUiTab(purchaseinvoicestatusid)
}

