import * as React from 'react';
import Box from "@mui/material/Box";

import {useEffect} from "react";
import GridTable from "../../../../../View/Form/GridTable";
import {doGet} from "../../../../../Utils/Restclient/NetworkActions";
import Paper from "@mui/material/Paper";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import {IconButton} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import EditTimetickDialog from "./EditTimetickDialog";
import AddIcon from "@mui/icons-material/Add";
import {doDeleteRest} from "../../../../../Utils/Restclient/NetworkRestActions";
import {getRelativeMaxViews, maxHeightViews} from "../../../../../Utils/Constant";
import Tooltip from "@mui/material/Tooltip";

export const uiTimeticks = {
    uiTable: {
        sort: false,
        fields:{},
        keyComponent: 'TimetickDayView',
    },
}

const uiFilters = {
    fields: {
        date : {name: 'date', label: 'Fecha' ,typeName: 'Date', size: 12, height: 'small'}
    }
}

const tyckTypes = {
    1 : 'Presencia',
    2 : 'Salida',
    3 : 'Cambio de posición',
    4 : 'Entrada de parte',
    5 : 'Salida de parte',
}

export default function TimetickDay(props) {
    const [ui, setUI] = React.useState(uiTimeticks);
    const [records, setRecords] = React.useState([]);
    const [filters, setFilters] = React.useState({date: new Date().getTime()});
    const [openEditTimetick, setOpenEditTimetick] = React.useState(false);
    const [selected, setSelected] = React.useState(false);

    const handledChange = (filter) =>{
        setFilters({...filter});
    }

    const renderRecord = (result => {
        let maxNumberTick = 0;
        const records = result.reduce((l, r) =>{
            let worker = r.worker;
            let newObject = {worker: worker.workerid, workerid: worker, idcardnumber: worker.idcardnumber, timeworked: r.timeworked,
                status: r.ticks.length === 0 ? 'KO' : 'OK'}
            r.ticks.forEach((tick, i) =>{
                newObject = {...newObject, ["T"+(i+1)]: {
                        timetickid: tick.timetickid,
                        timetick: tick.timetick,
                        timeticktype: tick.timeticktype,
                        workspaceid: tick.workspaceid,
                        latitude: tick.latitude,
                        dataperioddetailid: tick.dataperioddetailid,
                        longitude: tick.longitude,
                        taskid: tick.taskid,
                        workerid: tick.workerid}}
            })
            maxNumberTick = r.ticks.length > maxNumberTick ? r.ticks.length : maxNumberTick;
            return [...l, newObject];
        }, [])
        let newUI = {workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'es.rbm.model.jpa.Worker', align: 'left', editable: false},
            idcardnumber : {name: 'idcardnumber', label: 'DNI' ,typeName: 'String', flex:0.3, editable: false, align: 'center'},
            timeworked : {name: 'timeworked', label: 'Horas de presencia' ,typeName: 'Number', flex:0.3, editable: false}}
        for (let i = 1; i <= maxNumberTick ; i++) {
            const tag = "T" + i;
            newUI = {...newUI, [tag]: {name: [tag], label:[tag], hourField: 'timetick', typeName: 'Hour', flex: 0.2, renderCell : (v, row, column, apiRef) => {
                if (v.value) {
                    const inCell = new Date(v.value.timetick).toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'}).toString();
                    let inTooltip = 'Hora ' + new Date(v.value.timetick).toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit'}).toString();
                    const workerInfo = "Trabajador: " + ((v.value.workerid?.name + " " + v.value.workerid?.surname) || 'Sin trabajador');
                    const taskid = v.value.taskid;
                    const taskInfo = "Tarea: " + (taskid ? "(" + taskid.taskid + ") " + taskid.task : 'Sin tarea');
                    const workspaceid = v.value.workspaceid;
                    const workspaceInfo = "Espacio: " + (workspaceid ? "(" + workspaceid.workspaceid + ") " +  workspaceid.workspace : 'Sin espacio');
                    const timeticktype = v.value.timeticktype;
                    const typeInfo = "Tipo: " + (timeticktype ? "(" + timeticktype + ") " + tyckTypes[timeticktype] : 'Sin tipo');
                    const latitudeInfo = "Latitud: " + (v.value.latitude || '');
                    const longitudeInfo ="Longitud: " + (v.value.longitude || '');
                    const dataperiodInfo ="Parte: " + (v.value.dataperioddetailid?.dataperiodid.dataperiodid || '');
                    const title = <span>{inTooltip}<br/>{workerInfo}<br/>{taskInfo}<br/>{workspaceInfo}<br/>{typeInfo}<br/>{latitudeInfo}<br/>{longitudeInfo}<br/>{dataperiodInfo}<br/></span>;
                    return <Tooltip title={title}>
                        {inCell}
                    </Tooltip>
                }
                return '';
            }}}
        }

        const uiToSet = {...ui}
        uiToSet.uiTable.fields = {...newUI}
        setUI(uiToSet);
        setRecords(records);
    })


    const refresh = (day) => {
        let date = filters?.date? filters.date : new Date().getTime();
        let isValidDate = Date.parse(date)
        let dateTicks;
        if (isValidDate && Object.keys(date).length>0){
            dateTicks = date;
        } else if(day){
            dateTicks = day;
        } else{
            dateTicks = Date.parse(new Date());
        }
        doGet("presence/getTimetickBySubjectGroup/" + dateTicks, result => {
            renderRecord(result);
        })
    }

    useEffect(() =>{
        setSelected(null);
        refresh(new Date().getTime());
    }, [props, filters, openEditTimetick]);

    const changeOpenEditTimetick = () => {
        setOpenEditTimetick(!openEditTimetick);
    }

    const onCellDoubleClick = (e, v) => {
        if(e?.value?.timetickid){
            setSelected(e.value)
            changeOpenEditTimetick()
        }
    }
    const onCellKeyDown = (v, e) => {
        if (e.code.toLowerCase() === 'delete'){
            doDeleteRest('timetick', v.value.timetickid, () => refresh())
        }
    }

    return (
        <Paper sx={{ width: '100%', height: getRelativeMaxViews(), pt: 1, mt:1}}>
            <Dialog maxWidth={"xl"} open={openEditTimetick}>
                <EditTimetickDialog setOpen={setOpenEditTimetick} selected={selected}/>
            </Dialog>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item md={2}>
                    <Button size={'small'} variant="contained" onClick={changeOpenEditTimetick} startIcon={<AddIcon />} sx={{ml: 3}}> Añadir marcaje </Button>
                </Grid>
                <Grid item md={7}/>
                <Grid item md={2}  align={'center'}>
                    <SingleRecordForm ui={uiFilters} records={filters} sx={{backgroundColor: 'white', pb:1, pr: 1, mt: 1}} onChange={handledChange}/>
                </Grid>
                <Grid item md={1} align={'left'} sx={{mt:1}}>
                    <IconButton onClick={() => refresh()} color={'primary'} variant="contained" size="large">
                        <RefreshIcon/>
                    </IconButton>
                </Grid>

                <Grid item md={12}>
                    <GridTable ui={ui.uiTable} sx={{mt:2, mb: 5, m: 2, height: getRelativeMaxViews()}}
                               records={records} rowId={'idcardnumber'}
                               colorCell={'timeticktype'} toolBar={'S'}
                               colorRow={'status'}
                               density={'compact'} onCellDoubleClick={onCellDoubleClick}
                               hideFooter onCellKeyDown={onCellKeyDown} />
                </Grid>
            </Grid>
        </Paper>
    )

}
