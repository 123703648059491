import * as React from 'react';

import Box from "@mui/material/Box";
import {useEffect} from "react";
import GridTable from "./GridTable";
import uiDefinition from "../../Utils/uiDefinition";
import {doGet} from "../../Utils/Restclient/NetworkActions";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {filterRecordFromText} from "../../Utils/UtilsCommon";

export default function SelectGridTableEntity(props) {
    const {onSelected, onClose, rowId} = props;
    const [ui, setUI] = React.useState({fields: {}});
    const [records, setRecords] = React.useState([]);
    const [originalRecords, setOriginalRecords] = React.useState([]);
    const [filter, setFilter] = React.useState('');

    const handledChange = (v) => {
        const field = props.fieldSelected.definition.name;
        const oldElement = props.fieldSelected.row;
        const newElement = {...oldElement};
        newElement[field] = v.row;
        onSelected(newElement, oldElement, true);
        onClose();
    }

    const getUI = (props) => {
        const uiDefinitionElement = uiDefinition[props.fieldSelected.definition.typeName];
        if (uiDefinitionElement) {
            const descriptiveFields = uiDefinitionElement.descriptiveField.split('.').reduce((l, c, i) =>{
                const newField = {[c] : {name: c, label: props.fieldSelected.definition.label  , typeName: 'String', editable: false, align: 'center', filterable: false}}
                    return {...l, ...newField}
            }, {[uiDefinitionElement.keyField] : {name: uiDefinitionElement.keyField, label:'Código' , typeName: 'Number', editable: false, flex: 0.5}});

            const ui  = {fields: {...descriptiveFields}}
            setUI(ui)
            const url = props.fieldSelected.definition.urlRecords ? props.fieldSelected.definition.urlRecords : uiDefinitionElement.url;
            doGet(url, result => {
                setOriginalRecords(result);
                setRecords(result)
            });
        }
    }

    useEffect(() =>{
        if (props.fieldSelected) {
            getUI(props);
        }
    }, [props])

    const onKeyDown = (params, event) => {
        if (event.key === 'Enter') {
            handledChange(params)
        }
    };

    const onChangeFilter = (var1) => {
        const value = var1.target.value;
        setFilter(value);
        if (value) {
            const columns = Object.keys(ui.fields);
            const filteredRecords = filterRecordFromText(value, originalRecords, columns);
            setRecords(filteredRecords);
        } else {
            setRecords(originalRecords);
        }
    };

    return (
        <Box>
            <Grid container sx={{m: 2, width: '96%'}}>
                <Grid item md={12}>
                    <Paper sx={{bgcolor: 'white'}}>
                        <TextField fullWidth autoFocus
                                   value={filter}
                                   label={'Buscar...'} onChange={onChangeFilter}/>
                    </Paper>
                </Grid>
            </Grid>

            <GridTable ui={ui} onCellKeyDown={onKeyDown}
                       records={records}
                       onRowDoubleClick={handledChange} hideFooter
                       sx={{height:'63vh'}}/>
        </Box>
    )
}

SelectGridTableEntity.propTypes = {
    onSelected: PropTypes.func,
    onClose: PropTypes.func,
    rowId: PropTypes.string,
}