import Typography from "@mui/material/Typography";
import * as React from "react";
import {Link} from "react-router-dom";

export default function Copyright(){
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link to={"/"}>
               Rubén Bernabé Martínez
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}