export const initialState ={
    user: null,
    unauthorizedUser: false,
    token: null, notification: null,
    notificationmessage : '',
    active_network: false,
    menu_ui: null,
    menuVisibility: true,
}

export const actionTypes = {
    SET_USER        :   "SET_USER",
    SET_MENU_UI        :   "SET_MENU_UI",
    LOGOUT          :   "LOGOUT",
    SHOW_NOTIFICATION  :   "SHOW_NOTIFICATION",
    HIDE_NOTIFICATION  :   "HIDE_NOTIFICATION",
    UNAUTHORIZED  :   "UNAUTHORIZED",
    ACTIVE_NETWORK      :   "ACTIVE_NETWORK",
    DISABLE_NETWORK     :   "DISABLE_NETWORK",
    VISIBLE_MENU     :   "VISIBLE_MENU",
}

const reducer = (state, action) => {
    switch (action.type){
        case actionTypes.SET_USER:
            return {...state,
                    token: action.token
                    }
            break
        case actionTypes.SET_MENU_UI:
            return {...state,
                    menu_ui: action.menu_ui
                    }
            break
        case actionTypes.LOGOUT:
            return {...state,
                    token: null,
                    }
            break
        case actionTypes.SHOW_NOTIFICATION:{
            return {...state,
                notification: action.notification,
                notificationmessage: action.notificationmessage
            }
            break
        }
        case actionTypes.HIDE_NOTIFICATION:{
            return {...state,
                notification: false,
                notificationmessage: ''
            }
            break
        }
        case actionTypes.UNAUTHORIZED:{
            return {...state,
                unauthorizedUser: action.authorized
            }
            localStorage['token-session-erp'] = null;
            break
        }
        case actionTypes.ACTIVE_NETWORK:{
            return {...state,
                active_network: true
            }
            break
        }
        case actionTypes.DISABLE_NETWORK:{
            return {...state,
                active_network: false
            }
            break
        }
        case actionTypes.VISIBLE_MENU:{
            return {...state,
                menuVisibility: action.value
            }
            break
        }
        default: return state;
    }
}

export default reducer;

