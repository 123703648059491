import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SingleRecordForm from "../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../View/Button/SuccessButton";
import {generatePlannedProduction} from "./PlannedproductionController";
import {Dialog} from "@mui/material";
import GridTable from "../../../../View/Form/GridTable";
import {doPost} from "../../../../Utils/Restclient/NetworkActions";
import {updateDataperioddetail} from "../Agro/Crud/Dataperiod/actions";

const ui = {
    fields:{
        date : {name: 'date', label:'Fecha' , typeName: 'Date', editable: true, size: 3},
        workerid : {name: 'workerid', label:'Responsable' , typeName: 'es.rbm.model.jpa.Worker', size: 3, urlRecords: 'worker/getResponsibles'}
    }
}

const uiPending = {
    fields: {
        number : {name: 'number', label: '#',typeName:'String', editable: false, flex: 0.4},
        reference : {name: 'reference', label: 'Referencia',typeName:'String', editable: false, flex: 0.75},
        customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', editable: false},
        date : {name: 'date', label: 'Fecha',typeName:'Date', editable: false, flex: 0.6},
        shippingdate : {name: 'shippingdate', label: 'Fecha salida',typeName:'Date', editable: false, flex: 0.6},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', editable: false, flex: 0.45},
        launched : {name: 'launched', label: 'Lanzado',typeName:'Number', editable: false, flex: 0.45},
        done : {name: 'done', label: 'Hecho',typeName:'Number', editable: false, flex: 0.45},
        orderremarks : {name: 'orderremarks', label: 'Observaciones', typeName:'String', flex: 2, optional: true},
        suggestion : {name: 'suggestion', label: 'Sugerencia',typeName:'Number', flex: 0.45, editable: false},
        toAssign : {name: 'toAssign', label: 'A fabricar', typeName:'Number', flex: 0.5, optional: true},
    }
}

export default function AddPlannedproductionDialog(props) {
    const {open, setOpen, data} = props;
    const [isComplete, setComplete] = React.useState(false);
    const [record, setRecord] = React.useState({});
    const [pendingsaleorders, setPendingSaleorders] = React.useState([]);
    const [title, setTitle] = React.useState('Asignar pedidos');

    const handledChange = (change, complete) => {
        const toSave = {...change}
        if (!record.date) {
            const pending = data.quantity - (data.launched || 0);
            toSave.quantity =  pending;
        }
        setRecord({...toSave});
        setComplete(complete);
    }
    
    useEffect(() =>{
        refresh();
    }, [data])

    const refresh = () => {
        doPost("plannedproduction/assignablesInfo", data, result => {
            if (result) {
                setPendingSaleorders(result)
            }
        })
    }

    const onSuccessClick = (e) => {
        e.preventDefault();
        generatePlannedProduction({...record, assignables: pendingsaleorders}, () => setOpen(false))
    };

    const onChangeAssignable = (e) => {
        const existent = pendingsaleorders.filter(ps => ps.number === e.number);
        if (existent.length > 0) {
            const index = pendingsaleorders.indexOf(existent[0]);
            pendingsaleorders[index] = e;
            setPendingSaleorders(pendingsaleorders);
        }
    };

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Box>
                <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                <SingleRecordForm ui={ui} records={record} onChange={handledChange} disableAutoFocus />
            </Box>
            <Box sx={{mt: 2}}>
                <GridTable ui={uiPending} records={pendingsaleorders} sx={{height: '40vh'}}
                    onChange={onChangeAssignable} rowId={'number'}/>
            </Box>
            <Box>
                <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
            </Box>
        </Box>
    )
}