import {doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doDeleteRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

export const updateDataperioddetail = (dataperioddetailid, callback) => {
    let toSent = [];
    if(dataperioddetailid.workerid instanceof Array){
        toSent = dataperioddetailid.workerid.map(worker =>{
            return {...dataperioddetailid, workerid: worker, dataperiodid: {dataperiodid: dataperioddetailid?.dataperiodid?.dataperiodid}}
        });
    } else{
        toSent = [{...dataperioddetailid, dataperiodid: {dataperiodid: dataperioddetailid?.dataperiodid?.dataperiodid}}]
    }

    toSent.forEach(dd => {
        doPut('rest/agrodataperioddetail/update',dd, response =>{
            if(response){
                callback && callback();
            }
        })
    })
}

export const deleteDataperioddetail = (dataperioddetailid, callback) =>{
    doDeleteRest('agrodataperioddetail', dataperioddetailid.dataperioddetailid, callback)
}

export const getDetails = (dataperiodid, callback) =>{
    const filters = {
        entity : {name: 'agrodataperioddetail'},
        fields: {dataperiodid : {name: 'agrodataperiodid', operator: 'OPERATOR_EQUAL', value: {dataperiodid: dataperiodid.dataperiodid}}},
    }
    searchByCriteria(filters, result =>{
        callback && callback(result)
    });
}

export const getNextField = (record, callback) =>{
    const fieldstoChange = ['workspaceid','plotid', 'cultivationid']
    const className = ['workspace','plot', 'cultivationplot']
    const isReference = ['cultivationid']
    const fields = Object.keys(record).filter(r => record[r] && fieldstoChange.includes(r));
    if(fields.length){
        const field = fieldstoChange[fields.length-1];
        if (fieldstoChange.includes(field)){
            const index = fieldstoChange.indexOf(field);
            if(index !== fieldstoChange.length-1){
                const filters = {
                    entity : {name: className[index+1]},
                    fields: {
                        [field] : {name: field, operator: OPERATORS.OPERATOR_EQUAL, value: record[field]},
                    }
                }
                const nextField = fieldstoChange[index+1];
                if(nextField){
                    searchByCriteria(filters, result =>{
                        if (result && result.length > 0) {
                            let toReturn = [...result];
                            if(isReference.includes(nextField)){
                                toReturn = result.map(r => r[nextField]);
                            }
                            callback && callback(toReturn)
                        } else {
                            callback && callback([])
                        }
                    });
                }

            }
        }
    }
    callback && callback([]);
}

export const checkFields = (record, field, value, callback) =>{
    const fieldstoCheck = ['workspaceid','plotid', 'cultivationid']
    if (fieldstoCheck.includes(field)) {
        const index = fieldstoCheck.indexOf(field);
        const newRecord = {...record};
        for (let i = index+1; i < fieldstoCheck.length; i++) {
            delete newRecord[fieldstoCheck[i]];
        }
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}