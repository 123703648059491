import * as React from 'react';

import {useEffect} from "react";
import GridTable from "../../../../../View/Form/GridTable";
import {doGet, doPost, doPut} from "../../../../../Utils/Restclient/NetworkActions";
import Paper from "@mui/material/Paper";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import {IconButton} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import EditImputationDialog from "./EditImputationDialog";
import AddIcon from "@mui/icons-material/Add";
import {doDeleteRest} from "../../../../../Utils/Restclient/NetworkRestActions";
import {getRelativeMaxViews, maxHeightViews} from "../../../../../Utils/Constant";
import {OPERATORS} from "../../../../../Utils/Persistence/PersistenceConstant";
import {error} from "../../../../../Utils/Notification/notifications";

export const uiImputation = {
    uiTable: {
        sort: false,
        fields:{
            workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'es.rbm.model.jpa.Worker', align: 'left', editable: false, flex: 1.5},
            idcardnumber : {name: 'idcardnumber', label: 'DNI' ,typeName: 'String', align: 'center', editable: false},
            since : {name: 'since', label: 'Desde' , typeName: 'Hour', flex:0.3, align: 'center'},
            until : {name: 'until', label: 'Hasta' , typeName: 'Hour', flex:0.3, align: 'center'},
            workspaceid : {name: 'workspaceid', label: 'Espacio' ,typeName: 'es.rbm.model.jpa.Workspace', align: 'center'},
            taskid : {name: 'taskid', label: 'Tarea' ,typeName: 'es.rbm.model.jpa.Task', align: 'center'},
            hourlycost : {name: 'hourlycost', label: 'Precio hora' ,typeName: 'Number',  align: 'center'},
            hours : {name: 'hours', label: 'Horas' ,typeName: 'Number', editable: false, align: 'center'},
        },
        keyComponent: 'ImputationDayView',
    },
}

const uiFilters = {
    fields: {
        since : {name: 'since', label: 'Fecha' ,typeName: 'Date', size: 12, height: 'small', operator: OPERATORS.OPERATOR_BETWEEN}
    }
}

export default function ImputationDay(props) {
    const [state, setState] = React.useState({
            ui: uiImputation, records: [], filters: {since: new Date().getTime()}, openEditTimetick: false,
            selected: null, update: true
        }
    );
    const {ui, records, filters, openEditTimetick} = state;

    const handledChange = (filter) =>{
        setState({...state, filters: filter, update: true});
    }

    const refresh = (day) => {
        let date = filters?.since? filters.since : new Date().getTime();
        let isValidDate = Date.parse(date)
        let dateTicks;
        if (isValidDate && Object.keys(date).length > 0){
            dateTicks = date;
        } else if(day){
            dateTicks = day;
        } else {
            dateTicks = Date.parse(new Date());
        }

        const toSend = {day: dateTicks}
        doPost('salary/getImputationWorkedDay', toSend,records => {
            setState({...state, records: records, update: false})
        })
    }

    useEffect(() => {
        const {update} = state;
        if (update) {
            refresh();
        }

    }, [props, filters, state]);

    const changeOpenEditTimetick = () => {
        setState({...state, openEditTimetick: !openEditTimetick})
    }

    const generateImputations = () => {
        doPost('salary/generateImputations', {day: filters.since}, () => refresh())
    }

    const onCellDoubleClick = (e, v) => {
        if(e?.value?.timetickid){
            setState({...state, selected: e.value});
            changeOpenEditTimetick()
        }
    }
    const onCellKeyDown = (v, e) => {
        if (e.code.toLowerCase() === 'delete'){
            doDeleteRest('imputation', v.value.imputation, () => refresh())
        }
    }

    const onChange = (e) =>{
        const record = records.filter(r => r.imputationid === e.id);
        if (record && record.length > 0) {
            const index = records.indexOf(record[0]);
            const toSend = {...record[0]};
            toSend[e.field] = e.value
            doPut('rest/imputation/update',toSend, response =>{
                if(!response){
                    error('Fallo al modificar Imputación')
                } else {
                    records[index] = response;
                }
                setState({...state, records: [...records], update: true});
            })
        } else{
            error('Fallo al modificar imputación')
        }
    }

    return (
        <Paper sx={{ width: '100%', height: maxHeightViews + 3, pt: 1, mt:1}}>
            <Dialog maxWidth={"md"} open={openEditTimetick}>
                <EditImputationDialog parentSetState={setState} parentState={state}/>
            </Dialog>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item md={2}>
                    <Button variant="contained" onClick={generateImputations} sx={{ml: 3}}> Generar imputaciones </Button>
                </Grid>
                <Grid item md={7}/>
                <Grid item md={2}  align={'center'}>
                    <SingleRecordForm ui={uiFilters} records={filters} sx={{pb:1, pr: 1, mt: 1}} onChange={handledChange}/>
                </Grid>
                <Grid item md={1} align={'left'} sx={{mt:1}}>
                    <IconButton onClick={() => refresh()} color={'primary'} variant="contained" size="large">
                        <RefreshIcon/>
                    </IconButton>
                </Grid>

                <Grid item md={12}>
                    <GridTable ui={ui.uiTable} sx={{mt:2, mb: 5, m: 2, height: getRelativeMaxViews(-6)}}
                               records={records} rowId={'imputationid'} toolBar
                               colorRow={'hours'} onChange={onChange}
                               columnSorting={[{ field: 'workerid', sort: 'asc' }]}
                               density={'compact'} onCellDoubleClick={onCellDoubleClick}
                               onCellKeyDown={onCellKeyDown} />
                </Grid>
            </Grid>
        </Paper>
    )

}