import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {Collapse, Dialog, IconButton, Stack, TableCell} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {doDelete, doGet, doPost, doPostDownloadFile} from "../../../../../../Utils/Restclient/NetworkActions";
import PrintIcon from '@mui/icons-material/Print';
import {getPurchaseinvoicedetails} from "./actions";
import AddPurchasenoteDialog from "./AddPurchasenoteDialog";
import MenuButton from "../../../../../../View/Button/MenuButton";
import Grid from "@mui/material/Grid";
import GridTable from "../../../../../../View/Form/GridTable";
import Paper from "@mui/material/Paper";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {TotalizerRow} from "../../../../../../View/TotalizerRow";


const ui = {
    fields: {
        purchaseinvoicedetail : {name: 'purchaseinvoicedetail', label: 'Concepto',typeName:'String', flex: 3, align: 'center'},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 3},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
        price : {name: 'price', label:'Precio' , typeName: 'Number'},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number'},
        batchid : {name: 'batchid', label:'Lote' , typeName: 'es.rbm.model.jpa.Batch'},
        vatid : {name: 'vatid', label:'IVA' , typeName: 'es.rbm.model.jpa.Vat'},
        purchasenotedetailid : {name: 'purchasenotedetailid', label:'Albarán', typeName: 'Boolean'},
    },
    sort: {
        field: 'purchaseinvoicedetailis',
        sort: 'desc'
    },
    keyComponent: 'purchaseinvoiceDetails',
}

const uiTotalizer = {
    columns: {
        baseAmount: 'Base (€)',
        vatType: 'Tipo IVA',
        vatAmount: 'Total IVA (€)',
        retention: 'Retención (€)',
        total: 'TOTAL (€)'
    }
}

export default function DetailsPurchaseInvoiceDialogNew(props) {
    const {purchaseinvoiceid} = props;
    const [records, setRecords] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [reportOptions, setReportOptions] = React.useState(null);
    const [details, setDetails] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [open])

    const refresh = () => {
      doPost('purchaseinvoice/getPurchaseinvoiceInfo', {...purchaseinvoiceid}, result => {
          setRecords(result)
          getPurchaseinvoicedetails(purchaseinvoiceid, purchaseinvoicedetails => {
              setDetails(purchaseinvoicedetails);
          })
      });
    }

    const onSelectReport = (op) => {
        doPostDownloadFile('purchaseinvoice/downloadPurchaseinvoiceReport/' + purchaseinvoiceid.purchaseinvoiceid, op, op.label + '_' + purchaseinvoiceid.purchaseinvoiceid + '.pdf');
    }

    const onDelete = (purchaseinvoicedetailid) => {
        doDelete('rest/purchaseinvoicedetail/delete/' + purchaseinvoicedetailid.purchaseinvoicedetailid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const record = details.filter(r => r.purchaseinvoicedetailid === e.id);
        if (record.length) {
            record[0][e.field] = e.value;
            doPutRest('purchaseinvoicedetail', record[0], () => refresh());
        } else {
            doPutRest('purchaseinvoicedetail', e, () => refresh());
        }
    }

    const addNewDetailFromPurchasenotedetail = (e) => {
        const toSend = {purchaseinvoiceid: purchaseinvoiceid, purchasenotedetailid: e.purchasenotedetailid}
        doPost("purchaseinvoice/addPurchaseinvoicedetailFromPurchasenotedetail", toSend, () => refresh());
    }

    const addNewDetail = (e) => {
       doPutRest('purchaseinvoicedetail', e, () => refresh())
    }

    return(
        <Box sx={{m: 2, alignItems: 'center', width: '100%' }} align={'center'}>
            <Dialog fullWidth maxWidth="md" open={open}>
                <AddPurchasenoteDialog purchaseinvoiceid={purchaseinvoiceid} setOpen={setOpen} open={open}/>
            </Dialog>
            <Stack direction="row"
                   spacing={2}
                   sx={{mb: 2}}
                   justifyContent="center"
                   alignItems="center">
                <Button size={'small'} onClick={() => setOpen(true)} variant="contained" startIcon={<AddIcon />} sx={{m: 1, mb:2}}>
                    Añadir albarán
                </Button>
                <Button size={'small'} variant="contained" onClick={() => addNewDetail({purchaseinvoiceid: purchaseinvoiceid})} startIcon={<AddIcon />} sx={{m: 1, mb: 2}} disabled={!purchaseinvoiceid?.purchaseinvoiceid}>
                    Añadir detalle
                </Button>
            </Stack>
            <Grid container spacing={2}>
                {records && records.purchasenotes?.length ? <Grid item  sx={{height: '65vh'}} md={4} overflow={'auto'}>
                    { records.purchasenotes.sort((purchasenoteA, purchasenoteB) => purchasenoteA.purchasenote.purchasenoteid < purchasenoteB.purchasenote.purchasenoteid ? 1 : 0).map((purchasenote, index) =>
                        <Row refresh={refresh} purchasenote={purchasenote} key={index} onDelete={onDelete} addNewDetail={addNewDetailFromPurchasenotedetail}/>)}
                </Grid> : '' }
                <Grid item md={records && records.purchasenotes?.length ? 8 : 12}>
                    {details?.length ?
                        <Box>
                            <Paper sx={{p:1}}>
                                <GridTable ui={ui} onDelete={onDelete}
                                           records={details} rowId={'purchaseinvoicedetailid'}
                                           onChange={onChange} density={'compact'} hideFooter={true}
                                           toolBar sx={{height: '65vh'}}
                                />
                            </Paper>
                        </Box> : ''
                    }
                </Grid>
                <Grid item md={12}>
                    { records && <TotalizerRow ui={uiTotalizer} data={records.totalizer}/> }
                </Grid>
            </Grid>
        </Box>
    )
}

const Row = (props) => {
    const {purchasenote, onDelete, addNewDetail} = props;
    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
    const [open, setOpen] = React.useState(false);
    return(
        <Table stickyHeader sx={{backgroundColor: 'white', mb: 1}}  >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{width: '10%'}}>
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align={'center'} style={{width: '35%'}}>
                        <strong>{'Albarán: '}</strong> {purchasenote.purchasenote.serie + purchasenote.purchasenote.number}
                    </TableCell>
                    <TableCell align={'center'} style={{width: '35%'}}>
                        <strong>{'Fecha: '}</strong> {new Date(purchasenote.purchasenote.date).toLocaleDateString('es-ES', options)}
                    </TableCell>
                    <TableCell align={'center'} style={{width: '25%'}}>
                        <strong>{'Nº Líneas: '}</strong> {purchasenote.purchasenotedetailList.length}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table stickyHeader size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: '5%'}} align={'center'}  component="th" scope="row"> <strong>Nº</strong></TableCell>
                                        <TableCell style={{width: '60%'}} align={'center'}> <strong>Concepto</strong></TableCell>
                                        <TableCell style={{width: '10%'}} align={'center'}> <strong>Unidades</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {purchasenote.purchasenotedetailList.map((detailRow, i) => (
                                        <TableRow key={i} style={{ textDecoration: detailRow.purchaseinvoicedetailid ? 'none' : 'line-through'}}>
                                            <TableCell style={{width: '5%'}} align={'center'} component="th" scope="row"> {i+1}</TableCell>
                                            <TableCell style={{width: '60%'}} align={'center'}> {detailRow.concept} </TableCell>
                                            <TableCell style={{width: '10%'}} align={'center'}> {detailRow.quantity} </TableCell>
                                            <TableCell style={{width: '10%'}} align={'center'}> {detailRow.purchaseinvoicedetailid ?
                                                <IconButton onClick={() => onDelete(detailRow.purchaseinvoicedetailid)} value={2} color={'error'} variant="contained" size="small">
                                                    <DeleteIcon/>
                                                </IconButton> :
                                                <IconButton onClick={() => addNewDetail(detailRow)} value={2} color={'success'} variant="contained" size="small">
                                                    <AddIcon/>
                                                </IconButton>
                                            } </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {purchasenote?.purchasenote?.remarks?.length ? <RemarksRow remarks={purchasenote.purchasenote.remarks}/> : ''}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Table>)
}

const RemarksRow = (props) => {
    const {remarks} = props;
    const [open, setOpen] = React.useState(false);
    return(
        <Table stickyHeader size="small" sx={{backgroundColor: 'white'}}  >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{width: '10%'}}>
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align={'center'} style={{width: '90%'}}>
                        <strong>{'Observaciones'}</strong>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {remarks}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Table>)
}
