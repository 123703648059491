import * as React from 'react';
import Box from "@mui/material/Box";

import {useEffect} from "react";
import {doPost} from "../../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../../View/Form/GridTable";
import SingleSelectForm from "../../../../../../../View/Form/SingleSelectForm";


export const uiNewTreatmenDetail = {
    uiTable: {
        fields:{
            cultivationid : {name: 'cultivationid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3 },
            cultivation : {name: 'cultivation', label:'Nombre' , typeName: 'String', editable: false},
            productid : {name: 'productid', label:'Producto cultivado', typeName: 'es.rbm.model.jpa.Product',editable: false},
            area : {name: 'area', label:'Área(Ha)' , typeName: 'Number', editable: false},
            areacover : {name: 'areacover', label:'Área del tratamiento(Ha)' , typeName: 'Number', editable: true},
            percent : {name: 'percent', label:'Porcentaje' , typeName: 'Progress', editable: false},
        },
        keyComponent: 'newTreatmentDetailCrudManagement',
    },
}

export const uiProduct = {
    uiTable: {
        fields:{
            productid : {name: 'productid', label: 'Producto' ,typeName: 'Number', optional: true, editable: false},
            product : {name: 'product', label: 'Producto' ,typeName: 'String', editable: false},
            measureunitid : {name: 'measureunitid', label: 'Unidad de medida' ,typeName: 'es.rbm.model.jpa.Movement', editable: false },
            dose : {name: 'dose', label: 'Dosis (Ha)' ,typeName: 'Number', editable: false},
            quantity : {name: 'quantity', label: 'Cantidad' ,typeName: 'Number'},
        },
        keyComponent: 'newTreatmentproductDetailCrudManagement',
    },
}

export default function NewTreatmentDetails(props) {
    const {state, setState} = props;
    const [cultivationRecords, setCultivationRecords] = React.useState([]);
    const [productRecords, setProductRecords] = React.useState([]);

    const converterRecords = (callback) => {
        const newRecords = state.selectedCultivation? state.selectedCultivation.map(cultivation => {return {...cultivation, areacover: cultivation.areatreatment? cultivation.areatreatment : cultivation.area}}) : []
        setCultivationRecords(newRecords);
        const newState = {...state, cultivationAreas: newRecords}
        setState(newState);
        callback && callback(newRecords);
    }

    const refresh = (callback) => {
      converterRecords(callback);
    }

    const handledFiltersChange = (var1, var2) =>{
        var newState;
        if(var2.name === "recipeid"){
            const recipes = var1.map(v => v.recipeid);
            newState = {...state, recipeSelecteds: recipes}
            setState(newState);
        } else {
            const productid = var1.map(v => v.productid);
            newState = {...state, productsSelecteds: productid}
            setState(newState);
        }
        doPost("agro/calculateTreatmentproduct", newState, result =>{
            setProductRecords(result);
            const resultState = {...newState, productsQuantities: result}
            setState(resultState);
        })
    }

    const onChange = (e) => {
        const record = cultivationRecords.filter(r => r.cultivationid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            const newRecords = cultivationRecords.map(r => r.cultivationid === e.id? record[0] : r);
            const newState = {...state, cultivationAreas: newRecords}
            setState(newState);
            doPost("agro/calculateTreatmentproduct", newState, result =>{
                setProductRecords(result);
                const resultState = {...newState, productsQuantities: result}
                setState(resultState);
            })
        } else {
            alert('No se puede modificar el detalle')
        }
    }

    const onChangeProduct = (e) => {
        const record = productRecords.filter(r => r.productid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            const newRecords = productRecords.map(r => r.productid === e.id? record[0] : r);
            const newState = {...state, productsQuantities: newRecords}
            setState(newState);
        } else {
            alert('No se puede modificar el detalle')
        }
    }

    const calculatePercents = (cultivation) => {
      const newPercents = cultivation.map(c => {
          return {...c, percent: (100*c.areacover)/c.area}
      });
      setCultivationRecords(newPercents);
    }

    useEffect(() =>{
        if(!cultivationRecords || Object.keys(cultivationRecords).length === 0){
            refresh()
        } else{
            calculatePercents(cultivationRecords)
        }
        }, [props]);

    return (
        <Box sx={{ width: '100%', height: '50vh', mt:3}}>

            <Box>
                <SingleSelectForm name={'recipeid'}
                                  defaultValue={null} typeName={'es.rbm.model.jpa.Recipe'}
                                  label={'Recetas'} isClearable={handledFiltersChange}
                                  onChange={handledFiltersChange} isMulti
                                  key={'AddRecipeTreatment'}/>
            </Box>

            <GridTable ui={uiNewTreatmenDetail.uiTable} sx={{mt:2, mb: 5}}
                       records={cultivationRecords} rowId={'cultivationid'}
                       onChange={onChange} hideFooter/>

            <SingleSelectForm name={'productid'}
                              defaultValue={null} typeName={'es.rbm.model.jpa.Product'}
                              label={'Añadir producto'} isClearable={handledFiltersChange}
                              onChange={handledFiltersChange} isMulti
                              urlRecords={'agro/getAllsTreatmentproduct'}
                              key={'AddProductTreatment'}/>

            <GridTable sx={{mt:2}} ui={uiProduct.uiTable}
                       records={productRecords} rowId={'productid'}
                       onChange={onChangeProduct} hideFooter/>
        </Box>


    );

}