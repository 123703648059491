import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect} from "react";
import {doGet, doPost} from "../../../../Utils/Restclient/NetworkActions";
import Title from "../Dashboard/Title";
import Paper from "@mui/material/Paper";
import {IconButton} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Grid from "@mui/material/Grid";
import SingleRecordForm from "../../../../View/Form/SingleRecordForm";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import Box from "@mui/material/Box";
import {maxHeightViews} from "../../../../Utils/Constant";

const ui = {
    fields:{
        week : {name: 'week', label:'Semana' , typeName: 'Number', editable: true, size: 1},
        year : {name: 'year', label:'Año' , typeName: 'Number', editable: true, size: 1},
    }
}

export default function StockforecastView() {
    const [records, setRecords] = React.useState(null);
    const [filters, setFilters] = React.useState({week: moment().isoWeek(), year: moment().year()});

    useEffect(() =>{
       refresh();
    }, [])

    const refresh = (record) => {
        const filtersToApply = record ? record : filters
        doPost("plannedproduction/getWeekForecast", filtersToApply, result => setRecords(result));
    }

    const handledChange = (record) =>{
        setFilters({...record});
        refresh(record);
    }

    return (
        <React.Fragment >
            <Paper sx={{mb: 1, p:2, mt: 3}}>
                <Grid container>
                    <Grid md={11} item sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                        <SingleRecordForm  ui={ui} records={filters} onChange={handledChange} disableAutoFocus />
                    </Grid>
                    <Grid md={1} item sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton onClick={() => refresh()} color={'primary'} variant="contained" size="small">
                            <RefreshIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <Box sx={{height: maxHeightViews}} overflow={'auto'}>
                <Paper sx={{mt: 2, padding: 4}}>
                        <Table size="small" stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 'bold' }}>Producto</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Stock</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Previsión</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Diferencia</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Lunes</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Martes</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Miércoles</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Jueves</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Viernes</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Sábado</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}>Domingo</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }} align={'center'}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {records && records.map((row, i) => (
                                    <TableRow key={i} sx={{backgroundColor: row.color}}>
                                        <TableCell sx={{fontWeight: 'bold',  borderRightStyle: "solid", borderRightColor: "black", }} >
                                            {row.product.product + (row.product.externalcode ? '. Cod. ext: ' + row.product.externalcode : '')}
                                        </TableCell>
                                        <TableCell sx={{fontWeight: 'bold' }} align={'center'}>{row.stockQuantity}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold' }} align={'center'}>{row.toDoneQuantity}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold', borderRightStyle: "solid", borderRightColor: "black",}} align={'center'}>
                                            {row.difference}
                                        </TableCell>
                                        {Object.keys(row.quantityDays).map(quantity => <TableCell align={'center'}>{row.quantityDays[quantity]}</TableCell>)}
                                        <TableCell sx={{fontWeight: 'bold' }} align={'center'}>
                                            <IconButton variant="contained" size="small" disabled>
                                                <AddShoppingCartIcon fontSize={'medium'}/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                </Paper>
            </Box>
        </React.Fragment>
    );
}