import SignIn from "../Solutions/ERP/src/Login/SignIn";
import PageDescription from "../Solutions/ERP/src/Index/PageDescription";
import MainPanel from "../Solutions/ERP/src/MainPanel";
import ProfileManagement from "../Solutions/ERP/src/Profile/ProfileManagement";
import {accountUI} from "../Solutions/ERP/src/Maintenance/Crud/Account/AccountCrud";
import ObjectManagementView from "../View/Form/CrudForm/ObjectManagementView";
import ERPConfigurationView from "../Solutions/ERP/src/Config/ERPConfigurationView";
import MainSidebar from "../MainSidebar";
import NewMain from "../Solutions/ERP/src/Index/NewMain";

const routes = [
    {path:   "/login", element: <SignIn/>},
    {path:   "/", element: <NewMain/>},
    {path:   "/main", element: <MainSidebar/>},
    {path:   "/profile", element: <ProfileManagement/> },
    {path:   "/users", element: <ObjectManagementView entityDefinition={accountUI}/> },
    {path:   "/admin/config", element: <ERPConfigurationView/> },
]
export default routes;