import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import {doGetDownloadFile, doPost, doPostDownloadFile} from "../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../View/Form/GridTable";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DragDropFiles from "../../../../../../View/DragDropFiles/DragDropFiles";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Dialog from "@mui/material/Dialog";

const ui = {
    fields:{
        filename : {name: 'filename', label: 'Nombre' ,typeName: 'String', flex: 9, editable: false},
        lastModified : {name: 'lastModified', label: 'Última modificación' ,typeName: 'Time', editable: false, flex: 2},
        size : {name: 'size', label: 'Tamaño (KB)' ,typeName: 'Number', editable: false},
    }
}

export default function WorkerDocumentView(props) {
    const {workerid} = props;
    const [records, setRecords] = React.useState([]);
    const [upload, setUpload] = React.useState(false);
    const [fileToUpload, setFileToUpload] = React.useState(null);

    useEffect(() =>{
        if (!upload) {
            refresh();
        }
    }, [props, upload])

    const refresh = () => {
        doPost('worker/getWorkerDocumentName', workerid, result => {
            if (result) {
                setRecords(result);
            }
        })
    }

    const doImportData = () => {
        const toSend = {
            worker: workerid,
            file: Array.from(new Uint8Array(fileToUpload.value)),
            fileName: fileToUpload.name
        }
        doPost('worker/saveWorkerDocument', toSend, () => {
            setUpload(false);
            setFileToUpload(null);
        })
    }

    const handledFileAdd = (file1) =>{
        setFileToUpload(file1.target);
    }

    const onDelete = (file) =>{
        const toSent = {
            worker : workerid,
            fileName: file.filename
        }
        doPost('worker/deleteWorkerDocument', toSent, refresh);
    }

    const downloadData = (file) => {
        const toSent = {
            worker : workerid,
            fileName: file.filename
        }
        doPostDownloadFile('worker/downloadWorkerDocumentName', toSent, file.filename);
    }

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Dialog open={upload} disableEscapeKeyDown={false}>
                <Box maxWidth={'md'}>
                    <Grid container>
                        <Grid item md={12} sx={{margin: 2}}>
                            <Grid container sx={{mb:2}}>
                                <Grid item md={11} sx={{mt:6}}>
                                    <Typography  variant="h5" align="center" color="text.secondary" paragraph>
                                        Seleccione un archivo
                                    </Typography>
                                </Grid>
                                <Grid item md={1}>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button size={'small'} variant="contained" color="error" onClick={() => setUpload(false)}>
                                            X
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <DragDropFiles onLoadFile={handledFileAdd} name={'file'} file={fileToUpload}
                                           types={["PDF", "XLS", "XLSX", 'PNG', 'JPG']}/>
                            <SuccessButton text={'SUBIR ARCHIVO'} fullWidth sx={{mb: 1, mt: 5}} onClick={doImportData}/>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <Button size={'small'} variant="contained" onClick={() => setUpload(true)} startIcon={<AddIcon />} sx={{m: 1}}>
                    Subir archivo
                </Button>
                <GridTable ui={ui} sx={{mt: 2, height: '75vh'}} onDelete={onDelete} downloadData={downloadData}
                           records={records}
                           toolBar
                />
            </Box>
        </Box>
    );
}
