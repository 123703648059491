export const OPERATORS = {
    OPERATOR_EQUAL: 'OPERATOR_EQUAL',
    OPERATOR_BETWEEN: 'OPERATOR_BETWEEN',
    OPERATOR_IN: 'OPERATOR_IN',
    OPERATOR_LIKE: 'OPERATOR_LIKE',
    OPERATOR_ISNULL: 'OPERATOR_ISNULL',
    OPERATOR_ISNOTNULL: 'OPERATOR_ISNOTNULL',
    OPERATOR_LT: 'OPERATOR_LT',
    OPERATOR_LTE: 'OPERATOR_LTE',
    OPERATOR_GT: 'OPERATOR_GT',
    OPERATOR_GTE: 'OPERATOR_GTE',
    OPERATOR_NOT_EQUAL: 'OPERATOR_NOT_EQUAL'
}