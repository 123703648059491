import {doDelete, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

export const updateRecipedetail = (recipedetailidid, callback) =>{
    doPut('rest/recipedetail/update',recipedetailidid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deleteRecipedetail = (recipedetailidid, callback) =>{
    doDelete('rest/recipedetailid/delete/' + recipedetailidid.recipedetailidid,null, response =>{
        callback && callback();
    })
}

export const getDetails = (recipeid, callback) =>{
    const filters = {
        entity : { name: 'recipedetail'},
        fields: {recipeid : {name: 'recipeid', operator: 'OPERATOR_EQUAL', value: recipeid}},
    }
    searchByCriteria(filters, result =>{
        callback && callback(result)
    });
}
