import {doPost, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {error, success} from "../../../../../../Utils/Notification/notifications";

export const updateCustomerproduct = (record, callback) =>{
    if(record){
        doPut('rest/customerproduct/update',record, response =>{
            if(response){
                success('Modificado correctamente')
                callback && callback()
            } else{
                error('Fallo al modificar tarifa')
            }
        })
    } else{
        error('Fallo al modificar tarifa')
    }
}

export const updateCustomerattributevalue = (toSend, callback) => {
    doPost("attribute/updateAttributeCustomer", toSend, () =>{
        callback && callback()
    })
}
