import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import {doGet} from "../../../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../../../Utils/Notification/notifications";
import PlotMapView from "./MapView/PlotMapView";

export const PlotMapDialog = (props) => {
    const [plot, setPlot] = React.useState(null);


    useEffect(() => {
        const {plotid} = props;
        refresh(plotid)
    }, [props]);

    const refresh = (plotid) => {
        setPlot(plotid)
    }

    const syncWithSigPac = () => {
      doGet('agro/syncPlotWithSigPac/' + plot.plotid, () => success('Sincronizado correctamente'));
    }

    return (
        <Box>
            <Button size={'small'} variant="contained" onClick={syncWithSigPac} startIcon={<CallReceivedIcon />} sx={{m: 1, mt:3}}>
                Sincronizar con SIGPAC
            </Button>
            {plot && <PlotMapView plotid={plot} coordinates={{latitude: 38.06820389131554, longitude: -0.872540639659949}}/>}
        </Box>
    );
};

export default PlotMapView;
