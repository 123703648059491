import * as React from 'react';
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {useEffect} from "react";
import {IconButton} from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useTheme} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';


export default function HorizontalTabList(props) {
    const {defaultValue, sideBarTab, orientation, selectTab, showButton, expansible, onCloseTab, nonRefreshOnChange} = props;
    const [value, setValue] = React.useState(1);
    const [visible, setVisible] = React.useState(true);
    const [timeouts, setTimeouts] = React.useState(null);
    const [tabPanels, setTabPanels] = React.useState([]);
    const [tabs, setTabs] = React.useState([]);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (selectTab && !value) {
            setValue(selectTab);
        }
        if (props.visible !== false){
            setVisible(true)
        } else {
            setVisible(false);
        }
        refreshTimeout();
        configTabPanels();
    }, [selectTab, value, props])

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    const refreshTimeout = () => {
        if (expansible !== false) {
            if(timeouts){
                clearTimeout(timeouts)
            }
            const timeout = setTimeout(() => {
                setVisible(false);
            }, 60*1000);
            setTimeouts(timeout)
        }
    }

    const configTabPanels = () => {
        const tabPanels = sideBarTab?.fields?.map((e, i) => <TabPanel key={value + e.label + i} sx={{paddingBottom: 0, paddingTop: 0, maxHeight: 5}} value={i+1 + ''}>
            {e.children ? <HorizontalTabList showButton={false} expansible={true} visible={visible} key={value + e.label + i} sideBarTab={e.children}/> :
                <Box maxHeight maxWidth >
                    {e.component}
                </Box> }
        </TabPanel> );
        setTabPanels(tabPanels);
        const tabs = sideBarTab?.fields?.map((e, i) =>
            <Tab sx={{fontWeight: 'bold', fontSize: 11.5, color: theme.palette.primary.main}}
                 iconPosition={'end'} key={value + e.label + i} disabled={e.disabled}
                 label={
                     onCloseTab && value == i + 1 ?
                         <Box display="flex" alignItems="center">
                             {e.label}
                             <IconButton size="small" >
                                 <CloseIcon fontSize="small" onClick={() => onCloseTab(e)}/>
                             </IconButton>
                         </Box> : e.label
                 } value={i + 1 + ''}/>
        );
        setTabs(tabs);
    }

    return(
        <Box>
            <TabContext key={value} value={value+''} >
                {visible ?
                    <TabList allowScrollButtonsMobile style={{maxHeight: 40, minHeight: 40}} sx={{ml:2}} variant={'fullWidth'} onChange={handleChange} selectionFollowsFocus orientation={orientation ? orientation : 'horizontal'}>
                        {tabs}
                    </TabList>:
                    showButton !== false &&
                    <Grid container>
                        <Grid item md={1}>
                            <IconButton color={'warning'} onClick={() => {setVisible(true); refreshTimeout()}} variant="contained" size="small">
                                <ArrowDropDownIcon fontSize={'large'}/>
                            </IconButton>
                        </Grid>
                    </Grid>}
                {tabPanels}
            </TabContext>
        </Box>
    )
}
