import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import Box from "@mui/material/Box";
import ConstructionMapView from "./MapView/ConstructionMapView";

export const ConstructionMapDialog = (props) => {
    const [construction, setConstruction] = React.useState(null);


    useEffect(() => {
        const {constructionid} = props;
        refresh(constructionid)
    }, [props]);

    const refresh = (constructionid) => {
        setConstruction(constructionid)
    }

    return (
        <Box>
            <ConstructionMapView markButton={true} constructionid={construction} coordinates={{latitude: 38.06820389131554, longitude: -0.872540639659949}}/>
        </Box>
    );
};

export default ConstructionMapView;
