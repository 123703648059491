import {doGetDownloadFile, doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";


export const deleteSalenote = (salenote, callback) => {
    const toSend = {...salenote.salenote};
    toSend.saleinvoiceid = null;
    doPutRest('salenote', toSend, callback);
}

export const downLoadSalenote = (salenote) => {
    doGetDownloadFile('salenote/downloadSalenote/' + salenote.salenote.salenoteid, 'Albarán_venta_n' + salenote.salenote.salenoteid + '.pdf')
}

export const addSalenote = (saleinvoice, salenote, callback) => {
    const toSend = {salenoteid: salenote, saleinvoiceid: saleinvoice}
    doPost('saleinvoice/generateInvoiceFromNote', toSend, callback);
}

export const downloadInvoice = (saleinvoice) => {
    doGetDownloadFile('saleinvoice/downloadSaleinvoice/' + saleinvoice.saleinvoiceid, 'Factura_venta_n' + saleinvoice.saleinvoiceid + '.pdf')
}

export const filterNotes = (records, callback) => {
    const saleinvoiceid = records.saleinvoiceid;
    if (saleinvoiceid) {
        const filters = {
            entity : {name: 'salenote'},
            fields: {
                saleinvoiceid : {name: 'saleinvoiceid', operator: OPERATORS.OPERATOR_ISNULL},
                customerid : {name: 'customerid', operator: OPERATORS.OPERATOR_EQUAL, value: saleinvoiceid.customerid},
            },
        }
        searchByCriteria(filters, result =>{
            callback && callback(result);
        });
    } else {
        callback && callback([])
    }
}

export const getSalenoteDetails = (salenoteid, callback) =>{
    doPost('salenote/getDetails',salenoteid, result => callback && callback(result))
}

export const getSaleinvoicedetails = (saleinvoiceid, callback) => {
    const filters = {
        entity : {name: 'saleinvoicedetail'},
        fields: {saleinvoiceid : {name: 'saleinvoiceid', operator: 'OPERATOR_EQUAL', value: saleinvoiceid}},
    }
    searchByCriteria(filters, result => callback && callback(result));
}