import * as React from "react";
import {useEffect} from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ProfileDataForm from "./ProfileDataForm";
import {useStateValue} from "../../../../Utils/Redux/StateProvider";
import {doGet} from "../../../../Utils/Restclient/NetworkActions";

export default function ProfileManagement(){
    const [value, setValue] = React.useState('1');
    const [profile, setProfile] = React.useState(null);
    const [{user, basket, token}, dispatch] = useStateValue();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() =>{
        doGet('auth/getProfile', token, (result) =>{
            if(result){
                setProfile(result)
            }
        })
    }, [])

    return(
        <Box sx={{ width: '100%', height: '100%', typography: 'body1' }}>
            {value && <TabContext value={value}>
                <Box position={'fixed'} justifyContent={'center'} alignItems={'center'} sx={{ borderBottom: 3, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        <Tab label="Datos" value="1" />
                        <Tab label="Cuenta" value="2" />
                        <Tab label="Ajustes" value="3" />
                    </TabList>
                </Box>
                <Box >
                    <TabPanel value="1"><ProfileDataForm /></TabPanel>
                    {/*<TabPanel value="2"><AccountForm/></TabPanel>*/}
                </Box>
            </TabContext>}

        </Box>
    )
}