import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";

const getUiTab = (paymentmethodid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Método de pago", component: <EditObjectDialog objectid={paymentmethodid} entityDefinition={paymentmethodUI}/> },
            ]}
    })
}

export const paymentmethodUI = {
    entity : {
        name: 'paymentmethod',
        keyField: 'paymentmethodid',
        label: 'Pedido de compra',
        colorRow: 'status',
        info: {typeName: 'es.rbm.model.jpa.Purchaseorder'}
    },
    uiTable: {
        fields:{
            paymentmethodid : {name: 'paymentmethodid', label:'#' , typeName: 'Number', editable: false, flex:0.3, optional: true},
            paymentmethod : {name: 'paymentmethod', label:'Método de pago' , typeName:'String', align: 'center'},
        },
        keyComponent: 'paymentmethodCrudManagement'
    },
    uiForm : {
        fields:{
            paymentmethodid : {name: 'paymentmethodid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            paymentmethod : {name: 'paymentmethod', label:'Método de pago' , typeName:'String', align: 'center'},
        }
    },
    uiTab: (paymentmethod) => getUiTab(paymentmethod)
}

