import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {addNewObject} from "./actions";
import SuccessButton from "../../Button/SuccessButton";
import SingleRecordForm from "../SingleRecordForm";
import {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {success} from "../../../Utils/Notification/notifications";
import SaveRoundedButton from "../../Button/SaveRoundedButton";


export default function NewObjectDialog(props) {
    const {open, setOpen, entityDefinition, handleNewObject} = props;
    const [objectid, setObjectid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) => {
        setObjectid({...record});
        setComplete(complete);
    }

    useEffect(() =>{}, [])

    return (
        <Grid sx={{m: 5, display: 'flex', flexDirection: 'column', alignItems: 'center'}} maxWidth={'80vh'}>
            <Grid container>
                <Grid item md={12}>
                    <Grid container sx={{pb:1}}>
                        <Grid item md={11}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Typography component="h5" variant="h5">
                                    Nuevo {entityDefinition?.entity?.label?.toLowerCase()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={1}>
                            <SaveRoundedButton  onClick={(e) => {
                                e.preventDefault();
                                addNewObject(objectid, entityDefinition.entity, result => {
                                    success('Se ha añadido correctamente', () => {
                                        setOpen(!open);
                                        handleNewObject(result)
                                    });
                                });
                            }} disabled={!isComplete} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} overflowY={'auto'}>
                    <Box component="form" noValidate sx={{pt: 3, px: 1}} minHeight={'10vh'} maxHeight={'60vh'} overflow={'auto'}>
                        <SingleRecordForm ui={entityDefinition.uiForm} records={objectid} onChange={handledChange}/>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );

}
