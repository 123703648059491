import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import CultivationTreatmentView from "./CultivationTreatmentView";
import CultivationFertigationView from "./CultivationFertigationView";
import {CultivationMapDialog} from "./CultivationMapDialog";
import CultivationPlotView from "./CultivationPlotView";
import CultivationharvestView from "./CultivationharvestView";

const getUiTab = (cultivationid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Cultivo", component: <EditObjectDialog objectid={cultivationid} entityDefinition={cultivationUI}/> },
                {label : "Tratamientos", component: <CultivationTreatmentView cultivationid={cultivationid}/>},
                {label : "Fertirrigaciones", component: <CultivationFertigationView cultivationid={cultivationid}/>},
                {label : "Recolección", component: <CultivationharvestView cultivationid={cultivationid}/>},
                {label : "Parcelas", component: <CultivationPlotView cultivationid={cultivationid}/>},
                {label : "Mapa", component: <CultivationMapDialog cultivationid={cultivationid}/> }
            ]
    }})
}

export const cultivationUI = {
    entity : {
        name: 'cultivation',
        keyField: 'cultivationid',
        label: 'Cultivo',
        info: {typeName: 'es.rbm.model.jpa.Cultivation'}
    },
    uiTable: {
        fields:{
            cultivationid : {name: 'cultivationid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3 },
            cultivation : {name: 'cultivation', label:'Nombre' , typeName: 'String', editable: true},
            campaignid : {name: 'campaignid', label:'Campaña' , typeName: 'es.rbm.model.jpa.Campaign', editable: true, optional: true},
            workspaceid : {name: 'workspaceid', label:'Finca' , typeName: 'es.rbm.model.jpa.Workspace', editable: true, optional: true},
            productid : {name: 'productid', label:'Producto cultivado', typeName: 'es.rbm.model.jpa.Product', Workerrds: 'agro/getAllsCultivationproduct'},
            since : {name: 'since', label:'Desde' , typeName: 'Date', editable: true},
            until : {name: 'until', label:'Hasta' , typeName: 'Date', editable: true},
            area : {name: 'area', label:'Área total(Ha)' , typeName: 'Number', editable: true},
        },
        keyComponent: 'cultivationCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            cultivationid : {name: 'cultivationid', label:'Codigo' , typeName: 'String', editable: false, optional: true, flex: 0.3 },
            cultivation : {name: 'cultivation', label:'Nombre' , typeName: 'String', editable: true},
            since : {name: 'since', label:'Desde' , typeName: 'Date', editable: true},
            until : {name: 'until', label:'Hasta' , typeName: 'Date', editable: true},
            campaignid : {name: 'campaignid', label:'Campaña' , typeName: 'es.rbm.model.jpa.Campaign', editable: true, optional: true},
            workspaceid : {name: 'workspaceid', label:'Finca' , typeName: 'es.rbm.model.jpa.Workspace', editable: true, optional: true},
            productid : {name: 'productid', label:'Producto cultivado', typeName: 'es.rbm.model.jpa.Product',  urlRecords: 'agro/getAllsCultivationproduct'},
            area : {name: 'area', label:'Área(Ha)' , typeName: 'Number', editable: true},
        }
    },

    uiTab: (cultivation) => getUiTab(cultivation)
}

