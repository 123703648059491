import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {PlotMapDialog} from "./PlotMapDialog";

const getUiTab = (plotid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Parcela", component: <EditObjectDialog objectid={plotid} entityDefinition={plotUI}/> },
                {label : "Mapa", component: <PlotMapDialog plotid={plotid}/> }
            ]}
    })
}

export const plotUI = {
    entity : {
        name: 'plot',
        keyField: 'plotid',
        label: 'Parcela',
        info: {typeName: 'es.rbm.model.jpa.Plot'},
        urlExcelImport: 'agro/importPlotFromExcel',
        urlImport: 'agro/syncAllsPlotWithSigPac',
    },
    uiTable: {
        fields: {
            plotid : {name: 'plotid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3, sort: 'asc' },
            plot : {name: 'plot', label:'Descripción' , typeName: 'String'},
            workspaceid : {name: 'workspaceid', label:'Finca' , typeName: 'es.rbm.model.jpa.Workspace'},
            state : {name: 'state', label:'Provincia' , typeName: 'Number'},
            city : {name: 'city', label:'Población' , typeName: 'Number'},
            aggregate : {name: 'aggregate', label:'Agregado' , typeName: 'Number'},
            zone : {name: 'zone', label:'Zona' , typeName: 'Number'},
            polygon : {name: 'polygon', label:'Poligono' , typeName: 'Number'},
            parcel : {name: 'parcel', label:'Parcela' , typeName: 'Number'},
            enclosure : {name: 'enclosure', label:'Recinto' , typeName: 'Number'},
            area : {name: 'area', label:'Área' , typeName: 'Number'},
        },
        keyComponent: 'plotCrudManagement',
    },
    uiForm : {
        fields:{
            plotid : {name: 'plotid', label:'Codigo' , typeName: 'Number', editable: false, optional: true, flex: 0.3, sort: 'asc' , size: 3},
            plot : {name: 'plot', label:'Descripción' , typeName: 'String', size: 9},
            workspaceid : {name: 'workspaceid', label:'Finca' , typeName: 'es.rbm.model.jpa.Workspace', size: 9},
            state : {name: 'state', label:'Provincia' , typeName: 'Number', size: 3},
            city : {name: 'city', label:'Población' , typeName: 'Number', size: 3},
            aggregate : {name: 'aggregate', label:'Agregado' , typeName: 'Number', size: 3},
            zone : {name: 'zone', label:'Zona' , typeName: 'Number', size: 3},
            polygon : {name: 'polygon', label:'Poligono' , typeName: 'Number', size: 3},
            parcel : {name: 'parcel', label:'Parcela' , typeName: 'Number', size: 3},
            enclosure : {name: 'enclosure', label:'Recinto' , typeName: 'Number', size: 3},
            area : {name: 'area', label:'Área' , typeName: 'Number', size: 3},
        }
    },
    uiTab: (plotid) => getUiTab(plotid)
}