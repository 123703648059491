import * as React from "react";
import {useEffect} from "react";
import Box from "@mui/material/Box";
import {doGet, doPost} from "../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../Utils/Notification/notifications";
import Typography from "@mui/material/Typography";


const fields = [
    {field: 'accountid', label: 'Codigo'},
    {field: 'login', label: 'Usuario'},
    {field: 'ip', label: 'IP'},
    {field: 'enabled', label: 'Activo'},
]


export default function ProfileDataForm(props){
    const [account, setAccount] = React.useState(null);

    useEffect(() =>{
        doGet('auth/getProfile', (result) =>{
            if(result){
                setAccount(result)
            }
        })

    }, [props]);

    return (
        <Box sx={{mt: 10}}>
            {account &&
                fields.map(f =>
                     <Typography component="h5" variant="h5"> {f.label + ': ' + account[f.field]} </Typography>
                )
            }
        </Box>


    )
}
