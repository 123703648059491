import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (tasktypeid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tipo", component: <EditObjectDialog objectid={tasktypeid} entityDefinition={tasktypeUI}/> },
            ]}
    })
}

export const tasktypeUI = {
    entity : {
        name: 'tasktype',
        keyField: 'tasktypeid',
        label: 'Tipo de tarea'
    },
    uiTable: {
        fields:{
            tasktypeid : {name: 'tasktypeid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            tasktype : {name: 'tasktype', label:'Tipo' , typeName: 'String', editable: true, align: 'center'},
        },
        keyComponent: 'tasktypeCrudManagement'
    },
    uiForm : {
        fields:{
            tasktypeid : {name: 'tasktypeid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            tasktype : {name: 'tasktype', label:'Tipo' , typeName: 'String', editable: true },
        }
    },
    uiTab: (tasktype) => getUiTab(tasktype)
}

