import {doDelete, doPost, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {success} from "../../../../../../Utils/Notification/notifications";

export const addNewWorker = (worker) => {
    doPost('rest/worker/create', worker)
}

export const updateWorker = (worker) => {
    doPut('rest/worker/update', worker, result =>{
        if(result){
            success('Guardado correctamente');
        }
    })
}

export const deleteWorker = (worker, callback) => {
    doDelete('rest/worker/delete/' + worker.workerid, null,
        () => {
                callback && callback();
        })
}

export const updateWorkerattributevalue = (toSend, callback) => {
    doPost("worker/updateAttributeWorker", toSend, () =>{
        callback && callback()
    })
}