import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import EditExecutionDialog from "./EditExecutionDialog";
import GridTable from "../../../../View/Form/GridTable";
import Grid from "@mui/material/Grid";
import SuccessButton from "../../../../View/Button/SuccessButton";
import {
    getExecutionsFromSaleorderdetails,
    getPlannedproductionFromSaleorderdetails
} from "./PlannedproductionController";
import {doDelete} from "../../../../Utils/Restclient/NetworkActions";
import {doDeleteRest} from "../../../../Utils/Restclient/NetworkRestActions";

const uiExecutions = {
    fields: {
        executionid : {name: 'executionid', label: 'Código',typeName:'Number', editable: false},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', editable: false},
        hour : {name: 'hour', label: 'Fecha',typeName:'Time', editable: false},
    }
}

const uiPlannedproductions = {
    fields: {
        plannedproductionid : {name: 'plannedproductionid', label: '#',typeName:'Number', editable: false, flex: 0.4 },
        date : {name: 'date', label: 'Fecha',typeName:'Date', editable: false, flex: 0.5},
        responsibleid : {name: 'responsibleid', label: 'Responsable',typeName:'es.rbm.model.jpa.Worker', editable: false},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', editable: false, flex: 0.5},
        remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', editable: false, align: 'center'},
    }
}

export default function ExecutionsDialog(props) {
    const {data, setOpen, open} = props;
    const [openEditExecution, setOpenEditExecution] = React.useState(false);
    const [executions, setExecutions] = React.useState([]);
    const [plannedproductions, setPlannedproductions] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [selected, setSelected] = React.useState(null);

    useEffect(() =>{
        if(!openEditExecution) {
            refresh()
        }
    }, [props,openEditExecution ])

    useEffect(() =>{
        if (selected) {
            setOpenEditExecution(true)
        }
    }, [selected])

    const refresh = () => {
        getExecutionsFromSaleorderdetails(data.saleorderdetails, result => setExecutions(result));
        getPlannedproductionFromSaleorderdetails(data.saleorderdetails, result => setPlannedproductions(result));
    }

    const onDeleteExecution = (execution) => {
        doDeleteRest('execution', execution.executionid, () => refresh())
    }

    const onDeletePlannedproduction = (plannedproduction) => {
        doDeleteRest('plannedproduction', plannedproduction.plannedproductionid, () => refresh())
    }

    const onEdit = (execution) => {
        setSelectedDetail(execution);
        setOpenEditExecution(true);
    }

    const onChangeExecution = (e) => {
        //saveExecution({...e, saleorderdetails: e.saleorderdetails}, () => refresh());
    }

    const onChangePlannedproduction = (pp) => {
        //saveExecution({...e, saleorderdetails: e.saleorderdetails}, () => refresh());
    }

    const onNegativeClick = (e) => {e.preventDefault();setOpen(false)};

    const onAdd = (plannedproductionsid) => {
        setSelected(plannedproductionsid)
    }

    return(
    <Box sx={{m: 2, alignItems: 'center'}}>
        <Grid container>
            <Dialog fullWidth maxWidth="md" open={openEditExecution} onClose={() => setOpenEditExecution(false)}>
                <EditExecutionDialog setOpen={setOpenEditExecution} plannedproduction={selected} open={openEditExecution} selected={selectedDetail} data={data}/>
            </Dialog>
            <Grid item md={12}>
                <Box  sx={{m: 2}}>
                    <Typography sx={{mb: 3}} align={'center'} component="h1" variant="h5">Lanzamientos/Ejecuciones</Typography>
                    <GridTable ui={uiPlannedproductions}  onDelete={onDeletePlannedproduction}
                               records={plannedproductions} rowId={'plannedproductionid'}
                               onChange={onChangePlannedproduction} sx={{height: '30vh'}}
                               actionFlex={0.4} onEdit={onAdd}/>

                    {/*<Button size={'small'} align={'center'} variant="contained" startIcon={<AddIcon />} sx={{m: 1, mt: 3}} onClick={() => setOpenEditExecution(true)}>
                        Añadir ejecución
                    </Button>*/}
                    <GridTable ui={uiExecutions}  onDelete={onDeleteExecution} //onEdit={onEdit}
                               records={executions} rowId={'executionid'}
                               onChange={onChangeExecution} sx={{height: '30vh'}}
                               actionFlex={0.4}/>
                </Box>
            </Grid>
        </Grid>
    </Box>
    )
}
