import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {notEditableFields} from "../../../../../../Utils/UtilsCommon";
import {purchaseinvoiceUI} from "../Purchaseinvoice/purchaseinvoiceCrud";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {doGetDownloadFile, doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import {downloadInvoice} from "../Purchaseinvoice/actions";


const purchaseinvoicedetailUI = {
    fields: {
        purchasenote : {name: 'purchasenote', label:'Albarán de venta' , typeName: 'es.rbm.model.jpa.Purchasenote', editable: false, delimiter: ''},
        total : {name: 'total', label:'Total (€)' , typeName: 'Number', editable: false, optional: true},
        lines : {name: 'lines', label:'Nº lineas' , typeName: 'Number', editable: false, optional: true},
    }
}

export default function PurchaseorderPurchaseinvoiceView(props) {
    const {purchaseorderid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState(null);
    const [ui, setUi] = React.useState(purchaseinvoiceUI.uiTable);

    useEffect(() => {
        setUi(notEditableFields(ui));
        refresh()
    }, [purchaseorderid])

    const refresh = () => {
        setDetails(null)
        setRecords([])
        const filters = {
            entity : {name: 'purchasenote'},
            fields: {
                purchaseorderid : {name: 'purchaseorderid', operator: 'OPERATOR_EQUAL', value: purchaseorderid},
                purchaseinvoiceid : {name: 'purchaseinvoiceid', operator: OPERATORS.OPERATOR_ISNOTNULL}
            },
        }
        searchByCriteria(filters, result =>{
            if(result && result.length > 0){
                const purchaseinvoices = result.map(so => so.purchaseinvoiceid);
                setRecords(purchaseinvoices);
            }
        });
    }

    const onRowClick = (e, v) => {
        setDetails(null)
        if(e){
            doPost('purchaseinvoice/getPurchaseinvoiceInfo', e.row, result => {
                if(result) {
                    const details = result.purchasenotes.map(sn => {
                        return {purchasenote: sn.purchasenote, total: sn.total, lines: sn.purchasenotedetailList.length}
                    });
                    setDetails(details)
                }
            } )
        }
    }

    const downloadnotePDF = (purchasenoteid) =>{
        doGetDownloadFile('purchasenote/downloadPurchasenote/' + purchasenoteid.purchasenote.purchasenoteid, 'Albaran_venta_n' + purchasenoteid.purchasenote.purchasenoteid + '.pdf')
    }

    const downloadinvoicePDF = (invoiceid) =>{
        downloadInvoice(invoiceid)
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Box overflow={'auto'}>
                <GridTable ui={ui}
                           sx={{height: '40vh'}} //downloadPDF={downloadinvoicePDF}
                           records={records} rowId={'purchaseinvoiceid'} onRowClick={onRowClick}
                           toolBar={'D'} hideFooter/>
            </Box>
            {details &&
            <Box overflow={'auto'}>
                <GridTable ui={purchaseinvoicedetailUI}
                           sx={{height: '30vh', mt:2}}
                           records={details} //downloadPDF={downloadnotePDF}
                           hideFooter/>
            </Box>}
        </Box>
    )
}