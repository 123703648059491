import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import GridTable from "../../../../../../View/Form/GridTable";
import Paper from "@mui/material/Paper";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doDeleteRest, doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {error} from "../../../../../../Utils/Notification/notifications";
import {maxHeightViews} from "../../../../../../Utils/Constant";

const ui = {
    fields: {
        executionid : {name: 'executionid', label: '#',typeName:'Number', editable: false, flex: 0.3},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false},
        batchid : {name: 'batchid', label: 'Lote', typeName:'es.rbm.model.jpa.Batch', flex: 0.3},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', flex: 0.3},
        plannedproductionid : {name: 'plannedproductionid', label: 'Planificado',typeName:'Boolean', flex: 0.3},
        hour : {name: 'hour', label: 'Hora', typeName:'Hour', flex: 0.3},
    }
}

export default function DataperioexecutionDialog(props) {
    const {selected} = props;
    const [details, setDetails] = React.useState([]);

    useEffect(() =>{
        if (selected && selected.dataperiodid) {
            refresh();
        }
    }, [selected])

    const refresh = () => {
        const filters = {
            entity : {name: 'dataperiodexecution'},
            fields: {dataperiodid : {name: 'dataperioid', operator: 'OPERATOR_EQUAL', value: {dataperiodid: selected.dataperiodid}}},
        }
        searchByCriteria(filters, result =>{
            setDetails([...result]);
        });
    }

    const onDelete = (execution) => {
        doDeleteRest('execution', execution.executionid, () => refresh());
    }

    const onChange = (e) => {
        if (e.plannedproductionid) {
            error('No se puede modificar una elaboración planificada');
            refresh();
        } else {
            const record = details.filter(r => r.executionid === e.id);
            if(record && record.length>0) {
                record[0][e.field] = e.value;
                doPutRest('dataperiodexecution', record[0], () => refresh());
            } else {
                doPutRest('dataperiodexecution', e, () => refresh());
            }
        }
    }

    return(
        <Box sx={{m: 2, mr:0, alignItems: 'center'}}>
            <Paper sx={{backgroundColor: 'white'}}>
                <GridTable ui={ui} onDelete={onDelete} toolBar
                           records={details} rowId={'executionid'}
                           onChange={onChange} sx={{height: maxHeightViews}} />
            </Paper>
        </Box>
    )
}
