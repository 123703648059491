import * as React from "react";
import Select from 'react-select';
import Box from "@mui/material/Box";
import uiDefinition from "../../Utils/uiDefinition";
import {useEffect} from "react";
import {doGet} from "../../Utils/Restclient/NetworkActions";
import {Placeholder, ValueContainer} from "react-select/animated";


const colourStyles =  {
    menuList: styles => ({
        ...styles,
        background: 'white'
    }),
    option: (styles, {isFocused, isSelected}) => ({
        ...styles,
        background: isFocused ? 'bluelight' : isSelected ? 'blue' : undefined,
        zIndex: 1
    }),
    menu: base => ({
        ...base,
        zIndex: 100
    }),
    container: (provided, state) => ({
        ...provided,
        //paddingTop: state.hasValue || state.selectProps.inputValue ? 14 : 0,
        marginLeft: 1,
        marginRight: 1
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: state.selectProps.inputValue? null : "visible"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        position: (state.hasValue || state.selectProps.inputValue) && "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -22 : "50%",
        transition: "top 0.1s, font-size 0.1s",
        fontSize: (state.hasValue || state.selectProps.inputValue ) && 13,
        textAlign: 'center'
    })
}

export const converterRecords = (or, props) =>{
    if(or && or.length>0 && props){
        return or.map((r, i) =>{
            const result ={...converterRecord(r, props)};
            result[props.name] = {...r};
            return result;
        });
    } else{
        return [];
    }
}
export const converterRecord = (record, props) =>{
    const {keyField, descriptiveField, typeName, name} = props;
    if (record) {
        if (keyField && descriptiveField) {
            const result ={};
            result.value = record[keyField];
            result.label = record[descriptiveField];
            return result;
        } else if(uiDefinition[typeName] && uiDefinition[typeName].descriptiveField && uiDefinition[typeName].keyField) {
            const result ={};
            const uiDefinitionElement = uiDefinition[typeName];
            result.value = record[uiDefinitionElement['keyField']];
            result.label ='(' + result.value + ') ' + uiDefinitionElement['descriptiveField'].split('.').reduce((l, c) => { return l + record[c] + " "}, '');
            return result;
        } else if(uiDefinition[typeName]) {
            const result ={};
            result.value = record;
            result.label = record;
            return result;
        } else if(record && name && name.length > 0) {
            const result ={};
            result.value = record[name];
            result.label = record[name.replace('id', '')];
            return result;
        } else {
            return null;
        }
    } else{
        return null;
    }
}

export default function SingleSelectForm(props) {
    const [state, setState] = React.useState({});
    const [open, setOpen] = React.useState('');


    useEffect(() =>{
        if(props){
            if(props.originalrecords){
                setState({records: converterRecords(props?.originalrecords, props),
                    selected: converterRecord(props?.defaultValue, props)})
            } else if(props.urlRecords){
                doGet(props.urlRecords,
                    result => {
                        setState({records: converterRecords(result, props),
                            selected: converterRecord(props?.defaultValue, props)})
                    })
            } else {
                if(uiDefinition[props.typeName]?.url) {
                    const ui = uiDefinition[props.typeName];
                    //TODO if props.criteriaFilter
                    doGet(ui.url,
                        result => {
                            setState({records: converterRecords(result, props),
                                selected: converterRecord(props?.defaultValue, props)})
                        })
                }
            }
        }
    },[props])

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };



    return(
        <Box>
            {state.records &&
                props.isMulti?
            <Select
                components={{ValueContainer: CustomValueContainer}}
                onChange={props.onChange}
                defaultValue={state.selected}
                placeholder={props.label? props.label : 'Buscar...'}
                isDisabled={props.isDisabled}
                isLoading={props.isLoading? props.isLoading : false}
                isClearable={props.isClearable? props.isClearable : false}
                isSearchable={props.isSearchable? props.isSearchable : true}
                name={props.name? props.name : 'no-name'}
                blurInputOnSelect={true}
                options={state.records}
                styles={colourStyles}
                isMulti={props.isMulti}
                openMenuOnFocus={open => setOpen(open)}
            />
                : <Select
                components={{ValueContainer: CustomValueContainer}}
                onChange={props.onChange}
                defaultValue={state.selected}
                placeholder={props.label? props.label : 'Buscar...'}
                isDisabled={props.isDisabled}
                isLoading={props.isLoading? props.isLoading : false}
                isClearable={props.isClearable? props.isClearable : false}
                isSearchable={props.isSearchable? props.isSearchable : true}
                name={props.name? props.name : 'no-name'}
                blurInputOnSelect={true}
                options={state.records}
                styles={colourStyles}
                isMulti={props.isMulti}
                value={state.selected || ''}
                openMenuOnFocus={open => setOpen(open)}
            /> }
        </Box>
    )

}
