import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import NewConstructionHouseDialog from "./NewConstructionHouseDialog";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";


const ui = {
    fields:{
        houseid : {name: 'houseid', label: '#' ,typeName: 'Number', flex: 0.3},
        house : {name: 'house', label: 'Vivienda' ,typeName: 'String', align: 'center'},
    }
}

export default function ConstructionHouseView(props) {
    const {constructionid} = props;
    const [records, setRecords] = React.useState([]);
    const [house, setHouse] = React.useState(null);
    const [openNewConstructionHouse, setOpenNewConstructionHouse] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newConstructionHouse = () => {
        setOpenNewConstructionHouse(true);
    }

    const onEdit = (houseid) => {
        setHouse(houseid);
        setOpenNewConstructionHouse(!openNewConstructionHouse)
    }

    const onDelete = (houseid) =>{
        deleteObject(houseid,{name:"house", keyField:'houseid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.houseid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPutRest('house',toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })

    }

    useEffect(() =>{
        if(!openNewConstructionHouse){
            const filters = {
                fields: {constructionid : {name: 'constructionid', operator: 'OPERATOR_EQUAL', value: constructionid},},
                entity : { name: 'house'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                setHouse(null);
            });
        }
    }, [refreshObjects, openNewConstructionHouse, props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <Dialog fullWidth maxWidth={"xs"} open={openNewConstructionHouse}>
                    <NewConstructionHouseDialog houseid={house} construction={constructionid} setOpen={setOpenNewConstructionHouse} open={openNewConstructionHouse}/>
                </Dialog>
                <Button variant="contained" onClick={newConstructionHouse} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir vivienda
                </Button>
                <GridTable ui={ui} sx={{mt: 2}} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'houseid'}
                           onChange={onChange} toolBar actionFlex={0.4}
                />
            </Box>
        </Box>
    );
}
