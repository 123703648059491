import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {getCustomerproductprice, updateSaleorderdetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import {productUI} from "../Product/productCrud";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";



const ui = {
    fields: {
        //saleorderdetailid : {name: 'saleorderdetailid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
        //saleorderdetail : {name: 'saleorderdetail', label:'Descripción' , typeName: 'String', optional: true},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true, size: 2, autofocus: true},
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: true, size: 6, addObject: productUI,
            criteriaFilter: {
                entity : { name: 'product'},
                fields: {
                    ['producttypeid.saleproduct'] : {name: 'producttypeid.saleproduct', operator: OPERATORS.OPERATOR_EQUAL, value: true},
                    active : {name: 'active', operator: OPERATORS.OPERATOR_EQUAL, value: true},
                },
            }},
        unitprice : {name: 'unitprice', label:'Precio' , typeName: 'Number', editable: true, size: 2},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number', editable: true, optional: true, size: 2},
        remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', editable: true, optional: true, size: 12},
    },
    onChangeHandleTrigger : (records, field, value, callback) => {
        getCustomerproductprice(records, field, value, callback);
    }
}

export default function EditSaleorderdetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [saleorderdetailid, setSaleorderdetail] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nuevo detalle');

    const handledChange = (record, complete) => {
        setSaleorderdetail({...record});
        setComplete(complete);
    }

    useEffect(() => {
        if(saleorderdetailid && saleorderdetailid.saleorderdetailid){
            setTitle('Editar detalle')
        }
    }, [props])

    const onSuccessClick = (e) => {
        e.preventDefault()
        updateSaleorderdetail(saleorderdetailid,selected, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={saleorderdetailid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>
                            X
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
