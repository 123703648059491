import {doPost} from "../Restclient/NetworkActions";

export const searchByCriteria = (filters, callback) =>{
    const keys = Object.keys(filters.fields);
    if(keys.length>0){
        const itemsQuery = keys.map(f => {
            return {
                field: f,
                operator: filters.fields[f].operator,
                value: filters.fields[f].value
            }
        });
        const request = {
            clazz: filters.entity.name,
            items: [...itemsQuery]
        }
        doPost("rest/getByQuery", request, result =>{
            if (result) {
                //console.log(result);
                callback && callback(result)
            }
        }, false, true)
    }
}