import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {updateTreatmentcultivation} from "./networkActions";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const ui = {
    fields:{
        //treatmentcultivationid : {name: 'treatmentcultivationid', label:'Codigo' , typeName: 'String', editable: false, optional: true},
        cultivationid : {name: 'cultivationid', label:'Cultivo' , typeName: 'es.rbm.model.jpa.Cultivation', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product'},
        area : {name: 'area', label:'Área(Ha)' , typeName: 'Number'},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
        remarks : {name: 'remarks', label:'Observación' , typeName: 'String', optional: true},
    }
}

export default function EditTreatmentdetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [treatmentcultivation, setTreatmentcultivation] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nuevo detalle');

    const handledChange = (record, complete) => {
        setTreatmentcultivation({...record});
        setComplete(complete);
    }

    useEffect(() =>{
            setTitle('Editar detalle')
    }, [props])

    const onSuccessClick = (e) => {e.preventDefault();updateTreatmentcultivation(treatmentcultivation, () => setOpen(!open))};
    const onNegativeClick = (e) => {e.preventDefault();setOpen(!open)};
    return(
        <Box sx={{p: 5, m: 2}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={treatmentcultivation} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>X</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
