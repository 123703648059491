import NewTreatment from "./NewTreatment/NewTreatment";
import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import DetailsTreatmentDialog from "./DetailsTreatmentDialog";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

const getUiTab = (treatment) =>{
    return ({
        uiTabs : {fields : [
                {label : "Tratamiento", component: <EditObjectDialog objectid={treatment} entityDefinition={treatmentUI}/> },
                {label : "Detalles", component: <DetailsTreatmentDialog selected={treatment}/>},
            ]}
    })
}


const getNewComponent = (setOpenNewObject, openNewObject) =>{
    return <NewTreatment setOpenNewObject={setOpenNewObject} openNewObject={openNewObject}/>
}

export const treatmentUI = {
    entity : {
        name: 'treatment',
        keyField: 'treatmentid',
        label: 'Tratamiento',
        info: {typeName: 'es.rbm.model.jpa.Treatment'},
        colorRow: 'executed',
    },
    uiTable: {
        fields: {
            treatmentid : {name: 'treatmentid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3},
            treatment : {name: 'treatment', label: 'Tratamiento' ,typeName: 'String'},
            since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date'},
            workerid : {name: 'workerid', label: 'Responsable' ,typeName: 'es.rbm.model.jpa.Worker', optional: true, urlRecords: 'worker/getResponsibles'},
            aplicatorid : {name: 'aplicatorid', label: 'Aplicador' ,typeName: 'es.rbm.model.jpa.Worker', urlRecords:'agro/getAllsTreatmentAplicators',  optional: false},
            tractorid : {name: 'tractorid', label: 'Tractor' ,typeName: 'es.rbm.model.jpa.Tractor',  optional: false},
            tankid : {name: 'tankid', label: 'Cuba' ,typeName: 'es.rbm.model.jpa.Tank',  optional: false},
            remarks : {name: 'remarks', label: 'Observaciones' ,typeName: 'String', optional: true},
            executed : {name: 'executed', label: 'Completado' ,typeName: 'Boolean', optional: true},
        },
        keyComponent: 'treatmentCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            treatmentid : {name: 'treatmentid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3, optional: true},
            treatment : {name: 'treatment', label: 'Tratamiento' ,typeName: 'String'},
            since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date'},
            workerid : {name: 'workerid', label: 'Responsable' ,typeName: 'es.rbm.model.jpa.Worker', urlRecords: 'worker/getResponsibles'},
            aplicatorid : {name: 'aplicatorid', label: 'Aplicador' ,typeName: 'es.rbm.model.jpa.Worker', urlRecords:'agro/getAllsTreatmentAplicators',  optional: false},
            tractorid : {name: 'tractorid', label: 'Tractor' ,typeName: 'es.rbm.model.jpa.Tractor',  optional: false},
            tankid : {name: 'tankid', label: 'Cuba' ,typeName: 'es.rbm.model.jpa.Tank',  optional: false},
            executed : {name: 'executed', label: 'Completado' ,typeName: 'Boolean', optional: true},
            remarks : {name: 'remarks', label: 'Observaciones' ,typeName: 'String', optional: true, size: 12, multiline: true, rows: 3},
        }
    },

    onClickNewObject : {component : (setOpenNewObject, openNewObject) => getNewComponent(setOpenNewObject, openNewObject)},

    uiTab: (treatment) => getUiTab(treatment)
}

