import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";
import {countryUI} from "./countryCrud";

const getUiTab = (stateid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Provincia", component: <EditObjectDialog objectid={stateid} entityDefinition={stateUI}/> },
            ]}
    })
}

export const stateUI = {
    entity : {
        name: 'state',
        keyField: 'stateid',
        label: 'Provincia',
        info: {typeName: 'es.rbm.model.jpa.State'}
    },
    uiTable: {
        fields:{
            stateid : {name: 'stateid', label:'#' , typeName: 'Number', editable: false, flex:0.3, optional: true},
            state : {name: 'state', label:'Provincia' , typeName:'String', align: 'center'},
            countryid : {name: 'countryid', label:'País' , typeName:'es.rbm.model.jpa.Country', align: 'center'},
        },
        keyComponent: 'stateCrudManagement'
    },
    uiForm : {
        fields:{
            stateid : {name: 'stateid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            state : {name: 'state', label:'Provincia' , typeName:'String', align: 'center'},
            countryid : {name: 'countryid', label:'País' , typeName:'es.rbm.model.jpa.Country', align: 'center', addObject: countryUI},
        }
    },
    uiTab: (state) => getUiTab(state)
}

