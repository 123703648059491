import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {groupBy} from "../../../../../../Utils/UtilsCommon";


const saleorderdetailUI = {
    fields: {
        saleorderdetailid : {name: 'saleorderdetailid', label:'Codigo' , typeName: 'Number', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false },
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: false},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number', editable: false},
        unitprice : {name: 'unitprice', label:'Precio unitario' , typeName: 'Number', editable: false},
    }
}

const saleorderUI =  {
        fields:{
            saleorderid : {name: 'saleorderid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            customername : {name: 'customername', label: 'Nombre del cliente',typeName:'es.rbm.model.jpa.Customer', editable: false, align: 'center'},
            date : {name: 'date', label: 'Fecha',typeName:'Date', editable: false},
            saleorderstatusid : {name: 'saleorderstatusid', label: 'Estado',typeName:'es.rbm.model.jpa.Saleorderstatus', align: 'center', editable: false},
            firstaddress : {name: 'firstaddress', label:'Dirección' , typeName: 'String', editable: false, align: 'center'},
            townid : {name: 'townid', label:'Localidad' , typeName: 'es.rbm.model.jpa.Town', flex: 0.8, align: 'center', editable: false},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', editable: false},
            transactionid : {name: 'transactionid', label:'ID transación' , typeName: 'String', flex: 0.8, editable: false, align: 'center'},
            trackingcode : {name: 'trackingcode', label:'Numero de seguimiento' , typeName: 'String', flex: 0.8, editable: false, align: 'center'},
        }
}

export default function SaleorderCustomerView(props) {
    const {customerid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [customerid])

    const refresh = () => {
        setDetails([])
        setRecords([])
        const filters = {
            entity : {name: 'saleorder'},
            fields: {customerid : {name: 'customerid', operator: 'OPERATOR_EQUAL', value: customerid}},
        }
        searchByCriteria(filters, result =>{
            if(result && result.length > 0){
                const filters = {
                    entity : {name: 'saleorderdetail'},
                    fields: {saleorderid : {name: 'saleorderid', operator: 'OPERATOR_IN', value: result}},
                }
                searchByCriteria(filters, result1 =>{
                    const saleorderGroup = groupBy(result1, "saleorderid.saleorderid");
                    setGroups(saleorderGroup);
                    const newRecords = Object.keys(saleorderGroup).map(key => saleorderGroup[key][0].saleorderid);
                    setRecords(newRecords)
                    setDetails([]);
                });
            }
        });
    }

    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.saleorderid]);
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Box overflow={'auto'}>
                <GridTable ui={saleorderUI}
                           sx={{height: '40vh'}}
                           records={records} rowId={'saleorderid'} onRowClick={onRowClick}
                           toolBar={'S'} hideFooter/>
            </Box>
            <Box overflow={'auto'}>
                <GridTable ui={saleorderdetailUI}
                           sx={{height: '30vh', mt:2}}
                           records={details} rowId={'saleorderdetailid'}
                           hideFooter/>
            </Box>
        </Box>
    )
}