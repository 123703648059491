import * as React from 'react';
import Box from "@mui/material/Box";

import {useEffect} from "react";
import SingleRecordForm from "../../../../../../../View/Form/SingleRecordForm";

export default function NewFertigationForm(props) {
    const {entityDefinition, state, setState, setComplete} = props;

    const handledChange = (record, complete) => {
        setState({...state, fertigationForm:{...record}});
        setComplete(complete);
    }

    useEffect(() =>{
        if(!state.fertigationForm){
            setState({...state, fertigationForm:{}})
        }
    }, [props]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Box component="form" noValidate sx={{mt: 3}}>
                <SingleRecordForm ui={entityDefinition.uiForm} records={state.fertigationForm} onChange={handledChange} disableAutoFocus />
            </Box>
        </Box>
    );

}