import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";

const getUiTab = (countryid) =>{
    return ({
        uiTabs : {fields : [
                {label : "País", component: <EditObjectDialog objectid={countryid} entityDefinition={countryUI}/> },
            ]}
    })
}

export const countryUI = {
    entity : {
        name: 'country',
        keyField: 'countryid',
        label: 'País',
        info: {typeName: 'es.rbm.model.jpa.Town'}
    },
    uiTable: {
        fields:{
            countryid : {name: 'countryid', label:'#' , typeName: 'Number', editable: false, flex:0.3, optional: true},
            country : {name: 'country', label:'País' , typeName:'String', align: 'center'},
        },
        keyComponent: 'countryCrudManagement'
    },
    uiForm : {
        fields:{
            countryid : {name: 'countryid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            country : {name: 'country', label:'País' , typeName:'String', align: 'center'},
        }
    },
    uiTab: (country) => getUiTab(country)
}

